import React, {Component} from 'react'
import InfoForm from '../Component/Info/infoForm'
import { Layout,Card} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import axios from 'axios'

const {Content} = Layout;

//----------------------Not Using anymore-----------------------//
export default class Info extends Component{
  constructor(props){
    super(props)
    this.state = {

      partner:{
        pName:'',
        pDescription:'',
        pOrder:'',
        eventPartnerFile:null,
        pCategory:{
          id:'',
          label:''
        },
        pWebUrl:''
      },
      
      exhibitor:{
        eName:'',
        eDescription:'',
        eOrder:'',
        eventExhibitorFile:null,
        eCategory:{
          id:'',
          label:''
        },
        eWebUrl:''
      },
    }
  }


  eventPartnerHandler = event =>{
    console.log("lg",event.target.files)
    let {partner} = this.state;
    
    this.setState({
      partner:{...partner,eventPartnerFile:event.target.files}
    })

  }

  eventExhibitorHandler = event =>{
    console.log("lg",event.target.files)
    let {exhibitor} = this.state;
    
    this.setState({
      exhibitor:{...exhibitor,eventExhibitorFile:event.target.files}
    })

  }

  onChangeCategoryPartner = (value,properties) => {
    let {partner,pCategory} = this.state;
    let childObj = {
      id:value,
      label:properties.props.children
    }
    this.setState({partner:{...partner,pCategory:{...pCategory,...childObj}}})
  }

  onChangeExhibitorPartner = (value,properties) =>{
    let {exhibitor,eCategory} = this.state;
    let childObj = {
      id:value,
      label:properties.props.children
    }
    this.setState({exhibitor:{...exhibitor,eCategory:{...eCategory,...childObj}}})
  }

  onChangeHandler = (e) =>{
    let {partner,exhibitor,event} = this.state;
    
    switch(e.target.name){

      //Partner

      case 'pName':
        this.setState({partner:{...partner,pName:e.target.value}})
        console.log("onChange event",this.state.partner.pName)
      break;
      case 'pDescription':
        this.setState({partner:{...partner,pDescription:e.target.value}})
        console.log("onChange event",this.state.partner.pDescription)
      break;
      case 'pOrder':
        this.setState({partner:{...partner,pOrder:e.target.value}})
        console.log("onChange event",this.state.partner.pOrder)
      break;
      case 'pWebUrl':
        this.setState({partner:{...partner,pWebUrl:e.target.value}})
        console.log("onChange event",this.state.partner.pWebUrl)
      break;

      //Exhibitor

      case 'eName':
        this.setState({exhibitor:{...exhibitor,eName:e.target.value}})
        console.log("onChange event",this.state.exhibitor.eName)
      break;
      case 'eDescription':
        this.setState({exhibitor:{...exhibitor,eDescription:e.target.value}})
        console.log("onChange event",this.state.exhibitor.eDescription)
      break;
      case 'eOrder':
        this.setState({exhibitor:{...exhibitor,eOrder:e.target.value}})
        console.log("onChange event",this.state.exhibitor.eOrder)
      break;
      case 'eWebUrl':
        this.setState({exhibitor:{...exhibitor,eWebUrl:e.target.value}})
        console.log("onChange event",this.state.exhibitor.eWebUrl)
      break;

    }
  }

  onPartnerSubmitHandler = () =>{
    let {partner} = this.state
    let selFile = new FormData();
    let sesEventCode = sessionStorage.getItem('active-event-code');
    console.log("state",partner)
    
    selFile.append('filename',partner.eventPartnerFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"PartnersLogo");
    selFile.append('location',"info/partner");
    selFile.append('asset','logoUrl')
    selFile.append('pName',partner.pName)
    selFile.append('pDescription',partner.pDescription)
    selFile.append('pOrder',partner.pOrder)
    selFile.append('pCategoryId',partner.pCategory.id)
    selFile.append('pCategoryLabel',partner.pCategory.label)
    selFile.append('pWebUrl',partner.pWebUrl)

      axios.post(this.props.payload.apiLink+'/api/addPartner',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  onExhibitorSubmitHandler = () =>{
    let {exhibitor} = this.state
    let selFile = new FormData();
    let sesEventCode = sessionStorage.getItem('active-event-code');
    console.log("state",exhibitor)
    
    selFile.append('filename',exhibitor.eventExhibitorFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"ExhibitorsLogo");
    selFile.append('location',"info/exhibitor");
    selFile.append('asset','logoUrl')
    selFile.append('eName',exhibitor.eName)
    selFile.append('eDescription',exhibitor.eDescription)
    selFile.append('eOrder',exhibitor.eOrder)
    selFile.append('eCategoryId',exhibitor.eCategory.id)
    selFile.append('eCategoryLabel',exhibitor.eCategory.label)
    selFile.append('eWebUrl',exhibitor.eWebUrl)

      axios.post(this.props.payload.apiLink+'/api/addExhibitor',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  render(){

  
    return(
      <Layout>
      <HeaderNav></HeaderNav>
      <Content style={{ padding: '0 10%', marginTop: '150px' ,marginLeft: "auto",  marginRight: "auto"}}>
        <Card
          title="Insert Info"
          style={{ width: '100%' ,  marginLeft: "auto",  marginRight: "auto"}}
        >
          <InfoForm
            onChangeHandler = {this.onChangeHandler}
            onChangeCategoryPartner = {this.onChangeCategoryPartner}
            eventPartnerHandler = {this.eventPartnerHandler}
            onPartnerSubmitHandler = {this.onPartnerSubmitHandler}
            onChangeExhibitorPartner = {this.onChangeExhibitorPartner}
            eventExhibitorHandler = {this.eventExhibitorHandler}
            onExhibitorSubmitHandler = {this.onExhibitorSubmitHandler}
          />
        </Card>
      </Content>
      <FooterNav></FooterNav>
    </Layout>
    )
  }

}