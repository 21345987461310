import React,{Component} from 'react'
import { Layout,Menu} from 'antd';
// import 'antd/dist/antd.css';
// import '../../styles/style.css'
import {authFirebase} from '../../config'
import {Link} from 'react-router-dom'
import {openNotif} from '../../utils'
import EESY_logo_big from '../../styles/images/EESY_logo_big.png'
import dl_GooglePlay from '../../styles/images/dl_GooglePlay@2x.png'
import {dbFirebase} from '../../config'
import _ from 'lodash';

const { Header } = Layout;

let mainObj = {}

export default class Headers extends Component{
  constructor(props){
    super(props)
    this.state ={
      eventList:[{
      }],
      eventCode:null
    }
  }
  
  componentDidMount(){
    let company
    var childObj = {};
    let currentUser = this.props.payload.uid
    let userName = this.props.payload.name
    let sesEventCode = sessionStorage.getItem('active-event-code');
    this.setState({eventCode:sesEventCode})
    // // Get company
    //   dbFirebase.ref('users/authWeb/'+this.props.payload.uid).once("value").then(snap =>{
    //     console.log("snap",snap.val().company);
    //     company = snap.val().company;
    //     sessionStorage.setItem("user-company", company);
    //     return snap.val().company;;
    //   }).then(data =>{
    //     console.log("company",data)
    //     // Get event List for the Company
    dbFirebase.ref('/users/authWeb/'+currentUser).child('events').once('value').then(snaps=>{
      // console.log("snaps",snaps.val())
      if(snaps.val() != null && snaps.val() != null && snaps.val() != undefined){
        mainObj = Object.values(snaps.val())
        if(sesEventCode == null){
          this.setState({eventCode:mainObj[mainObj.length-1].eventCode})
        }
      } 
      else mainObj = {}

      this.setState({
        eventList:_.values(mainObj)
      });
    }) 
    // })
  }

  onLogOutHandler = () =>{
    authFirebase.signOut().then(function() {
      // Sign-out successful.
      sessionStorage.removeItem('logged-in');
      sessionStorage.removeItem('user-session');
      sessionStorage.removeItem('active-event-code');
      openNotif("success","Succesfully Logged Out!","")
    }).catch(function(error) {
      // An error happened.
    });
  }

  setActiveEvent = (item) =>{
    openNotif('success',"Event Code "+item.eventCode+" Active","");
    sessionStorage.setItem("active-event-code", item.eventCode);
    this.setState({eventCode:item.eventCode})
  }



  render(){
    return(
      <header>
        <div className="header-left">
          <div className="eesy-logo"><Link to = '/'><img src={EESY_logo_big} /></Link>  </div><span className="user-section-label">BETA</span>
          <div className="events">
            <span className="event-selected">{this.state.eventCode}</span><i className="material-icons">keyboard_arrow_down</i>
            <ul className="event-list slide-up">
            
              {this.state.eventList.map((item,index)=>(
                <li onClick = {()=> this.setActiveEvent(item)}><a href="javascript:void(0)">{item.eventCode}</a></li>
              ))}

            </ul>
          </div>
        </div>
        <div className="header-right">
          {/* <div className="notification"><a href="#"><i className="material-icons">comment</i><span className="badge-count">3</span></a></div> */}
          {/* <div className="search-events">
            <input type="text" placeholder="Search events" />
            <i className="material-icons">search</i>
          </div> */}
          <div className="user-section">
            <i className="material-icons">face</i>
            <span className="user-section-label">{this.props.payload.name}</span>
            <i className="material-icons">keyboard_arrow_down</i>
            <ul className="user-section-settings slide-up">
              <li><a disabled href="#"><i className="material-icons font-med">publish</i> Publish App</a></li>
              <li><a disabled href="#"><i className="material-icons font-med">settings</i> Account Settings</a></li>
              <li><a disabled href="#"><i className="material-icons font-med">event_note</i> Manage Events</a></li>
              <Link to ="/Settings"><li><a href="#"><i className="material-icons font-med">view_list</i> Feature Settings</a></li></Link> 
              {/* <li><a disabled href="#"><i className="material-icons font-med">view_list</i> Feature Settings</a></li> */}
              <li><a target="_blank" href="https://docs.google.com/presentation/d/e/2PACX-1vSeunsRf_46mH7RAljB8CbTZeGxP9tcU8Iq0Y9NN_UNrrNPAfBE0uH__U86QV6i3u1_FhnjhOJjTeQO/pub?start=true&loop=false&delayms=3000&slide=id.g4fb3714e55_0_7"><i className="material-icons font-med">help</i> Help</a></li>
              <li><a target="_blank" href="https://docs.google.com/document/d/1Bz74nJNtwf4IQsQLoNImRUJK_Y59mF_aw5-kWqTk0Bg/pub?hl=en"><i className="material-icons font-med">vpn_key</i> Privacy Policy</a></li>
              <li><a target="_blank" href="https://docs.google.com/document/d/1bkcSthutP1etAWNiwZBFK7eX_Po8MHyPdqArgq2W7ts/pub?hl=en"><i className="material-icons font-med">import_contacts</i> Terms of Use</a></li>
              <li><a disabled href="#"><i className="material-icons font-med">info</i> About GanApp</a></li>
              <Link to ="/Login"  onClick = {this.onLogOutHandler}><li><a href="#"><i className="material-icons font-med">exit_to_app</i> Logout</a></li></Link>

            </ul>
          </div>
        </div>
      </header>

    )
  }
}