

import React,{Component} from 'react'
import {Button,Input,Select,Spin,Avatar,DatePicker,TimePicker,Table} from 'antd'
import moment from 'moment'

const Option = Select.Option;
const format = 'HH:mm';
const { TextArea } = Input;

export default class contentForm extends Component{
  constructor(props){
    super(props)
  }

  style ={
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px"
  }

  render(){
    return(

      <div className= "main">

        <div className="top">
          <h1>Sessions</h1>
          {/* <div className="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
        </div>
        <div className="content">
          <h1 className="title-fancy">What's the <span class="emp">program</span><span className="font-huge">?</span></h1>
          <h2 className="title-fancy-sub">Upload the session details here</h2>
          
          <section className="section">
            
            <div className="space-between">
              <div class="tab tab-gray">
                {/* <a href="#" className="active">Day 1</a>
                <a href="#">Day 2</a>
                <a href="#">Day 3</a> */}
                
                {this.props.tabsList.map((item,index)=>(
                    // {/* Tab:  <span onClick = {(e)=> this.props.showTabModalEdit(item.id)}>{item.title}   </span> */}
                  <span>
                    <a href="#" style = {{marginRight:"-20px"}} onClick = {(e)=> this.props.showTabModalEdit(item.id) }>{item.title}</a> 
                    
                    <a href="#" title="Delete"  onClick = {(e)=> this.props.handleTabDelete(item.id)} ><i class="material-icons status-orange">delete</i></a>
                    {/* <button onClick = {(e)=> this.props.handleTabDelete(item.id)}>Delete</button> */}
                  </span>

                  ))}
                <a href="javascript:void(0);" onClick = {(e)=> this.props.showTabModalAdd()}>+ Add Day</a>
              </div>

             
              {/* <div>
                <i className="material-icons font-small status-red">lens</i> Incomplete Details
                <span className="h-space-small"></span>
                <i className="material-icons font-small status-green">lens</i> Complete Details
              </div> */}
            </div>

            {/* <Button type="primary" block onClick = {(e)=> this.props.showTabModalAdd()}>Add Tab</Button>
              <br></br>   
              <br></br>   */}

            <h3 class="tbl-title">Program at a glance</h3>
            <Button disabled={this.props.eventEnded} block onClick = {(e)=> this.props.showAgendaModalAdd()} className = "btn-gray-dash-add">Add Agenda</Button>
            <Table 
              className="tbl tbl-white -has-hover" cellpadding="0" cellspacing="0" border="0"
              columns={this.props.AgendaColumns} 
              dataSource={this.props.listAgenda} 
              onChange={this.props.handleAgendaTableChange} 
              pagination={{ pageSize: 10 }} 
              onRow={(record) => ({
                onClick: () => { this.props.showAgendaModalEdit(record); }
            })}
            />
          </section>
        </div>
      </div>

 
    )
  }
}

  
   