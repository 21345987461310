import React,{Component} from 'react';
import {Input,Form,Button} from 'antd'

export default class venueModal extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <Form 
      className='Login-Form' 
      onSubmit= {(e)=> this.props.onSubmitVenHandler(e,this.props)} 
      layout = "vertical" 
      style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}
      >
        <div className="fld-item">
          <label>Venue name:</label>
          <Input 
            onChange={this.props.onChangeHandler} 
            className="fld"
            size="default" 
            name='venName' 
            placeholder="Input VenueName"
            defaultValue = {this.props.venData.venName}
          />
        </div>

        <div className="fld-item">
          <label>Upload Venue Image:</label>
          <input type = "file" onChange={(e)=>this.props.eventVenFileHandler(e)} />
          <div class="text-gray v-space-large">Maximum upload file size: 25mb Dimensions should be 1000px : 790px (rectangle)</div>
        </div>

        <div className="fld-item">
          <Button disabled={this.props.eventEnded} type="primary" htmlType='submit' block>Save</Button>
        </div>

      </Form>
    )
  }
}