import React,{Component} from 'react'
import ContentForm from './Content/contentForm'
import { Layout,Card,Button,Popconfirm,Modal,Avatar} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import AgendaModal from './Content/agendaModal'
import TabsModal from './Content/tabsModal'
import {openNotif} from '../utils'

import axios from 'axios'
import debounce from 'lodash/debounce'
import _ from 'lodash'
import moment from 'moment'

const {Content} = Layout;

// --------------------------- Not Using Anymore -------------------------------//
export default class Contents extends Component{
  constructor(props){
    super(props);
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      color:{
        headerLabel:'',
        headerTitle:'',
        onGoing:'',
        primary:'',
        secondary:''
      },

      selectedData: [{
        name:null,
        id:null,
        imageLink:null
      }],
      fetching: false,

      tabs:{
        tBilling:'',
        tDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        tTitle:'',
        submitStatus:'',
        eventCode:'',
        tId:''
      },
      
      tabsList:[{
        billing:'',
        date:'',
        title:'' ,
        id:''
      }],

      agenda:{
        aProgramTitle:"",
        aDescription:"",
        aDate:moment(moment().toDate()).format('YYYY-MM-DD') ,
        aTimeStart:"12:00",
        aTimeEnd:"12:00",
        aDuration:"",
        // aSpeaker:[],
        // aModerator:[],
        // aHost:[],
        // aPanelist:[],
        aVenue:{
          id:'',
          name:''
        },
        aVenueKey:'',
        aId:'',
        aEventCode:'',
        expertValue: [],
        expertKeyArr:[],
        submitStatus:'',
        aIsRatable:true
      },

      modalStatus:null,

      venueList:[{
        name:null,
        id:null
      }],

      event:{
        eventName:'',
        eventDescription:'',
        eventAddress:'',
        eventCoordinates:'',
        eventVenue:'',
        eventHashTag:'',
        eventStartDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        eventEndDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        eventTimeStart:"12:00",
        eventTimeEnd:"12:00",
      },

      listAgenda:[{
        allowAskAnonymous: false,
        description: "",
        id: "",
        isRatable: true,
        pollUrl: "",
        program: "",
        schedType: 1,
        sessionType: 1,
        surverUrl: "",
        time: "",
        timeEnd: "",
        timeStart: "",
        track: "",
        venue:{
          id:"",
          name:"",
          url:""
        },
        expert:[{
          id:"",
          imageLink:"",
          isRatable:true,
          name:"",
          role:""
        }],
        expertNames:"",
        dateStart:'',
        unFormatTimeStart:'',
        dateKey:'',

      }],

      filteredAgenda: null,
      sortedAgenda: null,
      
      agendaModalVisible:false,
      tabModalVisible:false
      
    }
  }

  onSubmitTabsHandler = (e) =>{
    e.preventDefault();
    let {tabs,tabsList} = this.state
    console.log("tabs",tabs)
    if(tabs.submitStatus == 'add'){
      axios.post(this.props.payload.apiLink+'/api/addTabs',
      tabs)
      .then((response) =>{
        console.log(response);

        tabsList.push({
          billing:tabs.tBilling,
          date:tabs.tDate,
          title:tabs.tTitle,
          id:response.data.tabId
        })

        this.setState({ tabsList,
          tabModalVisible: false });
        openNotif('success',"Tab Added!","Tab Succesfully Added!")
        })
      .catch((error) =>{
        console.log(error);
      });
    }
    else if(tabs.submitStatus == 'edit'){

      let index = tabsList.findIndex(x => x.id==tabs.tId);

      axios.post(this.props.payload.apiLink+'/api/editTab',
      tabs)
      .then((response) =>{
        console.log(response);

        const newItems = [...tabsList];

        newItems[index].billing = tabs.tBilling;
        newItems[index].date = tabs.tDate;
        newItems[index].title = tabs.tTitle;

        this.setState({ tabsList:newItems,
          tabModalVisible: false });
        openNotif('success',"Tab Edited!","Tab Succesfully Edited!")
        })
      .catch((error) =>{
        console.log(error);
      });
    }
  }

  showTabModalAdd = (record) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    this.setState({
      tabModalVisible: true,
      tabs:{
        tBilling:'',
        tDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        tTitle:'' ,
        submitStatus:'add',
        eventCode:sesEventCode
      }
    });
  }

  showTabModalEdit = (id) =>{
    let {tabs,tabsList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    let index = tabsList.findIndex(x => x.id==id);
    
    this.setState({
      tabModalVisible: true,
      tabs:{
        tBilling:tabsList[index].billing,
        tDate:tabsList[index].date,
        tTitle:tabsList[index].title,
        tId:tabsList[index].id,
        submitStatus:'edit',
        eventCode:sesEventCode
      }
    });
  }

  handleTabDelete = (id) =>{
    let {tabs,tabsList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    
    axios.delete(this.props.payload.apiLink+'/api/deleteTab/'+sesEventCode+'/'+id)
      .then((response) =>{
        console.log(response);

        let index = tabsList.findIndex(x => x.id==id);
        tabsList.splice(index,1);

        this.setState({ tabsList,
          tabModalVisible: false });
        openNotif('success',"Tab Deleted!","Tab Succesfully Deleted!")
        })
      .catch((error) =>{
        console.log(error);
      });
  }

  onChangeIsRatable = (checked) =>{
    let {agenda} = this.state
    console.log(`switch to ${checked}`);
    this.setState({agenda:{...agenda,aIsRatable:checked}})

  }

  convert(input) {
    return moment(input, 'HH:mm:ss').format('hh:mm A');
  }

  componentDidMount = () =>{
    let {color,event} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    //Get Tabs
    
    axios.get(this.props.payload.apiLink+'/api/getTabs/'+sesEventCode)
    .then(response =>{
      console.log("Tabs",response)
      let tabsList = _.values(response.data).map(tabs => ({
        billing:tabs.billing,
        date:tabs.date,
        id:tabs.id,
        title:tabs.title,
      }));
      this.setState({ tabsList });
    }).catch(function (error) {
      console.log(error);
    });
    
    //Get Venue
    axios.get(this.props.payload.apiLink+'/api/getVenue/'+sesEventCode)
    .then(response =>{
      console.log("Venues",response)
      let venueList = _.values(response.data).map(venue => ({
        name: venue.name,
        id: venue.id+'-'+venue.url,
      }));
      console.log("venueList",venueList)
      this.setState({ venueList });

    }).catch(function (error) {
      console.log(error);
    });

    //Get Color

    axios.get(this.props.payload.apiLink+'/api/getColors/'+sesEventCode)
    .then(response =>{
      console.log("colors",response)
      if(response.data != ""){
        color = {
          headerLabel:response.data.headerLabelColor,
          headerTitle:response.data.headerTitleColor,
          onGoing:response.data.onGoingColor,
          primary:response.data.primaryColor,
          secondary:response.data.secondaryColor
        }
        this.setState({ color });
      }

    }).catch(function (error) {
      console.log(error);
    });

    //Get Properties

    axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
    .then(response =>{
      console.log("properties",response)
      event= {
        eventName:response.data.name,
        eventDescription:response.data.description,
        eventAddress:response.data.location.address,
        eventCoordinates:response.data.location.coordinates,
        eventVenue:response.data.location.venue,
        eventHashTag:response.data.hashtag,
        eventStartDate:response.data.startDate == '' ? moment(moment().toDate()).format('YYYY-MM-DD') :moment(response.data.startDate).format('YYYY-MM-DD'),
        eventEndDate:response.data.endDate == '' ? moment(moment().toDate()).format('YYYY-MM-DD'): moment(response.data.endDate).format('YYYY-MM-DD'),
        eventTimeStart:response.data.startDate == '' ? moment(moment().toDate()).format('h:mm A'):moment(response.data.startDate).format('h:mm A'),
        eventTimeEnd:response.data.endDate == '' ? moment(moment().toDate()).format('h:mm A'):moment(response.data.endDate).format('h:mm A'),
      }
      console.log("nope,",response.data.startDate == '' ? moment(moment().toDate()).format('h:mm A'):moment(response.data.startDate).format('h:mm A'),)
      this.setState({ event });
    }).catch(function (error) {
      console.log(error);
    });

    //Get Agenda
    axios.get(this.props.payload.apiLink+'/api/getAllAgenda/'+sesEventCode)
    .then(response =>{
      // console.log("responseAgenda",response)
      // console.log("responseAgenda1",_.values(response.data))
      // console.log("responseAgenda2",response)

      let expertsString = "";
      // this.convert(agenda.timeStart.getHours()  + ':' + agenda.timeStart.getMinutes()+ ':' + agenda.timeStart.getSeconds()) + "-" +this.convert(agenda.timeEnd.getHours()  + ':' + agenda.timeEnd.getMinutes()+ ':' + agenda.timeEnd.getSeconds()),
      let listAgenda = _.values(response.data).map(agenda => ({
        allowAskAnonymous: agenda.allowAskAnonymous,
        description: agenda.description,
        id: agenda.id,
        isRatable: agenda.isRatable,
        pollUrl: agenda.pollUrl,
        program: agenda.program,
        schedType: agenda.schedType,
        sessionType: agenda.sessionType,
        surverUrl: agenda.surverUrl,
        time: agenda.time,
        timeEnd: agenda.timeEnd,
        timeStart: agenda.timeStart,
        unFormatTimeStart: agenda.timeStart,
        track: agenda.track,
        venue:agenda.venue,
        expert:_.values(agenda.expert),
        expertNames:"",
        dateStart:'',
        dateKey:''
      }));

      for(let agendaIndex = 0;listAgenda.length > agendaIndex; agendaIndex++){
        
        let dateStart = new Date(parseInt(listAgenda[agendaIndex].timeStart))

        let hoursStart = dateStart.getHours();
        // Minutes part from the timestamp
        let minutesStart = "0" + dateStart.getMinutes();
        // Seconds part from the timestamp
        let secondsStart = "0" + dateStart.getSeconds();

        let yearStart = dateStart.getFullYear();
        let monthStart = dateStart.getMonth()+1;
        let dayStart = dateStart.getDate();
        //For table data
        let formattedDate =  monthStart + ' / ' + dayStart + ' / ' + yearStart
        let formattedDateKey = yearStart + '-' + monthStart + '-' + dayStart

        // Will display time in 10:30:23 format
        let formattedStartTime = hoursStart + ':' + minutesStart.substr(-2) + ':' + secondsStart.substr(-2);
        listAgenda[agendaIndex].timeStart = this.convert(formattedStartTime)
        // assign formatted data
        listAgenda[agendaIndex].dateStart = formattedDate
        listAgenda[agendaIndex].dateKey = formattedDateKey
        //End Date
        let dateEnd= new Date(parseInt(listAgenda[agendaIndex].timeEnd))
        let hoursEnd = dateEnd.getHours();
        let minutesEnd = "0" + dateEnd.getMinutes();
        let secondsEnd = "0" + dateEnd.getSeconds();
        let year = dateEnd.getFullYear();
        let formattedEndTime = hoursEnd + ':' + minutesEnd.substr(-2) + ':' + secondsEnd.substr(-2);
        listAgenda[agendaIndex].timeEnd = this.convert(formattedEndTime)

        listAgenda[agendaIndex].time = this.convert(formattedStartTime) + '-' + this.convert(formattedEndTime)

        //list of expert for showing
        expertsString = ""
        for(let i = 0;listAgenda[agendaIndex].expert.length >i;i++){
          expertsString = expertsString + listAgenda[agendaIndex].expert[i].name + ',';
          listAgenda[agendaIndex].expertNames = expertsString
        }
        
        listAgenda[agendaIndex].expertNames = listAgenda[agendaIndex].expertNames.slice(0,-1)
        
      }
      console.log("listAgenda",listAgenda)

      this.setState({ listAgenda });

    }).catch(function (error) {
      console.log(error);
    });
  }


  fetchUser = (value) => {
    let sesEventCode = sessionStorage.getItem('active-event-code');
    console.log('fetching user', value);
    this.setState({ selectedData: [], fetching: true });

    if(value != '' && value != null){
      axios.get(this.props.payload.apiLink+'/api/getExpertLike/'+sesEventCode+"/"+value.toLowerCase().replace(/\s/g,''),
        ).then((body) => {
          console.log("body",body)
          
          const selectedData = _.values(body.data).map(user => ({
            name: user.name,
            id: user.id,
            imageLink:user.imageLink
          }));

          console.log("data",selectedData)
          this.setState({ selectedData, fetching: false });
        })
        .catch(function (error) {
          console.log(error);
        });
  
    }
    
  }

  handleExpertChange = (expertValue) => {
    let {agenda} = this.state
    console.log("valuee",expertValue)

    let newExpertList = []

    
    for(let exp = 0; exp < expertValue.length; exp ++){
      let expIndex = newExpertList.findIndex(x => x.key==(expertValue[exp].key).toLowerCase().replace(/\s/g,''));
      console.log("expIndex",expIndex)
      if(expIndex == -1){
        newExpertList.push({
          key:expertValue[exp].key.toLowerCase().replace(/\s/g,''),
          label:expertValue[exp].label
        })
      }

    }

    console.log("newExpertList",newExpertList)

    this.setState({
      agenda:{...agenda,expertValue:newExpertList},
      selectedData: [],
      fetching: false,
    });


  }

  onChangeDate = (date, dateString) => {
    let {agenda} = this.state;
    console.log(date);
    console.log(dateString);
    this.setState({agenda:{...agenda,aDate:dateString}})
  }

  onChangeTabDate = (date, dateString) => {
    let {tabs} = this.state;
    console.log(date);
    console.log(dateString);
    this.setState({tabs:{...tabs,tDate:dateString}})
  }

  onChangeEventStartDate = (date, dateString) => {
    let {event} = this.state;
    console.log(event);
    console.log(dateString);
    this.setState({event:{...event,eventStartDate:dateString}})
  }

  onChangeEventEndDate = (date, dateString) => {
    let {event} = this.state;
    console.log(event);
    console.log(dateString);
    this.setState({event:{...event,eventEndDate:dateString}})
  }

  onChangeEventStartTime = (time, timeString) => {
    let {event} = this.state;
    let convertedTime = moment(timeString, 'h:m:s A').format('HH:mm')
    console.log(convertedTime);
    this.setState({event:{...event,eventTimeStart:convertedTime}})
  }

  onChangeEventEndTime = (time, timeString) => {
    let {event} = this.state;
    let convertedTime = moment(timeString, 'h:m:s A').format('HH:mm')
    console.log(convertedTime);
    this.setState({event:{...event,eventTimeEnd:convertedTime}})
  }

  onChangeStartTime = (time, timeString) => {
    let {agenda} = this.state;
    let convertedTime = moment(timeString, 'h:m:s A').format('HH:mm')
    console.log(convertedTime);
    this.setState({agenda:{...agenda,aTimeStart:convertedTime}})
  }

  onChangeEndTime = (time, timeString) => {
    let {agenda} = this.state;
    let convertedTime = moment(timeString, 'h:m:s A').format('HH:mm')
    console.log(convertedTime);
    this.setState({agenda:{...agenda,aTimeEnd:convertedTime}})
  }

  onChangeVenue = (value,properties) => {
    let {agenda,aVenue,venue} = this.state;
    console.log("value",value)
    let childObj = {
      id:value,
      name:properties.props.children[1]
    }
    console.log("venueObj",childObj)
    this.setState({agenda:{...agenda,aVenue:{...aVenue,...childObj}}})
  }

  venueFileHandler = event =>{
    console.log("lg",event.target.files)
    let {venue} = this.state;
    
    this.setState({
      venue:{...venue,venueFile:event.target.files}
    })

  }

  onChangeHandler = (e) => {
    let {color,agenda,event,tabs} = this.state;
    //Color
    switch(e.target.name){
      case 'headerLabelColor':
        this.setState({color:{...color,headerLabel:e.target.value}})
        console.log("onChange event",this.state.color.headerLabel)
        break;
      case 'headerTitleColor':
        this.setState({color:{...color,headerTitle:e.target.value}})
        console.log("onChange event",this.state.color.headerTitle)
      break;
      case 'onGoingColor':
        this.setState({color:{...color,onGoing:e.target.value}})
        console.log("onChange event",this.state.color.headerTitle)
      break;
      case 'primaryColor':
        this.setState({color:{...color,primary:e.target.value}})
        console.log("onChange event",this.state.color.headerTitle)
      break;
      case 'secondaryColor':
        this.setState({color:{...color,secondary:e.target.value}})
        console.log("onChange event",this.state.color.headerTitle)
      break;

      //Agenda
      case 'aProgramTitle':
        this.setState({agenda:{...agenda,aProgramTitle:e.target.value}})
        console.log("onChange event",this.state.agenda.aProgramTitle)
      break;
      case 'aDescription':
        this.setState({agenda:{...agenda,aDescription:e.target.value}})
        console.log("onChange event",this.state.agenda.aDescription)
      break;
      case 'aDate':
        this.setState({agenda:{...agenda,aDate:e.target.value}})
        console.log("onChange event",this.state.agenda.aDate)
      break;
      case 'aDuration':
        this.setState({agenda:{...agenda,aDuration:e.target.value}})
        console.log("onChange event",this.state.agenda.aDuration)
      break;
      
      //Event Details

      case 'eventName':
        this.setState({event:{...event,eventName:e.target.value}})
        console.log("onChange event",this.state.event.eventName)
      break;
      case 'eventDescription':
        this.setState({event:{...event,eventDescription:e.target.value}})
        console.log("onChange event",this.state.event.eventDescription)
      break;
      case 'eventAddress':
        this.setState({event:{...event,eventAddress:e.target.value}})
        console.log("onChange event",this.state.event.eventAddress)
      break;
      case 'eventVenue':
        this.setState({event:{...event,eventVenue:e.target.value}})
        console.log("onChange event",this.state.event.eventVenue)
      break;
      case 'eventCoordinates':
        this.setState({event:{...event,eventCoordinates:e.target.value}})
        console.log("onChange event",this.state.event.eventCoordinates)
      break;
      case 'eventHashTag':
        this.setState({event:{...event,eventHashTag:e.target.value}})
        console.log("onChange event",this.state.event.eventHashTag)
      break;

      //Tabs
      case 'tTitle':
        this.setState({tabs:{...tabs,tTitle:e.target.value}})
        console.log("onChange event",this.state.tabs.tTitle)
      break;
      case 'tBilling':
        this.setState({tabs:{...tabs,tBilling:e.target.value}})
        console.log("onChange event",this.state.tabs.tBilling)
      break;
    }
  }

  onSubmitColorHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    axios.put(this.props.payload.apiLink+'/api/addColors/'+sesEventCode,
        this.state.color
      ).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSubmitAgendaHandler = (e) =>{
   e.preventDefault();
   let {agenda,listAgenda} = this.state
   let sesEventCode = sessionStorage.getItem('active-event-code');


    if(agenda.submitStatus == 'add'){
      
      let dataObj = {
        aProgramTitle:agenda.aProgramTitle,
        aDescription:agenda.aDescription,
        aDate:agenda.aDate,
        aTimeStart:agenda.aTimeStart,
        aTimeEnd:agenda.aTimeEnd,
        aDuration:agenda.aDuration,
        expertValue:agenda.expertValue,
        aVenue:agenda.aVenue,
        eventCode:sesEventCode,
        aIsRatable:agenda.aIsRatable
      }

      let newExpertArr=[]
      let expertArr = _.values(agenda.expertValue)
      let expertString = "";

      for(let expertCnt = 0; expertCnt < expertArr.length; expertCnt++){
        newExpertArr.push({
          id:expertArr[expertCnt].key,
          name:expertArr[expertCnt].label,
          imageLink:"",
          isRatable:true,
          role:''
        })
        expertString = expertString + expertArr[expertCnt].label + ','
      }
      let newDateStart = new Date(agenda.aDate)

      console.log("newDateStart",newDateStart)

      let yearStart = newDateStart.getFullYear();
      let monthStart = newDateStart.getMonth()+1;
      let dayStart = newDateStart.getDate();
      //For table data
      let tableFormatDateStart =  monthStart + ' / ' + dayStart + ' / ' + yearStart

      console.log("tableFormatDateStart",tableFormatDateStart)
      let startTimeStamp = new Date (agenda.aDate + " " +agenda.aTimeStart+ " GMT+0800").getTime().toString();

      console.log("startTimeStamp",startTimeStamp)

      let newVenue = {}

      if(agenda.aVenue.id != '' && agenda.aVenue.id != null && agenda.aVenue.id != undefined){
        
        let venueId = agenda.aVenue.id.substring(0,7)
        let venueUrl = agenda.aVenue.id.substring(8,agenda.aVenue.id.length)

        newVenue = {
          id:venueId,
          url:venueUrl,
          name:agenda.aVenue.name
        }
      }
        
      console.log("asd")
      let newItem = {}
      axios.post(this.props.payload.apiLink+'/api/addAgenda2',
      dataObj
      ).then((response) => {
        console.log(response);
        console.log("inside axios")
        newItem = {
          allowAskAnonymous: false,
          description: agenda.aDescription,
          id: response.data.agendaId,
          isRatable: true,
          pollUrl: '',
          program: agenda.aProgramTitle,
          schedType: 1,
          sessionType: 1,
          surverUrl: "",
          time:  moment(agenda.aTimeStart, 'HH:mm').format('hh:mm A')+ "-"+moment(agenda.aTimeEnd, 'HH:mm:ss').format('hh:mm A'),
          timeEnd: moment(agenda.aTimeEnd, 'HH:mm').format('hh:mm A'),
          timeStart: moment(agenda.aTimeStart, 'HH:mm').format('hh:mm A'),
          unFormatTimeStart: startTimeStamp,
          track: "",
          venue:newVenue,
          expert:newExpertArr,
          expertNames:expertString.slice(0,-1),
          dateStart:tableFormatDateStart,
          dateKey:agenda.aDate,
          isRatable:agenda.aIsRatable
        }

        listAgenda.push(newItem);
        
        this.setState({listAgenda,
          agendaModalVisible: false})

          openNotif('success',"Session Created","Session Succesfully Created!")

      })
      .catch(function (error) {
        console.log(error);
      }); 

    }
    else if(agenda.submitStatus == 'edit'){

      let stringifyVenueKey = JSON.stringify(agenda.aVenueKey)
      let expertNamesArr = [];
      agenda.aVenueKey = stringifyVenueKey
      let expertString = '';
  
      let index = listAgenda.findIndex(x => x.id==agenda.aId);
  
      for(let exp = 0; exp < agenda.expertValue.length;exp++){
        // expertNamesArr.push(agenda.expertValue[exp].label)
        console.log("length",agenda.expertValue.length)
        console.log("index",exp)
        // console.log("agenda.expertValue[exp].label[2] ",agenda.expertValue[exp].label[2] )
        // if(agenda.expertValue[exp].label.constructor === Array) expertString = expertString + agenda.expertValue[exp].label[2] + ',';
        
        // else 
        expertString = expertString + agenda.expertValue[exp].label
      }
      console.log("expetString",expertString)
  
      let venueId ='';
      let venueUrl = '';
  
      console.log("venueId2",agenda.aVenue.id)
      if(agenda.aVenue.id != '' && agenda.aVenue.id != null && agenda.aVenue.id != undefined){
        venueId = agenda.aVenue.id.substring(0,7)
        venueUrl = agenda.aVenue.id.substring(8,agenda.aVenue.id.length)
        console.log("venueId",venueId)
      }
  
      let timeStampTimeStart = new Date (agenda.aDate + " " +moment(agenda.aTimeStart, 'h:mm A').format('HH:mm')+ " GMT+0800").getTime();
      
      let newDate = new Date(timeStampTimeStart)
      let year = newDate.getFullYear();
      let date = newDate.getMonth()+1;
      let day = "0"+newDate.getDate();
      //For table data
      let newDateText =  date + ' / ' + day + ' / ' + year
  
      let formatTimeStart = moment(agenda.aTimeStart, 'HH:mm').format('h:mm A')
      let formatTimeEnd = moment(agenda.aTimeEnd, 'HH:mm').format('h:mm A')
  
  
      const newItems = [...this.state.listAgenda];
      newItems[index].program = agenda.aProgramTitle;
      newItems[index].isRatable = agenda.aIsRatable;
      newItems[index].description = agenda.aDescription;
      newItems[index].expertNames = expertString.slice(0,-1);
      newItems[index].dateKey = agenda.aDate;
      newItems[index].unFormatTimeStart = timeStampTimeStart;
      newItems[index].timeStart = formatTimeStart;
      newItems[index].timeEnd = formatTimeEnd;
      newItems[index].dateStart = newDateText;
      newItems[index].time = formatTimeStart + "-" + formatTimeEnd;
      newItems[index].venue =  {
                                  id:venueId,
                                  name:agenda.aVenue.name,
                                  url:venueUrl
                                }
      
  
      //Add New Expert Object to ListAgenda(table)
      for(let exp = 0; exp < agenda.expertValue.length;exp++){
        let exist = this.state.listAgenda[index].expert.findIndex(x => x.id==agenda.expertValue[exp].key);
        console.log("index",exist)
  
        if(exist == -1){
          newItems[index].expert.push({
            id:agenda.expertValue[exp].key,
            name:agenda.expertValue[exp].label,
            imageLink:''
          })
        }
      }
  
      console.log("listAgendaOnEdit",listAgenda)
  
      axios.post(this.props.payload.apiLink+'/api/editAgenda',
      agenda)
      .then((response) =>{
          console.log(response);
          this.setState({ listAgenda:newItems,agenda:{},
            agendaModalVisible: false });
          openNotif('success',"Session Edited!","Session Succesfully Edited!")
        })
        .catch((error) =>{
          console.log(error);
      });
  

    }

  }

  onSubmitEventDetailsHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let date = moment(this.state.event.eventStartDate +" "+this.state.event.eventTimeStart).format();

    console.log("date",this.state.event);
    console.log("date JSON",moment(this.state.event.eventStartDate +" "+this.state.event.eventTimeStart).format());
    console.log("date iso",new Date(this.state.event.eventStartDate).toISOString().slice(0,-14));
    let dataObj = {
      eventAddress: this.state.event.eventAddress,
      eventCoordinates: this.state.event.eventCoordinates,
      eventDescription: this.state.event.eventDescription,
      eventEndDate: moment(this.state.event.eventEndDate +" "+this.state.event.eventTimeEnd).format(),
      eventHashTag: this.state.event.eventHashTag,
      eventName: this.state.event.eventName,
      eventStartDate: moment(this.state.event.eventStartDate +" "+this.state.event.eventTimeStart).format(),
      eventTimeEnd: this.state.event.eventTimeEnd,
      eventTimeStart: this.state.event.eventTimeStart,
      eventVenue: this.state.event.eventVenue,
    }

    console.log("dataobj",dataObj)
    axios.put(this.props.payload.apiLink+'/api/addEventDetails/'+sesEventCode,
        dataObj
      ).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  onSubmitVenueHandler = () =>{
    let {venue} = this.state
    let selFile = new FormData();
    let sesEventCode = sessionStorage.getItem('active-event-code');
    selFile.append('filename',venue.venueFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"Assets");
    selFile.append('location',"maps");
    selFile.append('asset','url')
    selFile.append('venueName',venue.venueName)

    axios.post(this.props.payload.apiLink+'/api/addVenue',
      selFile,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  handleAgendaTableChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredAgenda: filters,
      sortedAgenda: sorter,
    });
  }


  handleAgendaDelete = (agenda) =>{
    let {listAgenda} = this.state
    // delete listAgenda[index-1]

    let expertArr = []
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let index = listAgenda.findIndex(x => x.id==agenda);
    console.log("indexx",index)

    console.log("agendatodelete",listAgenda[index]);

    for(let exp = 0; exp < listAgenda[index].expert.length;exp++){
      expertArr.push(listAgenda[index].expert[exp].id)
    }

    // console.log("expertArr",listAgenda[index].venue.id)

    let venueId = listAgenda[index].venue == undefined ? '':listAgenda[index].venue.id;
    let agendaId = listAgenda[index].id;
    listAgenda.splice(index,1);
    


    
    //delete on Rest does not allow request body, need to pass array of experts to delete
    axios.post(this.props.payload.apiLink+'/api/deleteAgenda/'+sesEventCode+'/'+agenda,expertArr)
    .then((response) =>{
      console.log(response);
      if(venueId != ''){
        console.log("isnde")
        axios.delete(this.props.payload.apiLink+'/api/deleteVenueAgenda/'+sesEventCode+'/'+venueId+'/'+agendaId)
        .then((response) =>{
          console.log(response);
          this.setState({
            listAgenda
          });
          openNotif('success',"Event Deleted","Event Succesfully Deleted!")
        })      
        .catch(function (error) {
          console.log(error);
        });
      }else{
        this.setState({
          listAgenda
        });
        openNotif('success',"Event Deleted","Event Succesfully Deleted!")
      }

      
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  showAgendaModalEdit = (record) => {
    this.setState({modalStatus:1})
    console.log("record",record)
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {agenda,expertValue} = this.state
    let expertArr = []
    let experts =  _.values(record.expert)
    let expertObj = []
    let expertKeyArr = []
    console.log("experts",expertValue)

    //Default value for Experts
    for(let i = 0;i < experts.length;i++){
      //For Speaker Label Object
      // expertArr.push(<Avatar src={experts[i].imageLink}  size="small"/>)
      // expertArr.push("   ")
      expertArr.push(experts[i].name)
      //List of Expert Keys for Insert
      expertKeyArr.push(experts[i].name)
      //For speaker drop down
      expertObj.push ({
        key:experts[i].id,
        label:expertArr
      })
      expertArr = []
    }
    console.log("currentagendaState",agenda)
    // let venueId = (record.venue.id +'-'+record.venue.url).toString()
    // console.log("venueId",JSON.stringify(venueId))
    //state to pass on submit
    agenda = {
      aProgramTitle:record.program,
      aDescription:record.description,
      aDate:record.dateKey,
      aTimeStart:moment(record.timeStart, 'h:mm A').format('HH:mm'),
      aTimeEnd:moment(record.timeEnd, 'h:mm A').format('HH:mm'),
      aVenueKey:record.venue ==undefined ? '':(record.venue.id +'-'+record.venue.url),
      expertValue:expertObj,
      aVenue:record.venue==undefined ? '':{
        id:(record.venue.id +'-'+record.venue.url),
        name:record.venue.name
      },
      aId:record.id,
      aEventCode:sesEventCode,
      submitStatus:'edit',
      aIsRatable:record.isRatable
    }


    this.setState({
      expertValue:expertObj,
      agenda,
      agendaModalVisible:true
    });
  }

  showAgendaModalAdd = (record) => {
    let {agenda} = this.state
    console.log("record",record)
    console.log("agendaAdd",agenda)
    this.setState({modalStatus:0})

    this.setState({
      agendaModalVisible: true,
      agenda:{
        aProgramTitle:"",
        aDescription:"",
        aDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        aTimeStart:"12:00",
        aTimeEnd:"12:00",
        aDuration:"",
        aVenue:{
          id:'',
          name:''
        },
        aVenueKey:'',
        aId:'',
        aEventCode:'',
        expertValue: [],
        expertKeyArr:[],
        submitStatus:'add',
        aIsRatable:false
      }
    });
  }

  handleAgendaModalOk = (e) => {
    console.log(e);
    this.setState({
      agendaModalVisible: false,
    });
  }

  handleAgendaModalCancel = (e) => {
    console.log(e);
    this.setState({
      agendaModalVisible: false,
      agenda:{
        aProgramTitle:"",
        aDescription:"",
        aDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        aTimeStart:"12:00",
        aTimeEnd:"12:00",
        aDuration:"",
        aVenue:{
          id:'',
          name:''
        },
        aVenueKey:'',
        aId:'',
        aEventCode:'',
        expertValue: [],
        expertKeyArr:[]
      }
    });
  }

  handleTabModalCancel = (e) => {
    console.log(e);
    this.setState({
      tabModalVisible: false,
      tabs:{
        billing:'',
        date:moment(moment().toDate()).format('YYYY-MM-DD'),
        title:'' 
      }
    });
  }


  render(){
    // console.log("venueList",this.state.venueList)
    console.log("tabList",this.state.tabsList)
    const { fetching, selectedData, speakerValue,moderatorValue,
      hostValue,panelistValue,venueList,listAgenda,agenda,modalStatus,color,event,tabsList,
       tabs} = this.state;

    let { sortedAgenda, filteredAgenda,agendaModalVisible,tabModalVisible } = this.state;

    // console.log("Agenda",agenda);

    sortedAgenda = sortedAgenda || {};
    filteredAgenda = filteredAgenda || {};
    const AgendaColumns = [{
      title: 'Date',
      dataIndex: 'dateStart',
      key: 'dateStart',
      sorter: (a, b) =>  { 
       //Only accepted format for sor (yyyy-month-day)
       console.log("sort",a.dateKey)
       
        return moment(a.dateKey || 0).unix() - moment(b.dateKey || 0).unix()
      }  ,
      sortOrder: sortedAgenda.columnKey === 'dateStart' && sortedAgenda.order,
    }, {
      title: 'Time',
      dataIndex: 'time',
      key: 'timeStart',
      sorter: (a, b) => a.unFormatTimeStart - b.unFormatTimeStart,
      sortOrder: sortedAgenda.columnKey === 'timeStart' && sortedAgenda.order,
    }, 
    {
      title: 'Session',
      dataIndex: 'program',
      key: 'program',
      sorter: (a, b) => { return a.program.localeCompare(b.program)},
      sortOrder: sortedAgenda.columnKey === 'program' && sortedAgenda.order,
    },
    {
      title: 'Location',
      dataIndex: 'venue.name',
      key: 'venue.name',
      sorter: (a, b) => { return a.venue.name.localeCompare(b.venue.name)},
      sortOrder: sortedAgenda.columnKey === 'venue.name' && sortedAgenda.order,
    },{
      title: 'Experts',
      key: 'expertNames',
      dataIndex: 'expertNames',
      sorter: (a, b) => a.expertNames.length - b.expertNames.length,
      sortOrder: sortedAgenda.columnKey === 'expertNames' && sortedAgenda.order,
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (text,record) => <div>
      
    {this.state.listAgenda.length>=1 ?(
      <div>
            <Button onClick={(e) => {  e.stopPropagation(); this.showAgendaModalEdit(record)}}> 
              Edit
            </Button>

              <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.handleAgendaDelete(record.id,record.index)}}>
                <Button onClick={(e) => {  e.stopPropagation();}}> 
                  Delete
                </Button  >
                
              </Popconfirm>  
      </div>
  
        ):null
        }        
    </div>
    ,
    },

  ];

    return(
      <Layout>

        {/* Session Modal */}
        <Modal title="Session"
            visible={agendaModalVisible}
            destroyOnClose = {true}
            footer = {[null]}
            onCancel={this.handleAgendaModalCancel}
        >
            
          <AgendaModal
            onChangeHandler = {this.onChangeHandler}
            onChangeEndTime = {this.onChangeEndTime}
            onChangeStartTime = {this.onChangeStartTime}
            onChangeDate = {this.onChangeDate}
            fetchUser = {this.fetchUser}
            handleExpertChange = {this.handleExpertChange}
            onSubmitAgendaHandler = {this.onSubmitAgendaHandler}

            selectedData = {selectedData}
            fetching = {fetching}
            agenda = {agenda}
            expertValue = {agenda.expertValue}
            venueList = {venueList}
            onChangeVenue = {this.onChangeVenue}
            modalStatus = {modalStatus}
            onChangeIsRatable = {this.onChangeIsRatable}
          />

        </Modal>

      {/* Tabs Modal */}
        <Modal title="Tabs"
            visible={tabModalVisible}
            destroyOnClose = {true}
            footer = {[null]}
            onCancel={this.handleTabModalCancel}
        >
            
          <TabsModal
            onChangeTabDate = {this.onChangeTabDate}
            onChangeHandler = {this.onChangeHandler}
            onSubmitTabsHandler = {this.onSubmitTabsHandler}

            tabs = {tabs}
          />

        </Modal>

      <HeaderNav></HeaderNav>
      <Content style={{ padding: '0 10%', marginTop: '150px' ,marginLeft: "auto",  marginRight: "auto"}}>
        <Card
          title="Insert Content"
          style={{ width: '100%' ,  marginLeft: "auto",  marginRight: "auto"}}
        >
          <ContentForm
            showAgendaModalAdd = {this.showAgendaModalAdd}
            showAgendaModalEdit = {this.showAgendaModalEdit}
            onChangeHandler = {this.onChangeHandler}
            onSubmitColorHandler = {this.onSubmitColorHandler}
            fetchUser = {this.fetchUser}
            handleSpeakerChange = {this.handleSpeakerChange}
            handleModeratorChange = {this.handleModeratorChange}
            handlePanelistChange = {this.handlePanelistChange}
            handleHostChange = {this.handleHostChange}
            onChangeEndTime = {this.onChangeEndTime}
            onChangeStartTime = {this.onChangeStartTime}
            onChangeDate = {this.onChangeDate}
            onSubmitAgendaHandler = {this.onSubmitAgendaHandler}
            onChangeVenue = {this.onChangeVenue}
            onSubmitEventDetailsHandler = {this.onSubmitEventDetailsHandler}
            venueFileHandler = {this.venueFileHandler}
            onSubmitVenueHandler = {this.onSubmitVenueHandler}
            handleAgendaTableChange = {this.handleAgendaTableChange}
            handleAgendaClick = {this.handleAgendaClick}
            onChangeEventStartDate = {this.onChangeEventStartDate}
            onChangeEventEndDate = {this.onChangeEventEndDate}
            onChangeEventEndTime = {this.onChangeEventEndTime}
            onChangeEventStartTime = {this.onChangeEventStartTime}
            showTabModalAdd = {this.showTabModalAdd}
            showTabModalEdit = {this.showTabModalEdit}
            handleTabDelete = {this.handleTabDelete}

            fetching = {fetching}
            selectedData = {selectedData}
            speakerValue = {speakerValue}
            moderatorValue = {moderatorValue}
            hostValue = {hostValue}
            panelistValue = {panelistValue}
            venueList = {venueList}
            AgendaColumns = {AgendaColumns}
            listAgenda = {listAgenda}
            color = {color}
            event = {event}
            tabsList = {tabsList}
          />
        </Card>
      </Content>
      <FooterNav></FooterNav>
    </Layout>
    )
  }
}