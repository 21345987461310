import React,{Component} from 'react'
import {Button,Input,Select,Form,Avatar,DatePicker,TimePicker,Popover} from 'antd'
import moment from 'moment'
import { ChromePicker } from 'react-color';

import About_icon from '../../styles/images/About_icon@2x.png'
import About_logo from '../../styles/images/About_logo@2x.png'
import About_banner from '../../styles/images/About_banner@2x.png'
import About_headerbg from '../../styles/images/About_headerbg@2x.png'
import About_Colors from '../../styles/images/About_Colors@2x.png'
import About_Camera from '../../styles/images/About_Camera Frames@2x.png'
import GanApp_logo from '../../styles/images/GanApp_logo@2x.png'
import About_Event from '../../styles/images/About_Event logo@2x.png'

const Option = Select.Option;
const format = 'HH:mm';
const { TextArea } = Input;




//Not Using anymore
export default class aboutForm extends Component{
  constructor(props){
    super(props)
    this.state = {
      selected_id:'tab-event-desc'
    }
  }

  style ={
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px"
  }

  changeID(new_id){
    this.setState({ selected_id: new_id });
  }

  render(){
    // console.log("this",this.props.event)
    let startDate = moment(this.props.event.eventStartDate,'YYYY/MM/DD')._i
    return(

      <div className= "main">
       <div class="top">
        <h1>About the event</h1>
          {/* <div class="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
        </div>
          <div class="content">
            <h1 class="title-fancy">Ready to <span class="emp">customize</span><span class="font-huge">?</span></h1>
            <h2 class="title-fancy-sub">Describe your event, upload banners and brand your event</h2>
            
            <section class="section">
              <input id="tab-event-desc" type="radio" checked={this.state.selected_id === "tab-event-desc"} onChange={()=>this.changeID("tab-event-desc")} class="hide" />
              <input id="tab-banner" type="radio" checked={this.state.selected_id === "tab-banner"} onChange={()=>this.changeID("tab-banner")} class="hide" />
              <input id="tab-header" type="radio" checked={this.state.selected_id === "tab-header"} onChange={()=>this.changeID("tab-header")} class="hide" />
              <input id="tab-event" type="radio" checked={this.state.selected_id === "tab-event"} onChange={()=>this.changeID("tab-event")} class="hide" />
              <input id="tab-camera" type="radio" checked={this.state.selected_id === "tab-camera"} onChange={()=>this.changeID("tab-camera")} class="hide" />
              <input id="tab-colors" type="radio" checked={this.state.selected_id === "tab-colors"} onChange={()=>this.changeID("tab-colors")} class="hide" />
              <div class="tab tab-blue text-align-center">
                <label for="tab-event-desc">Event Description</label>
                <label for="tab-banner">Banner</label>
                <label for="tab-header">Header Background</label>
                <label for="tab-event">Event Logo</label>
                <label for="tab-camera">Camera Frames</label>
                <label for="tab-colors">Colors</label>
              </div>
              <div class="tab-contents">
                <div class="tab-cont tab-event-desc slide-up">
                <Form 
                    className='Login-Form' 
                    layout = "vertical" 
                  >

                      {/* <input type="text" placeholder="Enter event description here" class="fld-large full-width" /> */}
 
                      <div class="fld-item">
                        <label>Name</label>
                        <Input 
                          onChange={this.props.onChangeHandler} 
                          size="default" 
                          name='eventName' 
                          placeholder="Input Name"
                          className="fld-large full-width"
                          value = {this.props.event.eventName}
                        />
                      </div>

                      <div class="fld-item">
                        <label>Description</label>
                        <TextArea 
                          rows={4} 
                          onChange={this.props.onChangeHandler} 
                          name='eventDescription' size="default" 
                          placeholder="Input Description"
                          className="fld-large full-width"
                          value = {this.props.event.eventDescription}
                        />
                      </div>
                      <div class="fld-item fld-item-col-2">
                        <label>Date Start</label>
                        <DatePicker 
                          onChange={this.props.onChangeEventStartDate}
                          value = {moment(startDate,'YYYY/MM/DD')}
                          // defaultValue ={moment(startDate,'YYYY/MM/DD')}
                        />
                      </div>
                      <div class="fld-item fld-item-col-2">
                        <label>Date End</label>
                        <DatePicker 
                          onChange={this.props.onChangeEventEndDate}
                          value = {moment(this.props.event.eventEndDate,'YYYY/MM/DD')}
                          // defaultValue ={moment(startDate,'YYYY/MM/DD')}
                        />
                      </div>
                      <div class="fld-item fld-item-col-2">
                        <label>Time Start</label>
                        <TimePicker 
                          value={moment(this.props.event.eventTimeStart, 'h:mm a')} 
                          // defaultValue=''
                          name='eventTimeStart' onChange={this.props.onChangeEventStartTime} 
                          use12Hours 
                          format="h:mm a" 
                        />
                      </div>
                      <div class="fld-item fld-item-col-2">
                        <label>Time End</label>
                        <TimePicker 
                          value={moment(this.props.event.eventTimeEnd, 'h:mm a')} 
                          // defaultValue=''
                          name='eventTimeEnd' 
                          onChange={this.props.onChangeEventEndTime} 
                          use12Hours 
                          format="h:mm a" 
                        />
                      </div>
                      <div class="fld-item">
                        <label>HashTag</label>
                        <Input 
                          onChange={this.props.onChangeHandler} 
                          size="default" 
                          name='eventHashTag' 
                          placeholder="Input HashTag"
                          className="fld-large full-width"
                          value = {this.props.event.eventHashTag} 
                        />
                      </div>
                      <div class="fld-item">
                        <label>Address</label>
                        <Input 
                          onChange={this.props.onChangeHandler} 
                          size="default" 
                          name='eventAddress' 
                          placeholder="Input Address"
                          className="fld-large full-width"
                          value = {this.props.event.eventAddress}
                        />
                      </div>
                      <div class="fld-item">
                        <label>Venue</label>
                        <Input 
                          onChange={this.props.onChangeHandler} 
                          size="default" 
                          name='eventVenue' 
                          placeholder="Input Venue"
                          className="fld-large full-width"
                          value = {this.props.event.eventVenue}
                        />
                      </div>
                      <div class="fld-item">
                        <label>Coordinates</label>
                        <Input 
                          onChange={this.props.onChangeHandler} 
                          size="default" 
                          name='eventCoordinates' 
                          placeholder="Input Coordinates"
                          className="fld-large full-width"
                          value = {this.props.event.eventCoordinates}
                        />
                      </div>
 
                  
                  <div  className="text-align-right"><input disabled = {this.props.properties.eventEnded} onClick = {(e)=> this.props.onSubmitEventDetailsHandler()}  type="button" value="Done" class="btn btn-orange" /></div>
                </Form>
                </div>
                
                <div class="tab-cont tab-banner slide-up">
                  <div class="dash-blocks text-align-center h-margin-small">
                    <h3 class="text-gray"><i class="material-icons v-align text-gray">add</i> App Banner</h3>
                    <img src={About_banner} width="220" class="v-space-large" />
                    <h4 class="font-bold">Upload Photo</h4>
                    <div class="text-gray v-space-large">Drop your .png or .jpeg files here. Maximum upload file size: 10mb Dimensions should be 1080px : 768px (rectangle)</div>
                    <div>
                      <input id="appBanner" type="file" onChange={(e)=>this.props.eventBannerUploadHandler(e)} class ="hide" />
                      <label for="appBanner" class="btn btn-orange btn-small">Select file</label>
                    </div>
                  </div>
                </div>
                <div class="tab-cont tab-header slide-up">
                  <div class="dash-blocks text-align-center h-margin-small">
                    <h3 class="text-gray"><i class="material-icons v-align text-gray">add</i> Header Background</h3>
                    <img src={About_headerbg} width="220" class="v-space-large" />
                    <h4 class="font-bold">Upload Photo</h4>
                    <div class="text-gray v-space-large">Drop your .png or .jpeg files here. Maximum upload file size: 10mb Dimensions should be 1080px : 540px (rectangle)</div>
                    <div>
                      <input id="headBg" onChange={(e)=>this.props.eventBackgroundUploadHandler(e)} type="file" class="hide" />
                      <label for="headBg" class="btn btn-orange btn-small">Select file</label>
                    </div>
                  </div>
                </div>

                <div class="tab-cont tab-event slide-up">
                  <div class="dash-blocks text-align-center h-margin-small">
                    <h3 class="text-gray"><i class="material-icons v-align text-gray">add</i> Event Logo</h3>
                    <img src={About_Event} width="220" class="v-space-large" />
                    <h4 class="font-bold">Upload Photo</h4>
                    <div class="text-gray v-space-large">Drop your .png or .jpeg files here. Maximum upload file size: 10mb Dimensions should be 800px : 800px (rectangle)</div>
                    <div>
                      <input id="eventLogo" onChange={(e)=>this.props.eventLogoUploadHandler(e)} type="file" class="hide" />
                      <label for="eventLogo" class="btn btn-orange btn-small">Select file</label>
                    </div>
                  </div>
                </div>

                <div class="tab-cont tab-camera slide-up">
                  <div class="space-between align-items-start">
                    <div class="h-space-large v-space-large"><img src={About_Camera} width="200" /></div>
                    <div class="space-between flex-wrap">
                      <div class="half-width h-space-large v-space-large">
                        <h4>Frame 1</h4>
                        <div class="dash-blocks text-align-center">
                          <div class="display-frame"><img src={this.props.filterList[0].port == ''? About_Camera:this.props.filterList[0].port}/></div>
                          <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 10mb (1080 x 1440)</div>
                          <div>
                            <input id="frame1" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF0','port')}  type="file" class="hide" />
                            <label for="frame1" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>
                      <div class="half-width h-space-large v-space-large">
                        <h4>Thumbnail 1</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame-thumbnail"><img src={ this.props.filterList[0].thumb == ''? GanApp_logo:this.props.filterList[0].thumb} /></div>
                        <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 5mb (320 x 320)</div>
                          <div>
                            <input id="thumb1" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF0','thumb')} type="file" class="hide" />
                            <label for="thumb1" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>

                      <div class="half-width h-space-large v-space-large">
                        <h4>Frame 2</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame"><img src={this.props.filterList[1].port == ''? About_Camera:this.props.filterList[1].port}/></div>
                          <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 10mb (1080 x 1440)</div>
                          <div>
                            <input id="frame2" type="file" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF1','port')} class="hide" />
                            <label for="frame2" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>
                      <div class="half-width h-space-large v-space-large">
                        <h4>Thumbnail 2</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame-thumbnail"><img src={ this.props.filterList[1].thumb == ''? GanApp_logo:this.props.filterList[1].thumb} /></div>
                        <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 5mb (320 x 320)</div>
                          <div>
                            <input id="thumb2" type="file" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF1','thumb')}  class="hide" />
                            <label for="thumb2" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>
                      <div class="half-width h-space-large v-space-large">
                        <h4>Frame 3</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame"><img src={this.props.filterList[2].port == ''? About_Camera:this.props.filterList[2].port}/></div>
                          <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 10mb (1080 x 1440)</div>
                          <div>
                            <input id="frame3" type="file" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF2','port')}  class="hide" />
                            <label for="frame3" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>
                      <div class="half-width h-space-large v-space-large">
                        <h4>Thumbnail 3</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame-thumbnail"><img src={ this.props.filterList[2].thumb == ''? GanApp_logo:this.props.filterList[2].thumb} /></div>
                        <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 5mb (320 x 320)</div>
                          <div>
                            <input id="thumb3" type="file" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF2','thumb')} class="hide" />
                            <label for="thumb3" class="btn btn-orange btn-small" >Select file</label>
                          </div>
                        </div>
                      </div>

                      <div class="half-width h-space-large v-space-large">
                        <h4>Frame 4</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame"><img src={this.props.filterList[3].port == ''? About_Camera:this.props.filterList[3].port}/></div>
                          <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size:10mb (1080 x 1440)</div>
                          <div>
                            <input id="frame4" type="file" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF3','port')} class="hide" />
                            <label for="frame4" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>
                      <div class="half-width h-space-large v-space-large">
                        <h4>Thumbnail 4</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame-thumbnail"><img src={ this.props.filterList[3].thumb == ''? GanApp_logo:this.props.filterList[3].thumb} /></div>
                        <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 5mb (320 x 320)</div>
                          <div>
                            <input id="thumb4" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF3','thumb')} type="file" class="hide" />
                            <label for="thumb4" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>

                      <div class="half-width h-space-large v-space-large">
                        <h4>Frame 5</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame"><img src={ this.props.filterList[4].port == ''? About_Camera:this.props.filterList[4].port}/></div>
                          <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 10mb (1080 x 1440)</div>
                          <div>
                            <input id="frame5" type="file" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF4','port')} class="hide" />
                            <label for="frame5" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>
                      <div class="half-width h-space-large v-space-large">
                        <h4>Thumbnail 5</h4>
                        <div class="dash-blocks text-align-center">
                        <div class="display-frame-thumbnail"><img src={this.props.filterList[4].thumb == ''? GanApp_logo:this.props.filterList[4].thumb} /></div>
                        <div class="text-gray v-space-med font-small">Drop your .png or .jpeg files here. Maximum upload file size: 5mb (320 x 320)</div>
                          <div>
                            <input id="thumb5" type="file" onChange={(e)=>this.props.eventUploadPortFilter(e,'camF4','thumb')}  class="hide" />
                            <label for="thumb5" class="btn btn-orange btn-small">Select file</label>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="tab-cont tab-colors slide-up">
                  <div class="space-between">
                    <div><img src={About_Colors} width="550" /></div>
                    <div>
                      <div class="color-picker-item">
                        <span class="circle-item">1</span>
                        <a href="#" class="btn-gray-dash">
                        <Popover
                            content={
                              <ChromePicker
                                color = {this.props.color.primary}
                                onChangeComplete={ this.props.handleChangePrimaryLabel }
                              />
                            }
                              trigger="click"
                              placement="left"
                            >
                          <span class="color-picker-box" style={{background:this.props.color.primary}} ></span>
                          <span class="h-margin-med text-gray">Primary Color</span>
                        </Popover>
                        </a>
                      </div>
                      <div class="color-picker-item">
                        <span class="circle-item">2</span>
                        <a href="javascript:void(0)" class="btn-gray-dash">
                          <Popover
                            content={
                              <ChromePicker
                                color = {this.props.color.secondary}
                                onChangeComplete={ this.props.handleChangeSecondaryLabel }
                              />
                            }
                              trigger="click"
                              placement="left"
                            >
                              <span class="color-picker-box" style={{background:this.props.color.secondary}}></span>
                              <span class="h-margin-med text-gray">Secondary Color (Android)</span>
                            </Popover>
                          
                        </a>
                      </div>


                      <div class="color-picker-item">
                        <span class="circle-item">3</span>

                        <a href="javascript:void(0)" class="btn-gray-dash">
                          <Popover
                            content={
                              <ChromePicker
                                color = {this.props.color.headerTitle}
                                onChangeComplete={ this.props.handleChangeHeaderTitleLabel }
                              />
                          }
                            trigger="click"
                            placement="left"
                          >
                            
                            <span class="color-picker-box" style={{background:this.props.color.headerTitle}}></span>
                            <span class="h-margin-med text-gray">Header Text Color</span>
                          
                          </Popover>
                        </a>
                      </div>


                      <div class="color-picker-item">
                        <span class="circle-item">4</span>
                        <a href="#" class="btn-gray-dash">
                        <Popover
                            content={
                              <ChromePicker
                                color = {this.props.color.headerLabel}
                                onChangeComplete={ this.props.handleChangeHeaderLabel }
                              />
                          }
                            trigger="click"
                            placement="left"
                          >
                            <span class="color-picker-box" style={{background:this.props.color.headerLabel}}></span>
                            <span class="h-margin-med text-gray">Label Text Color</span>
                          </Popover>
                        </a>
                      </div>
                      <div class="color-picker-item">
                        <span class="circle-item">5</span>
                        <a href="#" class="btn-gray-dash">
                        <Popover
                            content={
                              <ChromePicker
                                color = {this.props.color.onGoing}
                                onChangeComplete={ this.props.handleChangeOnGoingLabel }
                              />
                          }
                            trigger="click"
                            placement="left"
                          >
                            <span class="color-picker-box" style={{background:this.props.color.onGoing}}></span>
                            <span class="h-margin-med text-gray">Ongoing Background Color</span>
                          </Popover>

                        </a>
                        {/* <div  className="text-align-right"><input disabled = {this.props.properties.eventEnded} type="button" value="Done" onClick = {(e)=> this.props.onSubmitColorHandler()} class="btn btn-orange" /></div> */}
                        <div className="v-space-med h-space-small text-align-right"><input type="button" disabled = {this.props.properties.eventEnded} class="btn btn-orange" onClick = {(e)=> this.props.onSubmitColorHandler()} value="Save" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
      </div>  
    )
  }
}