import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import {authFirebase} from '../../config'
import {notification} from 'antd'
export default class Time extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
  }

  _onAction = (e) =>{
    // console.log('user did something', e)
  }
 
  _onActive = (e) => {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  _onIdle = (e) => {
    console.log('user is idle', e)
    console.log('last active', this.idleTimer.getLastActiveTime())
    authFirebase.signOut().then(function() {
      // Sign-out successful.
      sessionStorage.removeItem('logged-in');
      sessionStorage.removeItem('user-session');
      sessionStorage.removeItem('active-event-code');
      const args = {
        message: 'Session Expired',
        description: 'You'+"'"+'ve been idle for too long. Please Login again.',
        duration: 0,
      };
      notification.open(args);
      // openNotif("success","Succesfully Logged Out!","")
    }).catch(function(error) {
      // An error happened.
    });
  }
 
  render() {
    return (
      <div>
        {/* <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this._onActive}
          onIdle={this._onIdle}
          onAction={this._onAction}
          debounce={250}
          timeout={1000*60*15} /> */}
        {/* your app here */}
      </div>
    )
  }
 

}