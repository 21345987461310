import React,{Component} from 'react'
import { TimePicker,Input,DatePicker,Select,Spin,Form,Button,Switch } from 'antd'
import moment from 'moment'

const Option = Select.Option;
const { TextArea } = Input;
export default class agendaModal extends Component{
  constructor(props){
    super(props)
  }
  
render(){
  return(
    
    <Form 
      className='Login-Form' 
      onSubmit= {(e)=> this.props.onSubmitAgendaHandler(e,this.props)} 
      layout = "vertical" 
      style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}
      >
      <div className="fld-item">
        <label>Program/Session Title:</label>
        <Input 
          className="fld"
          onChange={this.props.onChangeHandler} 
          size="default" 
          name='aProgramTitle' 
          placeholder="Input Program/Session Title" 
          defaultValue = {this.props.agenda.aProgramTitle}
        />
      </div>
      
      <div className="fld-item">
        <label>Description:</label>
        <TextArea 
          className="fld"
          onChange={this.props.onChangeHandler} 
          size="default" 
          name='aDescription' 
          placeholder="Input Description" 
          defaultValue = {this.props.agenda.aDescription}
        />
      </div>

      <div className="fld-item fld-item-col-2">
        <label>Venue:</label>
        <Select
          className="fld"
          showSearch
          style={{ width: '100%' }}
          placeholder="Select a Venue"
          optionFilterProp="children"
          onChange={this.props.onChangeVenue}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          defaultValue={this.props.agenda.aVenueKey}
        >

        {this.props.venueList.map(venue =>
          <Option key={venue.id}> {venue.name} </Option>
        )
        
        }
      </Select>
      </div>

      <div className="fld-item fld-item-col-2">
        <label>Date:</label>
          <DatePicker 
            className="fld"
            onChange={this.props.onChangeDate}
            defaultValue = {moment(this.props.agenda.aDate,'YYYY/MM/DD')}
          />
      </div>
      
      <div className="fld-item fld-item-col-2">
        <label>Time Start:</label>
          <TimePicker 
            className="fld"
            defaultValue={moment(this.props.agenda.aTimeStart, 'h:mm a')} 
            name='aTimeStart' onChange={this.props.onChangeStartTime} 
            use12Hours 
            format="h:mm a" 
          />
      </div>
        
      <div className="fld-item fld-item-col-2">
        <label>Time End:</label>
        <TimePicker 
        className="fld"
          defaultValue={moment(this.props.agenda.aTimeEnd, 'h:mm a')} 
          name='aTimeEnd' 
          onChange={this.props.onChangeEndTime} 
          use12Hours 
          format="h:mm a" 
        />
      </div>

      <div className="fld-item">
        <label>Experts:</label>
        <Select
          className="fld"
          mode="tags"
          labelInValue
          value={this.props.expertValue}
          placeholder="Select Panelist"
          notFoundContent={this.props.fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.props.fetchUser}
          onChange={this.props.handleExpertChange}
          style={{ width: '90%' }}
          size ='default'
          name='aExpertSelect' 
        >
        
        { this.props.selectedData.map(d => <Option key={d.id}>
        
          {/* <Avatar src={d.imageLink} size="small"/>{"   "} */}
          {d.name}
          </Option>)}
        </Select>
        {'  '} {this.props.fetching ? <Spin size="small" /> : null}
      </div>

      <div className="fld-item fld-item-col-2">
        <label>Session Type:</label>
        <Select
          className="fld"
          showSearch
          style={{ width: '100%' }}
          placeholder="Select a Venue"
          optionFilterProp="children"
          onChange={this.props.onChangeSessionType}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          defaultValue={this.props.agenda.aSessionType}
        >
          <Option value="1">Regular</Option>
          <Option value="2">With Poll</Option>
          <Option value="4">No details</Option>
      </Select>
      </div>

      <div className="fld-item" style={{display: this.props.agenda.aSessionType=='2'? 'block':'none'}}>
        <label>Poll:</label>
        <Select
          className="fld"
          showSearch
          style={{ width: '100%' }}
          placeholder="Select a Poll"
          optionFilterProp="children"
          onChange={this.props.onChangePollUrl}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          defaultValue={this.props.agenda.pollUrl}
          // dropdownStyle= {{maxHeight:'40px'}}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {this.props.pollList.map(poll =>
            <Option key={poll.id}> {poll.question} </Option>
          )
          
          }
      </Select>
      </div>
    
        <div className="fld-item">
        <Switch defaultChecked = {this.props.agenda.aIsRatable} onChange={this.props.onChangeIsRatable} /><span>   is Ratable?</span>
        </div>
      
      <div className="fld-item">
      <Button disabled={this.props.eventEnded} type="primary" htmlType='submit' block>Save</Button>
      </div>
      
    </Form>

    
  )
}

}