import React,{Component} from 'react'
import {List,Input,Form} from 'antd'

export default class urlFrom extends Component{
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className= "main">
        <div class="top">
          <h1>Form Urls</h1>
        </div>
        <div class="content">
          <h1 class="title-fancy">Ready to <span class="emp">customize</span><span class="font-huge">?</span></h1>
          <h2 class="title-fancy-sub">Provide the form URLs</h2>

          <section className="section">
            
            
            {this.props.role=='organizer'? 

            <div style={{margin:'auto',width:'90%'}}>
             <List
              size="small"
              header={<div style={{fontSize:'20px',fontWeight:'bold'}}>Link List</div>}
              // footer={<div>Footer</div>}
              bordered
              dataSource={this.props.arrayUrl}
              renderItem={item => (<List.Item>{item}</List.Item>)}
            />
            </div>

            
            :
            ''
            
            }
            {this.props.role=='admin'? 
            
            <div class="text-align-center h-margin-small" style ={{margin:'auto',width:"50%"}}>
            <Form 
              className='Login-Form' 
              layout = "vertical"
            >
              <div class="fld-item">
                <label className="text-align-left">Feedback Url</label>
                <Input 
                  onChange={this.props.onChangeHandler} 
                  size="default" 
                  name='feedbackUrl' 
                  placeholder="Input Feedback Url"
                  className="fld-large full-width"
                  style = {{width:"100%", display:'inline-block',margin: "auto"}}
                  
                  value = {this.props.formUrls.feedbackUrl} 
                />
              </div>
              <div class="fld-item">
                <label className="text-align-left">Form Material Url</label>
                <Input 
                  onChange={this.props.onChangeHandler} 
                  size="default" 
                  name='formMaterialUrl' 
                  placeholder="Input Form Material Url"
                  className="fld-large full-width"
                  // style = {{width:"100%", display:'inline-block'}}
                  value = {this.props.formUrls.formMaterialUrl} 
                />
              </div>
              <div class="fld-item">
                <label className="text-align-left">Form My Schedule Url</label>
                <Input 
                  onChange={this.props.onChangeHandler} 
                  size="default" 
                  name='formMyScheduleUrl' 
                  placeholder="Input Form My Schedule Url"
                  className="fld-large full-width"
                  // style = {{width:"100%", display:'inline-block'}}
                  value = {this.props.formUrls.formMyScheduleUrl} 
                />
              </div>
              <div class="fld-item">
                <label className="text-align-left">Form Session Url</label>
                <Input 
                  onChange={this.props.onChangeHandler} 
                  size="default" 
                  name='formSessionUrl' 
                  placeholder="Input Form Session Url"
                  className="fld-large full-width"
                  // style = {{width:"100%", display:'inline-block'}}
                  value = {this.props.formUrls.formSessionUrl} 
                />
              </div>
              <div class="fld-item">
                <label className="text-align-left">Form Speaker Url</label>
                <Input 
                  onChange={this.props.onChangeHandler} 
                  size="default" 
                  name='formSpeakerUrl' 
                  placeholder="Input Form Speaker Url"
                  className="fld-large full-width"
                  // style = {{width:"100%", display:'inline-block'}}
                  value = {this.props.formUrls.formSpeakerUrl} 
                />
              </div>

              <div class="fld-item" style={{display:'none'}}>
                <label className="text-align-left">Notif Sheet Key</label>
                <Input 
                  onChange={this.props.onChangeHandler} 
                  size="default" 
                  name='notifSheetKey' 
                  placeholder="Input Notif Sheet Key"
                  className="fld-large full-width"
                  // style = {{width:"100%", display:'inline-block'}}
                  value = {this.props.formUrls.notifSheetKey} 
                />
              </div>

              
              <div  className="text-align-right"><input onClick = {(e)=> this.props.onSubmitHandler()}  type="button" value="Done" class="btn btn-orange" /></div>
            </Form>
            </div>
            
            :

            ''
            }

            
            </section>
          <div class="tab-contents" style = {{margin: "auto"}}>
         
          </div>
          
        </div>
      </div>
    )
   }
}