import React, {Component} from 'react'
import { Form, Input, Button } from 'antd';
// import login from '../../styles/login.css'
import dl_AppStorex from '../../styles/images/dl_AppStore@2x.png'
import dl_GooglePlay from '../../styles/images/dl_GooglePlay@2x.png'
import yt_logo from '../../styles/images/yt_logo.png'

class loginForm extends Component{
  constructor(props){
    super(props)
  }


  render(){
    const { getFieldDecorator } = this.props.form;
    return(




      // <div id="wrap">
      // <link href="https://fonts.googleapis.com/css?family=Lato|Roboto" rel="stylesheet"/>
      // <link rel="icon" href={favicon}/>
      // <div id="content">
      //   <div id="login-cont">
      //     <div class="eesy-logo"></div>


      //     <Form id="login-form" className='Login-Form' onSubmit= {(e)=> this.props.onSubmitHandler(e,this.props)}>
      //         <FormItem
      //         >
                
      //           {getFieldDecorator('userName', {
      //               rules: [{
      //                 type: 'email', message: 'The input is not valid E-mail!',
      //           },{ required: true, message: 'Please input your username!' },
      //           {
      //           type: "regexp",
      //             pattern: new RegExp("^[0-9]*$"),
      //             message: "Wrong format!"
      //           }
      //         ],
      //         })(
      //           <div className="fld fld-text"><Input onChange={this.props.onChangeHandler} name='uUsername'className="fld fld-text" placeholder="event code/username/email" /></div>
                
      //           )}
      //         </FormItem>
      //         <FormItem
            
      //         >
      //           {getFieldDecorator('password', {
      //               rules: [{ required: true, message: 'Please input your password!' }],
      //           })( 
      //             <div className="fld fld-text"><Input type="password" onChange={this.props.onChangeHandler} name='uUpassword' className="fld fld-text" placeholder="password"/></div>
              
      //         )}
      //         </FormItem>
      //         <div class="margin-bottom10">
      //         <Button overrides={false} className="btn btn-orange" htmlType='submit'>Login</Button>
      //         <input type="submit" className="btn btn-blue" value="signup" />
      //         </div>
      //       </Form>

      //     <div class="ganapp-logo"><img src={GanApp_logo} /></div>
      //     <div>
      //       <span>Please download GanApp to view your event real-time</span>
      //       <div class="dl-btns">
      //         <a href="#"><img src={dl_AppStorex} /></a>
      //         <a href="#"><img src={dl_GooglePlay} /></a>
      //       </div>
      //     </div>
      //   </div>
      // </div>

      // </div>





<div id="wrap">

<div id="login-area">
	<div id="login-cont">
		<div class="eesy-logo"></div>
    <Form id="login-form" className='Login-Form' onSubmit= {(e)=> this.props.onSubmitHandler(e,this.props)}>
       {/* <FormItem
       >
        
        {getFieldDecorator('userName', {
            rules: [{
              type: 'email', message: 'The input is not valid E-mail!',
        },{ required: true, message: 'Please input your username!' },
        {
        type: "regexp",
          pattern: new RegExp("^[0-9]*$"),
          message: "Wrong format!"
        }
      ],
      })( */}
        <div className="fld fld-text"><Input type='email' required onChange={this.props.onChangeHandler} name='uUsername' placeholder="email" /></div>
        
        {/* )} */}
      {/* </FormItem> */}
      {/* <FormItem
    
      >
        {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password!' }],
        })(  */}
          <div className="fld fld-text"><Input type="password" required onChange={this.props.onChangeHandler} name='uUpassword' placeholder="password"/></div>
{/*       
      )}
      </FormItem> */}
      <div className="fld">
        <input type="submit" className="btn btn-orange" value="Login" />
        <input type="submit" className="btn btn-blue" value="signup" />
 			</div>
		</Form>
		<div class="ganapp-logo"></div>
		<div>
			<span>Please download GanApp to view your event real-time</span>
			<div class="dl-btns" style={{marginBottom:'10px'}}>
				<a target='_blank' href="https://itunes.apple.com/ph/app/ganapp/id1230899383?mt=8"><img src={dl_AppStorex} /></a>
				<a target='_blank' href="https://play.google.com/store/apps/details?id=com.pointwest.eesy"><img src={dl_GooglePlay} /></a>
			</div>
			<span >To know more about EESY</span>
			<div class="dl-btns">
        <a target='_blank' href="https://www.youtube.com/watch?v=DcciiMhvEuM&list=PL3FwghXxdAL_lj3Uw8FnpoGoPy4jBKKTX&index=2&t=0s"><img style={{width:"169px",height:"40px"}} src={yt_logo} /></a>
      </div>
     
		</div>
	</div>
</div>
</div>



//  <div id="wrap">
// <link rel="stylesheet" type="text/css" href="../../styles/login.css"/>
// <div id="login-area">
// 	<div id="login-cont">
// 		<div className="eesy-logo"></div>
//     <Form id="login-form" className='Login-Form' onSubmit= {(e)=> this.props.onSubmitHandler(e,this.props)}>
//       <FormItem
//       >
        
//         {getFieldDecorator('userName', {
//             rules: [{
//               type: 'email', message: 'The input is not valid E-mail!',
//         },{ required: true, message: 'Please input your username!' },
//         {
//         type: "regexp",
//           pattern: new RegExp("^[0-9]*$"),
//           message: "Wrong format!"
//         }
//       ],
//       })(
//         <div className="fld fld-text"><Input onChange={this.props.onChangeHandler} name='uUsername' placeholder="event code/username/email" /></div>
        
//         )}
//       </FormItem>
//       <FormItem
    
//       >
//         {getFieldDecorator('password', {
//             rules: [{ required: true, message: 'Please input your password!' }],
//         })( 
//           <div className="fld fld-text"><Input type="password" onChange={this.props.onChangeHandler} name='uUpassword' placeholder="password"/></div>
      
//       )}
//       </FormItem>

//       <div className="fld">
//         <input type="submit" className="btns btn-orange" value="Login" />
//         <input type="submit" className="btns btn-blue" value="signup" />
// 			</div>
// 		</Form>
// 		<div className="ganapp-logo"></div>
// 		<div>
// 			<span>Please download GanApp to view your event real-time</span>
// 			<div className="dl-btn">
// 				<a href="#"><img src={dl_AppStorex} /></a>
// 				<a href="#"><img src={dl_GooglePlay} /></a>
// 			</div>
// 		</div>
// 	</div>
// </div>

// </div> 



    //   <div style ={{width:"100%",marginLeft: "auto",  marginRight: "auto",minWidth:"100px"}}>
      // <Form className='Login-Form' onSubmit= {(e)=> this.props.onSubmitHandler(e,this.props)} layout = "vertical" style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}>
      //   <FormItem
      //   >
          
      //     {getFieldDecorator('userName', {
      //         rules: [{
      //           type: 'email', message: 'The input is not valid E-mail!',
      //     },{ required: true, message: 'Please input your username!' },
      //     {
      //     type: "regexp",
      //       pattern: new RegExp("^[0-9]*$"),
      //       message: "Wrong format!"
      //     }
      //   ],
      //   })(
      //     <Input addonBefore = "Username:" onChange={this.props.onChangeHandler} size="large" name='uUsername' placeholder="Input Username" />
      //     )}
      //   </FormItem>
      //   <FormItem
      
      //   >
      //     {getFieldDecorator('password', {
      //         rules: [{ required: true, message: 'Please input your password!' }],
      //     })(          
      //   <Input addonBefore = "Password:" type="password" onChange={this.props.onChangeHandler} size="large" name='uUpassword' placeholder="Input Password" />
      //    )}
      //   </FormItem>
      //   <Button  type="primary" htmlType='submit' block>Submit</Button>
      // </Form>
    // </div>
    )
  }
}

export default Form.create()(loginForm)

