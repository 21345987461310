import React,{Component} from 'react'
import { Layout} from 'antd';
import 'antd/dist/antd.css';
const { Footer } = Layout;

export default class Footers extends Component{
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div>
        <Footer style={{ textAlign: 'center', marginTop:'150px'}}>
          Ant Design ©2018 Test Footer
        </Footer>
      </div>
    )
  }
}