import React, { Component } from 'react';
import BarChart from './Form/Chart';
import HeaderNav from '../Layout/Header';
import SideMenu from '../Layout/SideMenu';
import { Layout, Button, Row, Col, Dropdown, Menu, Icon } from 'antd';
import { dbFirebase } from '../../config';
import axios from 'axios';

import noChart from '../../styles/images/chart_icon.png'
import refImg from '../../styles/images/refresh-3-240.png'

const { Content } = Layout;
const refreshRate = ["Manually", 3, 15, 30, 60];

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qList: "",
      index: 0,
      value: [{
      }],
      name: '',
      count: 0,
      questions: [],
      data: {},
      poll: true,
      event: "",
      refRate: "Refresh every...",
      sec: 0,
    }
  }

  componentDidMount = () => {
    let activeEvent = sessionStorage.getItem('active-event-code')
    let apiLink = this.props.payload.apiLink
    // console.log("event", activeEvent)
    // console.log("link", apiLink)

    this.setState({
      event: activeEvent,
    })

    axios.get(apiLink + '/poll/getPoll/' + activeEvent)
      .then((response) => {
        // console.log("poll", response.data.result);

        if (response.data.result !== "No Poll Data!") {
          this.setState({
            data: response.data.result,
            questions: Object.keys(response.data.result),
            poll: false,
          })
          // console.log("q", this.state.data)

          if (this.state.name === "") {
            this.countResponse(this.state.questions[0], 0)
          }
          else {
            this.countResponse(this.state.qList, this.state.index)
          }
        }
        else {
          this.setState({
            questions: [],
            data: {},
            poll: true,
          })
        }
      })
  }

  refresh = () => {
    let activeEvent = sessionStorage.getItem('active-event-code');
    let apiLink = this.props.payload.apiLink
    // console.log("event", activeEvent)
    // console.log("link", apiLink)

    axios.get(apiLink + '/poll/getPoll/' + activeEvent)
      .then((response) => {
        // console.log("poll", response.data.result);
        var keys = Object.keys(response.data.result)

        if (activeEvent !== this.state.event) {
          this.setState({
            qList: keys[0],
            event: activeEvent,
            refRate: "Refresh every...",
          })
          this.stopInterval();
        }

        if (response.data.result !== "No Poll Data!") {
          this.setState({
            data: response.data.result,
            questions: keys,
            poll: false,
          })
          // console.log("PollData exist", this.state.data, this.state.qList)

          if (this.state.name === "") {
            // console.log("state name not exists")
            this.countResponse(this.state.qList, 0)
          }
          else {
            // console.log("state name exists", this.state.name, this.state.qList, this.state.index)
            this.countResponse(this.state.qList, this.state.index)
          }
        }
        else {
          // console.log("PollData not exists")
          this.setState({
            questions: [],
            data: {},
            poll: true,
          })
        }
      })
  }

  countResponse = (qList, index) => {

    // console.log("countResponse", qList, index)
    // console.log("options", this.state.data, this.state.data[qList])

    let options = Object.keys(this.state.data[qList].options);
    //console.log("index", qList, "opt", options);

    var response = [];
    options.forEach(element => {
      response.push({
        name: this.state.data[qList].options[element].value,
        count: this.state.data[qList].options[element].responseCount
      })
    });
    // console.log("response", response)
    this.setState({
      qList: qList,
      index: index,
      value: response,
      name: this.state.data[qList].question,
    })
  }

  setRefreshRate = (time) => {
    // console.log("time", time);
    if (time === "Manually") {
      this.setState({
        refRate: "Manually",
      })
      this.stopInterval();
    }

    else {
      this.stopInterval();
      this.setState({
        refRate: time + " secs",
      })
      this.interval = setInterval(() => {
        this.setState({
          sec: 1 + this.state.sec
        })
        // console.log("appapp", this.state.sec)
        this.responseInterval(time);
      }, 1000);
    }
  }

  responseInterval = (time) => {
    // console.log("responseInterval")
    if (this.state.sec === time) {
      this.setState({
        sec: 0
      })
      this.refresh();
    }
  }

  stopInterval = () => {
    // console.log("stopInterval")
    this.setState({
      sec: 0
    })
    clearInterval(this.interval);
  }

  render() {
    const isDataNull = this.state.poll;

    return (
      <div id="area">
        <HeaderNav
          payload={this.props.payload}
        />
        <SideMenu payload={this.props.payload}/>
        <Content style={{ margin: '84px 0px 0px 13px', padding: '0 5px', minHeight: 705 }}>
          {isDataNull ? (
            <div style={{ background: '#fff', paddingLeft: 75, paddingTop: 50, minHeight: 700 }}>
              <Row type="flex" justify="end" >
                <a onClick={(e) => this.refresh()}>
                  <img src={refImg} style={{ width: 30, height: 30, marginRight: 40, marginBottom: 100, marginTop: 5 }} />
                </a>
              </Row>

              <Row type="flex" justify="center" >
                <img src={noChart} style={{ opacity: 0.25, width: 300, height: 300 }} />
              </Row>

              <Row type="flex" justify="center" style={{ fontSize: 25, fontWeight: 900, color: "#c0c0c0" }}>
                NO POLL DATA
                            </Row>
            </div>) : (
              <div style={{ background: '#fff', paddingLeft: 75, paddingTop: 50, minHeight: 700 }}>
                <Row type="flex" justify="end" marginBottom="60px">
                  {/* {this.state.questions.map((qList, index) => (
                                        <Button size="large" style={{ marginBottom: 20, marginRight: 10, color: '#000000' }}
                                            onClick={(e) => this.countResponse(qList, index)}
                                        >Question{index + 1}</Button>
                                    ))} */}

                  <Dropdown overlay=
                    {<Menu>
                      {this.state.questions.map((qList, index) => (
                        <Menu.Item style={{ marginLeft: 10, fontSize: 16, color: '#000714' }}
                          onClick={(e) => this.countResponse(qList, index)}>
                          Question{index + 1}
                        </Menu.Item>
                      ))}
                    </Menu>}>
                    <Button size="large" style={{ marginRight: 10, color: '#000000' }}>
                      Questions<Icon type="down" />
                    </Button>
                  </Dropdown>

                  <Dropdown overlay=
                    {<Menu>
                      {refreshRate.map((time, index) => (
                        <Menu.Item style={{ marginLeft: 10, fontSize: 16, color: '#000714' }}
                          onClick={(e) => this.setRefreshRate(time, index)}>
                          {time === "Manually" ? (time) : (time) + " secs"}
                        </Menu.Item>
                      ))}
                    </Menu>}>
                    <Button size="large" style={{ marginRight: 10, color: '#000000' }}>
                      {this.state.refRate}<Icon type="down" />
                    </Button>
                  </Dropdown>

                  <a onClick={(e) => this.refresh()}>
                    <img src={refImg} style={{ width: 30, height: 30, marginRight: 40, marginTop: 5 }} />
                  </a>

                </Row>

                <BarChart
                  prtcpntValue={this.state.value}
                  name={this.state.name}
                />
              </div>
            )}
        </Content>
      </div>
    )
  }

}

export default (Dashboard);