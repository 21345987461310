import React,{Component} from 'react'
import {Button,Card} from 'antd'


export default class assetsForm extends Component{
  constructor(props){
    super(props)
  }
  
  render(){
    return(
      <div>

        <h3>EVENT LOGO</h3>
          <input type = "file" onChange={(e)=>this.props.eventLogoHandler(e)} />
          <br></br>
          <button onClick={this.props.eventLogoUploadHandler}>Upload</button>
          <br></br>
          <br></br>

        <h3>EVENT BANNER</h3>
          <input type = "file" onChange={(e)=>this.props.eventBannerHandler(e)} />
          <br></br>
          <button onClick={this.props.eventBannerUploadHandler}>Upload</button>
          <br></br>
          <br></br>

          <h3>BACKGROUND IMAGE</h3>
          <input type = "file" onChange={(e)=>this.props.eventBackgroundHandler(e)} />
          <br></br>
          <button onClick={this.props.eventBackgroundUploadHandler}>Upload</button>
          <br></br>
          <br></br>

          {/* <h3>VENUE IMAGE</h3>
          <input type = "file" onChange={(e)=>this.props.eventVenueHandler(e)} />
          <br></br>
          <button onClick={this.props.eventVenueUploadHandler}>Upload</button>
          <br></br>
          <br></br> */}
{/* 
          <h3>PARTNER IMAGE</h3>
          <input type = "file" onChange={(e)=>this.props.eventPartnerHandler(e)} />
          <br></br>
          <button onClick={this.props.eventPartnerUploadHandler}>Upload</button>
          <br></br>
          <br></br>

          <h3>EXHIBITOR IMAGE</h3>
          <input type = "file" onChange={(e)=>this.props.eventExhibitorHandler(e)} />
          <br></br>
          <button onClick={this.props.eventExhibitorUploadHandler}>Upload</button>
          <br></br>
          <br></br>

          <h3>SPEAKER IMAGE</h3>
          <input type = "file" onChange={(e)=>this.props.eventSpeakerHandler(e)} />
          <br></br>
          <button onClick={this.props.eventSpeakerUploadHandler}>Upload</button>
          <br></br>
          <br></br> */}

          {/* <h3>CAMERA FILTER PORT</h3>
          <input type = "file" onChange={(e)=>this.props.eventFilterPortHandler(e)} />
          <br></br>
          <button onClick={this.props.eventFilterPortUploadHandler}>Upload</button>
          <br></br>
          <br></br>

          <h3>CAMERA FILTER THUMB</h3>
          <input type = "file" onChange={(e)=>this.props.eventFilterThumbHandler(e)} />
          <br></br>
          <button onClick={this.props.eventFilterThumbUploadHandler}>Upload</button>
          <br></br>
          <br></br> */}


          <br></br>
        <h4>Camera Filters:</h4>
        <table style = {{fontFamily:'arial', borderCollapse:'collapse',width:'100%'}}>
          <tbody>
            {this.props.filterList.map((item,index)=>(
              <tr key={index}>
                <td>
                <Card
                  hoverable
                  style={{ width: 240 }}
                  cover={<img alt="example" src={item.port} />}
                >
                </Card>
                <span>Portrait:
                <input type = "file" onChange={(e)=>this.props.eventFilterPortListHandler(e,item.id)} />
                </span>
                <span>Thumbnail:
                <input type = "file" onChange={(e)=>this.props.eventFilterThumbListHandler(e,item.id)} />
                </span>
                {/* <button onClick={this.props.eventFilterPortUploadHandler}>Upload</button> */}
                <Button onClick = {(e)=> this.props.eventUploadEditFilter(item.id)}>
                    Save
                </Button>
                <Button onClick = {(e)=> this.props.eventDeleteFilter(item.id)}>
                    Delete
                </Button>
                <br></br>
                </td>
                
              </tr>
              ))}
          </tbody>
        </table>
        <br></br>
        <br></br>
        <h5>Upload new Camera Filter:</h5>

        <span>Portrait:
        <input type = "file" onChange={(e)=>this.props.eventFilterPortDataHandler(e)} />
        </span>
        <span>Thumbnail:
        <input type = "file" onChange={(e)=>this.props.eventFilterThumbDataHandler(e)} />
        </span>
        {/* <button onClick={this.props.eventFilterPortUploadHandler}>Upload</button> */}
        <Button onClick = {(e)=> this.props.eventUploadNewFilter()}>
            Save
        </Button>


      </div>
    )
  }
}