import React,{Component} from 'react';
import ExhibitorForm from './Exhibitor/exhibitorForm'
import { Layout,Card,Button,Popconfirm,Modal,Avatar} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import {openNotif} from '../utils'
import ExhibitorModal from './Exhibitor/exhibitorModal'
import SideMenu from './Layout/SideMenu'
import Timer from './Utils/Time'
import _ from 'lodash'
import axios from 'axios'
import {Redirect} from 'react-router-dom';

const {Content} = Layout;

export default class Exhibitor extends Component{
  constructor(props){
    super(props)
    this.state ={

      exhData:{
        exhBilling:'',
        exhCategory:'',
        exhCategoryLabel:'',
        exhId:'',
        exhLogoUrl:'',
        exhName:'',
        exhWebUrl:'',
        exhDescription:'',
        eventExhFile:null,
        submitStatus:'',
        eventCode:'',
        location:''
      },

      exhList:[{
        billing:'',
        category:'',
        categoryLabel:'',
        id:'',
        logoUrl:'',
        name:'',
        webUrl:'',
        description:''
      }],

      sortedExh:null,
      filteredExh:null,
      exhModalVisible:false,

      eventEnded:''
    }
  }

  eventExhFileHandler = event =>{
    // console.log("lg",event.target.files)
    let {exhData} = this.state;
    
    this.setState({
      exhData:{...exhData,eventExhFile:event.target.files}
    })

  }

  componentDidMount = ()=>{
    let {exhList} = this.state

    let sesEventCode = sessionStorage.getItem('active-event-code');


    //Get properties to get eventEnded on properties

    axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
    .then(response =>{
      // console.log("properties",response)
  
      // console.log("eventEnded",response.data.eventEnded )
      this.setState({ eventEnded:response.data.eventEnded });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(this.props.payload.apiLink+'/api/getAllExhibitors/'+sesEventCode,
      ).then((response) => {
        // console.log("body",response)
        
        exhList = _.values(response.data).map(partner => ({
          billing:partner.billing,
          category:partner.category,
          categoryLabel:partner.categoryLabel,
          id:partner.id,
          logoUrl:partner.logoUrl,
          name:partner.name,
          webUrl:partner.webUrl,
          description:partner.description
        }));
        
        // console.log("exhList",exhList)

        this.setState({exhList})
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  handleExhModalCancel = (e) => {
    // console.log(e);
    this.setState({
      exhModalVisible: false,      

    });
  }

  onChangeHandler = (e) =>{
    let {exhData} = this.state;
    
    switch(e.target.name){

      case 'exhName':
        this.setState({exhData:{...exhData,exhName:e.target.value}})
        // console.log("onChange event",this.state.exhData.exhName)
      break;
      case 'exhDescription':
        this.setState({exhData:{...exhData,exhDescription:e.target.value}})
        // console.log("onChange event",this.state.exhData.exhDescription)
      break;
      case 'exhBilling':
        this.setState({exhData:{...exhData,exhBilling:e.target.value}})
        // console.log("onChange event",this.state.exhData.exhBilling)
      break;
      case 'exhWebUrl':
        this.setState({exhData:{...exhData,exhWebUrl:e.target.value}})
        // console.log("onChange event",this.state.exhData.exhWebUrl)
      break;
      case 'exhCategory':
        this.setState({exhData:{...exhData,exhCategory:e.target.value}})
        // console.log("onChange event",this.state.exhData.exhCategory)
      break;
      case 'exhCategoryLabel':
        this.setState({exhData:{...exhData,exhCategoryLabel:e.target.value}})
        // console.log("onChange event",this.state.exhData.exhCategoryLabel)
      break;

    }
  }


  showExhModalEdit = (record) =>{
    let {exhData} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    
    exhData = {
      exhBilling:record.billing,
      exhCategory:record.category,
      exhCategoryLabel:record.categoryLabel,
      exhId:record.id,
      exhLogoUrl:record.logoUrl,
      exhName:record.name,
      exhWebUrl:record.webUrl,
      exhDescription:record.description,
      eventCode:sesEventCode,
      location:'info/exhibitor',
      submitStatus:'edit'
    }

    // console.log("record",record);

    this.setState({
      exhData,
      exhModalVisible:true
    });
  }

  showExhModalAdd = () =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    this.setState({
      exhData:{
        exhBilling:'',
        exhCategory:'',
        exhCategoryLabel:'',
        exhId:'',
        exhLogoUrl:'',
        exhName:'',
        exhWebUrl:'',
        exhDescription:'',
        eventExhFile:null,
        submitStatus:'add',
        eventCode:sesEventCode,
        location:'info/exhibitor'
      },
      exhModalVisible:true
    });
  }

  handleExhTableChange = (pagination,filters, sorter) => {
    // console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedExh: sorter,
      filteredExh:filters
    });
  }

  onDeleteExhHandler = (record) =>{
    let {exhList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    // console.log("record",record);

    let index = exhList.findIndex(x => x.id==record.id);

    let newList = [...exhList];
    newList.splice(index,1);
    // console.log("newList",exhList)

    let exhbObj = {
      eventCode:sesEventCode,
      company:this.props.payload.company,
      imageLink:record.logoUrl == undefined ? '':record.logoUrl,
      exhId:record.id
    }

    axios.post(this.props.payload.apiLink+'/api/deleteExhibitor',exhbObj
    )
    .then( (res) => {
      // console.log("resDel",res)

      this.setState({
        exhList:newList
      });
      openNotif('success',"Partner Deleted!","Partner Succesfully Deleted!")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  onSubmitExhHandler = (e) =>{
    e.preventDefault();
    let {exhData,exhList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let selFile = new FormData();

    if(exhData.submitStatus == 'add'){
      if(exhData.eventExhFile != undefined){
        selFile.append('filename',exhData.eventExhFile[0]);
        selFile.append('company',this.props.payload.company);
        selFile.append('eventCode',sesEventCode);
        
        selFile.append('oldFileLink',exhData.oLogoUrl);

        // console.log("exhData",exhData)
        let newExhId = ''
        let newImageLink = ''
        let updateLinkObj = {}
        axios.post(this.props.payload.apiLink+'/api/addExhibitor2/',
        exhData)
        .then( (res) => {

          // console.log(res);
          
          newExhId = res.data.exhId
          selFile.append('type',"ExhibitorLogo/"+newExhId);
          
          axios.post(this.props.payload.storageLink+'/api/addExhibitorLogo2/',
          selFile,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) =>{
            // console.log("imageres",response)

            newImageLink = response.data.dataURL

            updateLinkObj = {
              exhId:newExhId,
              logoUrl:newImageLink,
              eventCode:sesEventCode
            }

            axios.post(this.props.payload.apiLink+'/api/updateExhibitorLogoUrl/',
            updateLinkObj)
            .then((response) =>{
              // console.log("updateUrl",response)

              exhList.push({
                billing:exhData.exhBilling,
                category:exhData.exhCategory,
                categoryLabel:exhData.exhCategoryLabel,
                description:exhData.exhDescription,
                id:newExhId,
                logoUrl:newImageLink,
                name:exhData.exhName,
                webUrl:exhData.exhWebUrl,
              })

              this.setState({ exhList,
                exhModalVisible: false });
              openNotif('success',"Exhibitor Added!","Exhibitor Succesfully Added!")

            })
            .catch(function (error) {
              console.log(error);
            });

          })
          .catch(function (error) {
            console.log(error);
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      }
      else{
        axios.post(this.props.payload.apiLink+'/api/addExhibitor2',
        exhData)
        .then( (res) => {
  
          // console.log(res);
  
          exhList.push({
            billing:exhData.exhBilling,
            category:exhData.exhCategory,
            categoryLabel:exhData.exhCategoryLabel,
            description:exhData.exhDescription,
            id:res.data.exhId,
            logoUrl:'',
            name:exhData.exhName,
            webUrl:exhData.exhWebUrl,
          })

          this.setState({ exhList,
            exhModalVisible: false });
          openNotif('success',"Exhibitor Added!","Exhibitor Succesfully Added!")
  
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
    else if(exhData.submitStatus == 'edit'){
      if(exhData.eventExhFile != undefined){
        selFile.append('filename',exhData.eventExhFile[0]);
        selFile.append('company',this.props.payload.company);
        selFile.append('eventCode',sesEventCode);
        selFile.append('type',"PartnersLogo/"+exhData.exhId);
        selFile.append('oldFileLink',exhData.exhLogoUrl);
  
        let index = exhList.findIndex(x => x.id==exhData.exhId);
  
        const newItems = [...exhList];
  
        newItems[index].billing = exhData.exhBilling;
        newItems[index].category = exhData.exhCategory;
        newItems[index].categoryLabel = exhData.exhCategoryLabel;
        newItems[index].id = exhData.exhId
        newItems[index].name = exhData.exhName
        newItems[index].webUrl = exhData.exhWebUrl
        newItems[index].description = exhData.exhDescription
  
        // console.log("inside undefined")
  
        axios.post(this.props.payload.storageLink+'/api/addExhibitorLogo2/',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) =>{
          
          // console.log("response",response)
  
          let updateExhibitorObj = {
            exhBilling:exhData.exhBilling,
            exhCategory:exhData.exhCategory,
            exhCategoryLabel:exhData.exhCategoryLabel,
            exhId:exhData.exhId,
            exhLogoUrl:response.data.dataURL,
            exhName:exhData.exhName,
            exhWebUrl:exhData.exhWebUrl,
            exhDescription:exhData.exhDescription,
            eventCode:sesEventCode,
          }
  
          newItems[index].logoUrl = response.data.dataURL;
  
          axios.post(this.props.payload.apiLink+'/api/editExhibitor/',
          updateExhibitorObj)
          .then( (res) => {
  
            // console.log(res);
            this.setState({ exhList:newItems,
              exhModalVisible: false });
            openNotif('success',"Partner Edited!","Partner Succesfully Edited!")
            
  
          })
          .catch(function (error) {
            console.log(error);
          });
        })      
        .catch(function (error) {
          console.log(error);
        });
      }
      else{
        // console.log("edit undefined")
        axios.post(this.props.payload.apiLink+'/api/editExhibitor',
        exhData)
        .then( (res) => {
  
          // console.log(res);

          let index = exhList.findIndex(x => x.id==exhData.exhId);
  
          const newItems = [...exhList];
    
          newItems[index].billing = exhData.exhBilling;
          newItems[index].category = exhData.exhCategory;
          newItems[index].categoryLabel = exhData.exhCategoryLabel;
          newItems[index].id = exhData.exhId
          newItems[index].name = exhData.exhName
          newItems[index].webUrl = exhData.exhWebUrl
          newItems[index].description = exhData.exhDescription

          this.setState({ exhList:newItems,
            exhModalVisible: false });
          openNotif('success',"Partner Edited!","Partner Succesfully Edited!")
  
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
  }

  render(){
    let { sortedExh, exhList,filteredExh,exhModalVisible,exhData,eventEnded } = this.state;

    sortedExh = sortedExh || {};
    filteredExh = filteredExh || {};
    const exhColumns = [
      {
      title: 'Photo',
      width: 100,
      render: (text,record) =>   <Avatar shape="square" src={record.logoUrl} size="large"/>

    }, 
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      sortOrder: sortedExh.columnKey === 'name' && sortedExh.order,
    }, 
    {
      title: 'Company Profile',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder: sortedExh.columnKey === 'description' && sortedExh.order,
    },
    {
      title: 'Website Link',
      dataIndex: 'webUrl',
      key: 'webUrl',
      sorter: (a, b) => a.webUrl.length - b.webUrl.length,
      sortOrder: sortedExh.columnKey === 'webUrl' && sortedExh.order,
    },
    {
      title: 'Category Label',
      dataIndex: 'categoryLabel',
      key: 'categoryLabel',
      sorter: (a, b) => { return a.categoryLabel.localeCompare(b.categoryLabel)},
      sortOrder: sortedExh.columnKey === 'categoryLabel' && sortedExh.order,
    },
    {
      title: 'Order',
      dataIndex: 'billing',
      key: 'billing',
      sorter: (a, b) => { return a.billing - b.billing},
      sortOrder: sortedExh.columnKey === 'billing' && sortedExh.order,
    },
    
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (text,record) =>
      
      // <div>
      //       <Button onClick={(e) => {  e.stopPropagation(); this.showExhModalEdit(record)}}> 
      //         Edit
      //       </Button>

      //         <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteExhHandler(record)}}>
      //           <Button onClick={(e) => {  e.stopPropagation();}}> 
      //             Delete
      //           </Button  >
                
      //         </Popconfirm>  
      // </div>
      <div className="text-align-center">
      {/* <i class="material-icons font-small status status-red -hide-on-hover">lens</i> */}
      {/* <a href="#" onClick={(e) => {  e.stopPropagation(); this.showExhModalEdit(record)}} className="-show-on-hover" title="Edit"><i class="material-icons status-orange">edit</i></a> */}
      <a href="#" onClick={(e) => {  e.stopPropagation(); this.showExhModalEdit(record)}} title="Edit"><i class="material-icons status-orange">edit</i></a>
          <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteExhHandler(record)}}>
            {/* <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} className = "-show-on-hover"><i class="material-icons status-orange">delete</i></a> */}
            <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} ><i class="material-icons status-orange">delete</i></a>
          </Popconfirm>  
      </div>
    },
  ];

    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer/>
      <Modal title="Partner Details"
          visible={exhModalVisible}
          destroyOnClose = {true}
          footer = {[null]}
          onCancel={this.handleExhModalCancel}
      >
          
        <ExhibitorModal
          onChangeHandler = {this.onChangeHandler}
          eventExhFileHandler = {this.eventExhFileHandler}
          exhData = {exhData}
          onSubmitExhHandler = {this.onSubmitExhHandler}
          eventEnded = {eventEnded}
        />

      </Modal>

      <HeaderNav
        payload = {this.props.payload}
      />
    <SideMenu 
    payload = {this.props.payload}>
    </SideMenu>
        <ExhibitorForm
          handleExhTableChange = {this.handleExhTableChange}

          exhColumns = {exhColumns}
          exhList = {exhList}
          showExhModalAdd = {this.showExhModalAdd}
          eventEnded = {eventEnded}
        />
      </div>
    )
  }
}