import React, {Component} from 'react'
import HeaderNav from './Layout/Header';
import DashBoardForm from './Dashboard/dashboardForm'
import {dbFirebase} from '../config';
import _ from 'lodash';
import {openNotif} from '../utils';
import axios from 'axios';

import Timer from './Utils/Time'
import SideMenu from './Layout/SideMenu';
import moment from 'moment';

let mainObj = {};
//Pagination limit items
let limitPage = 5;


export default class Dashboard extends Component{
  constructor(props){
    super(props)
    this.state = {
      eventList:[{
      }],
      eventCode:null,

      paginationAgenda:[],
      pagesSes:[],
      currentPage:0,
      prevPage:"ant-pagination-prev ant-pagination-disabled",
      nextPage:"ant-pagination-next",
      
      listAgenda:[{
        allowAskAnonymous: false,
        description: "",
        id: "",
        isRatable: true,
        pollUrl: "",
        program: "",
        schedType: 1,
        sessionType: 1,
        surverUrl: "",
        time: "",
        timeEnd: "",
        timeStart: "",
        track: "",
        venue:{
          id:"",
          name:"",
          url:""
        },
        expert:[{
          id:"",
          imageLink:"",
          isRatable:true,
          name:"",
          role:""
        }],
        expertNames:"",
        dateStart:'',
        unFormatTimeStart:'',
        unFormatTimeEnd:'',
        onGoing:'',
        dateKey:'',

      }],
    }
  }

  convert(input) {
    return moment(input, 'HH:mm:ss').format('hh:mm A');
  }

  componentDidMount(){
    let currentUser = this.props.payload.uid
    let sesEventCode = sessionStorage.getItem('active-event-code');
    // // Get company
    //   dbFirebase.ref('users/authWeb/'+this.props.payload.uid).once("value").then(snap =>{
    //     console.log("snap",snap.val().company);
    //     company = snap.val().company;
    //     sessionStorage.setItem("user-company", company);
    //     return snap.val().company;;
    //   }).then(data =>{
    //     console.log("company",data)
    //     // Get event List for the Company
    dbFirebase.ref('/users/authWeb/'+currentUser).child('events').once('value').then(snaps=>{
      // console.log("snaps",snaps.val())
      if(snaps.val() !== null && snaps.val() !== null && snaps.val() !== undefined){
        mainObj = Object.values(snaps.val())
        // console.log("mainObj",mainObj)
        // console.log('sees',sesEventCode)
        if(sesEventCode == null){
          sessionStorage.setItem("active-event-code", mainObj[mainObj.length-1].eventCode);
        }
      } 
      else mainObj = {}

      this.setState({
        eventList:_.values(mainObj)
      });
    })
    // })

       //Get Agenda
       axios.get(this.props.payload.apiLink+'/api/getAllAgenda/'+sesEventCode)
       .then(response =>{
        //  console.log("responseAgenda",response)
        //  console.log("responseAgenda1",_.values(response.data))
        //  console.log("responseAgenda2",response)
   
         let expertsString = "";
         // this.convert(agenda.timeStart.getHours()  + ':' + agenda.timeStart.getMinutes()+ ':' + agenda.timeStart.getSeconds()) + "-" +this.convert(agenda.timeEnd.getHours()  + ':' + agenda.timeEnd.getMinutes()+ ':' + agenda.timeEnd.getSeconds()),
         let listAgenda = _.values(response.data).map(agenda => ({
           allowAskAnonymous: agenda.allowAskAnonymous,
           description: agenda.description,
           id: agenda.id,
           isRatable: agenda.isRatable,
           pollUrl: agenda.pollUrl,
           program: agenda.program,
           schedType: agenda.schedType,
           sessionType: agenda.sessionType,
           surverUrl: agenda.surverUrl,
           time: agenda.time,
           timeEnd: agenda.timeEnd,
           timeStart: agenda.timeStart,
           unFormatTimeStart: agenda.timeStart,
           unFormatTimeEnd:agenda.timeEnd,
           track: agenda.track,
           venue:agenda.venue === undefined ? '':agenda.venue,
           expert:_.values(agenda.expert),
           expertNames:"",
           dateStart:'',
           dateKey:'',
           onGoing:''
         }));
   
         for(let agendaIndex = 0;listAgenda.length > agendaIndex; agendaIndex++){
           
           let dateStart = new Date(parseInt(listAgenda[agendaIndex].timeStart))
   
           let hoursStart = dateStart.getHours();
           // Minutes part from the timestamp
           let minutesStart = "0" + dateStart.getMinutes();
           // Seconds part from the timestamp
           let secondsStart = "0" + dateStart.getSeconds();
   
           let yearStart = dateStart.getFullYear();
           let monthStart = dateStart.getMonth()+1;
           let dayStart = dateStart.getDate();
           //For table data
           let formattedDate =  monthStart + '/' + dayStart + '/' + yearStart
           let formattedDateKey = yearStart + '-' + monthStart + '-' + dayStart
   
           // Will display time in 10:30:23 format
           let formattedStartTime = hoursStart + ':' + minutesStart.substr(-2) + ':' + secondsStart.substr(-2);
           listAgenda[agendaIndex].timeStart = this.convert(formattedStartTime)
           // assign formatted data
           listAgenda[agendaIndex].dateStart = formattedDate
           listAgenda[agendaIndex].dateKey = formattedDateKey
           //End Date
           let dateEnd= new Date(parseInt(listAgenda[agendaIndex].timeEnd))
           let hoursEnd = dateEnd.getHours();
           let minutesEnd = "0" + dateEnd.getMinutes();
           let secondsEnd = "0" + dateEnd.getSeconds();
          //  let year = dateEnd.getFullYear();
           let formattedEndTime = hoursEnd + ':' + minutesEnd.substr(-2) + ':' + secondsEnd.substr(-2);
           listAgenda[agendaIndex].timeEnd = this.convert(formattedEndTime)
   
           listAgenda[agendaIndex].time = this.convert(formattedStartTime) + '-' + this.convert(formattedEndTime)
          
           var milliseconds = new Date().getTime();
           //To set OnGoing design on table
           if(milliseconds >= listAgenda[agendaIndex].unFormatTimeStart && milliseconds < listAgenda[agendaIndex].unFormatTimeEnd) listAgenda[agendaIndex].onGoing = 'active'
          
          //  console.log("mill",milliseconds)
           //list of expert for showing
           expertsString = ""
           for(let i = 0;listAgenda[agendaIndex].expert.length >i;i++){
             expertsString = expertsString + listAgenda[agendaIndex].expert[i].name + ',';
             listAgenda[agendaIndex].expertNames = expertsString
           }
           
           listAgenda[agendaIndex].expertNames = listAgenda[agendaIndex].expertNames.slice(0,-1)
           
         }

         listAgenda.sort(function(a,b) {return a.unFormatTimeStart - b.unFormatTimeStart;});
         //For Pagination
         let paginationAgenda = []
         let pagesSes = []
         

         for(let listCnt = 0; listCnt < listAgenda.length; listCnt++){
           if(listCnt < limitPage){
            paginationAgenda.push(listAgenda[listCnt]);
           }
         }

         let page = Math.ceil(listAgenda.length /limitPage)
        //  console.log("page",page)
         for(let pageCnt = 1; pageCnt <= page;pageCnt++ ){
           if(pageCnt == 1){
            pagesSes.push({
              active:"ant-pagination-item ant-pagination-item-active",
              tab:pageCnt-1,
              title:pageCnt
            });
           }
           else{
            pagesSes.push({
              active:"ant-pagination-item",
              tab:pageCnt-1,
              title:pageCnt
            });
           }
         }

        //  console.log("listAgenda",listAgenda)
        //  console.log("pagesSes",pagesSes)
        //  console.log("paginationAgenda",paginationAgenda)
         ///////////////////
         this.setState({ listAgenda,paginationAgenda,pagesSes });
   
       }).catch(function (error) {
         console.log(error);
       });
  }

  paginationAgendaList = (page) =>{
    let {listAgenda,pagesSes} = this.state
    let indexLimit = (page+1)*limitPage
    let indexMin = indexLimit - limitPage
    let paginationAgenda = []
    //max page
    let curPage = Math.ceil(listAgenda.length /limitPage)
    // console.log("max page",curPage)
    // console.log("page",page)
    // console.log("indexLimit",indexLimit)
    // console.log("indexMin",indexMin)
    if(page != -1 && page < curPage ){
      for(let listCnt = indexMin; listCnt < listAgenda.length; listCnt++){
        if(listCnt < indexLimit){
          paginationAgenda.push(listAgenda[listCnt]);
        }
      }
  
      
      pagesSes = []
      for(let pageCnt = 1; pageCnt <= curPage;pageCnt++ ){
        if(pageCnt == page+1){
          pagesSes.push({
            active:"ant-pagination-item ant-pagination-item-active",
            tab:pageCnt-1,
            title:pageCnt
          });
        }
        else{
          pagesSes.push({
            active:"ant-pagination-item",
            tab:pageCnt-1,
            title:pageCnt
          });
        }
  
        if(page == 0) this.setState({
          prevPage:"ant-pagination-prev ant-pagination-disabled",
          nextPage:"ant-pagination-next",
          currentPage:page
         })
        else if(page == curPage-1)this.setState({
          prevPage:"ant-pagination-prev",
          nextPage:"ant-pagination-next ant-pagination-disabled",
          currentPage:page
         })
        else this.setState({
          prevPage:"ant-pagination-prev",
          nextPage:"ant-pagination-next",
          currentPage:page
         })
  
      }
  
      // console.log("listAgenda",listAgenda)
      // console.log("paginationAgenda",paginationAgenda)
      ///////////////////
      this.setState({ paginationAgenda,pagesSes });
    }
    
  }

  setActiveEvent = (item) =>{
    openNotif('success',"Event Code "+item.eventCode+" Active","");
    sessionStorage.setItem("active-event-code", item.eventCode);
  }

  onChangeHandler = (e) => {
    switch(e.target.name){
      case 'uEventCode':
        this.setState({
          eventCode:e.target.value
        })
        console.log("onChange event",this.state.eventCode)
    }
  }

  addEventCode = () =>{
    let sesCompany = this.props.payload.company
    let eventC = this.state.eventCode;
    let currentUser = this.props.payload.uid
    //For Api Insert
    let dataObj = {
      eventCode:this.state.eventCode,
      status:1,
      company:sesCompany,
      uId:currentUser
    }
    // console.log("databoj",dataObj)
    //For dashboard list
    let itemObj = {
      eventCode:this.state.eventCode,
      status:1,
      }
   
    axios.post(this.props.payload.apiLink+'/api/addEventCode',
    dataObj).then(response => {
      // console.log("res",response)

      if(response.data != "Event Code Already Exist"){
        mainObj[eventC] = itemObj
        

        // console.log("mainobj",mainObj)
    
        this.setState({
          eventList:_.values(mainObj)
        });
        //Add Event on users node
        dbFirebase.ref('users/authWeb/'+currentUser+'/events/'+this.state.eventCode).update({
          eventCode:this.state.eventCode,
          endDate:'',
          eventName:'',
          startDate:''
        })
        //Add event on EventList per company
        dbFirebase.ref('EventList/'+sesCompany+'/' + this.state.eventCode).set({
          eventCode: this.state.eventCode,
          status: 1
      
        //----------------------------------------------- 
        });
        openNotif('success',"Event Code Added","Event "+this.state.eventCode+" Added");

      }
  })
  .catch(error=> {
    console.log(error);
  });
  }

  render(){
    // console.log("state eventlist",this.state.eventList);
    let {listAgenda,paginationAgenda,pagesSes,currentPage,prevPage,nextPage} = this.state
    return(
      <div id="area">
      <Timer/>
       <HeaderNav
        payload = {this.props.payload}
      />
      <SideMenu
      payload = {this.props.payload}
      >
      </SideMenu>
      
      <DashBoardForm
        eventList = {this.state.eventList}
        addEventCode = {this.addEventCode}
        setActiveEvent = {this.setActiveEvent}
        onChangeHandler={this.onChangeHandler}
        paginationAgendaList = {this.paginationAgendaList}

        listAgenda = {listAgenda}
        paginationAgenda = {paginationAgenda}
        pagesSes = {pagesSes}
        currentPage = {currentPage}
        nextPage = {nextPage}
        prevPage = {prevPage}
      />
      </div>

    )
  }
}