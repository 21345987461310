import React, {Component} from 'react'
import {Popconfirm,message} from 'antd';
import HeaderNav from './Layout/Header';
import MaterialForm from './Materials/materialForm'
import _ from 'lodash';
import {openNotif} from '../utils'
import axios from 'axios';
import QRious from 'qrious'
import JSZip from 'jszip';
import saveAs from 'file-saver'
import Timer from './Utils/Time'

import {Redirect} from 'react-router-dom';
import SideMenu from './Layout/SideMenu'

export default class Materials extends Component{
  constructor(props){
    super(props)
    this.state = {

      matData:[{
      }],
      iCompany:'',
      selectedFile:null,

      eventEnded:'',

      fileName:'',
      sortedMat:null,
      filteredMat:null,
      showError:'none',
      test:''
    }
  }

  success = (time) => {
    const hide = message.loading('Action in progress..', 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, time);
  };


  getDataUri =(url,name,count) =>{
    var image = new Image();
    let {matData} = this.state
    // console.log("matddata",matData)
   
    image.onload = () => {
      var canvas1 = document.createElement('canvas');
      canvas1.id = "canvas"+count;
      canvas1.height = 400;
  
      var ctx = canvas1.getContext("2d");
      let div = document.getElementById("c"); 
    
      var textString = name,
      textWidth = ctx.measureText(textString ).width;
      
      ctx.font = "12px Arial";
      ctx.fillText(textString , (canvas1.width/2) - (textWidth / 2), 40);
      ctx.textAlign = "center"; 

      // or 'width' if you want a special/scaled size


      ctx.drawImage(image, 0, 50);
      matData[count].dataURL = canvas1.toDataURL('image/png');
      console.log("matDataUrl",matData[count] )
      div.appendChild(canvas1)
        
      // Get raw image data
      // callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

      // ... or get as Data URI
      // callback(canvas.toDataURL('image/png'));
      this.setState({matData})
    };
    
    image.src = url;
}

  componentDidMount = () =>{
    let {matData} = this.state
    const qr = new QRious({size: 300});

    let sesEventCode = sessionStorage.getItem('active-event-code');

    //Get properties to get eventEnded on properties

    // axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
    // .then(response =>{
    //   console.log("properties",response)
 
    //   console.log("eventEnded",response.data.eventEnded )
    //   this.setState({ eventEnded:response.data.eventEnded });
    // }).catch(function (error) {
    //   console.log(error);
    // });

    axios.get(this.props.payload.apiLink+'/api/getMaterials/'+sesEventCode,
      ).then((response) => {
        // console.log("body",response)
        let matCount = 0;
        matData = _.values(response.data).map(materials => (
          // matCount = matCount+1,
          
          {
          company:materials.company,
          downloadURL:materials.downloadUrl,
          name:materials.name,
          id:materials.id,
          dataURL:'',
          // questionLabel:'Question'+matCount,
        }));

        // for(let matCnt = 0;matCnt < matData.length;matCnt++){
        //   matData.push({questionLabel:'Question'+matCnt})
        // }

        console.log("matData",matData)

        for(let matCnt = 0;matCnt < matData.length;matCnt++){
          //qr format
          qr.value =  matData[matCnt].name+'||'+ matData[matCnt].downloadURL+'||'+ matData[matCnt].company + '||speaker||'+matData[matCnt].id;
          //Convert to data url to download

          matData[matCnt].dataURL = qr.toDataURL();
        }
        this.setState({matData})

        // Add Text/Label on QR code
        for(let matCnt = 0;matCnt < matData.length;matCnt++){
          
          this.getDataUri(matData[matCnt].dataURL,matData[matCnt].name,matCnt);
        }

    
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onChangeHandler = (e) =>{
    
    switch(e.target.name){
      case 'company':
        this.setState({iCompany:e.target.value})
        // console.log("onChange event",this.state.iCompany)
        default:
      break;
    }
  }

  fileSelectedHandler = event =>{
    
    event.preventDefault();
    if(this.state.iCompany !== ''){
      // 
      const hide = message.loading('Action in progress..', 0);
      // setTimeout(hide, this.state.time);
        // var loadtime = setTimeout(hide, 10000);
      this.setState({showError:'none'})
      let files = event.target.files
      const qr = new QRious({size: 300});
      // console.log("files",files)

      let {matData,iCompany} = this.state
      let sesEventCode = sessionStorage.getItem('active-event-code');
      let trackCount = 0;
      let stopCnt = 0
      if(files !== undefined){
        // console.log("weew")
        for(let i = 0; i<files.length; i++){
          let selFile = new FormData();
          let matAddObj = {}
          console.log("i",i)

          // console.log("inside != undefined",files[i])
          selFile.append('filename',files[i]);
          selFile.append('company',this.props.payload.company);
          selFile.append('eventCode',sesEventCode);
          selFile.append('type',"materials");
          selFile.append('oldFileLink','');
          //Upload Material
          axios.post(this.props.payload.storageLink+'/api/addMaterial/',
          selFile,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) =>{
            console.log("responsee image",response)
            //Qr format
            qr.value =  response.data.name+'||'+ response.data.dataURL+'||'+ iCompany
            
            matAddObj = {
              name:response.data.name,
              downloadURL:response.data.dataURL,
              id:'',
              eventCode:sesEventCode,
              location:'materials',
              company:iCompany,
              dataURL:qr.toDataURL("image/png"),
              questionLabel:'Question'+parseInt(matData.length+1)
            }
            
            //Update db
            axios.post(this.props.payload.apiLink+'/api/addMaterialDetails/',
            matAddObj)
            .then( (res) => {
              matAddObj.id = res.orgId
              // console.log("matAddObj",matAddObj)
              trackCount += 1;
              matData.push(matAddObj)
              // console.log("trackCout",trackCount-1)
              //So success pop up will only show once
              if(parseInt(trackCount-1)==parseInt(i)&& stopCnt==0) {
                // this.setState({time:0});
                // clearTimeout(loadtime);
                stopCnt+=1
                message.destroy()
                openNotif('success',"Material Added!","Material Succesfully Added!")
                // this.setState({time:60000});
              }
              
              // console.log(res);
              this.setState({ matData});
            })
            .catch(function (error) {
              console.log(error);
            });
            
          })
          .catch(function (error) {
            console.log(error);
            message.destroy()
            openNotif('error',"Material upload failed!","File size is too big please limit the file to 10mb!")
          });
        }
      }
    }else{
      this.setState({showError:'block',fileName:''})
      
    }
    
  }

  onDeleteMatHandler = (record) =>{
    let {matData} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    // console.log("record",record);

    let index = matData.findIndex(x => x.id==record.id);

    let newList = [...matData];
    newList.splice(index,1);
    // console.log("newList",matData)
    // console.log("index",index)

    let matObj = {
      eventCode:sesEventCode,
      id:record.id,
      downloadURL:record.downloadURL,
      company:this.props.payload.company,
      type:'materials'
    }

    // console.log("matobj",matObj)

    axios.post(this.props.payload.apiLink+'/api/deleteMaterials',matObj
    )
    .then( (res) => {
      // console.log("resDel",res)

      this.setState({
        matData:newList
      });
      openNotif('success',"Material Deleted!","Material Succesfully Deleted!")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  DownloadAll = () =>{
    let {matData} = this.state

    // console.log("matdata",this.state);
    var arrURL = [];
    var arrName = [];
    var arrCompany = []
    for (var key in matData) {
      arrURL.push(matData[key].dataURL);
      arrName.push(matData[key].name.split('.').slice(0, -1).join('.'));
      arrCompany.push(matData[key].company);
    }
    
    // console.log("arr",arrURL);
    var zip = new JSZip();
 
    // Generate a directory within the Zip file structure
    var img = zip.folder("Qr Codes");

    // Add a file to the directory, in this case an image with data URI as contents
    for(let count = 0 ; count < arrURL.length; count ++){
      img.file(arrCompany[count]+'-'+arrName[count], arrURL[count].split(',')[1], {base64: true});

    }
    // Generate the zip file asynchronously
    zip.generateAsync({type:"blob"})
    .then(function(content) {
        // Force down of the Zip file
        saveAs(content, "qr.zip");
    });
  }

  handleMatTableChange = (pagination,filters, sorter) => {
    // console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedMat: sorter,
      filteredMat:filters
    });
  }

  render(){
   
    let { sortedMat,filteredMat,matData,eventEnded,showError,fileName,test } = this.state;

    sortedMat = sortedMat || {};
    filteredMat = filteredMat || {};
    const matColumns = [
    // {
    //   title: 'Question Id',
    //   dataIndex: 'questionLabel',
    //   key: 'questionLabel',
    //   sorter: (a, b) => { return a.name.localeCompare(b.name)},
    //   sortOrder: sortedMat.columnKey === 'questionLabel' && sortedMat.order,
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      sortOrder: sortedMat.columnKey === 'name' && sortedMat.order,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: (a, b) => { return a.company.localeCompare(b.company)},
      sortOrder: sortedMat.columnKey === 'company' && sortedMat.order,
    }, 
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (text,record) =>
      <div className="text-align-center">
        {/* <i class="material-icons font-small status status-red -hide-on-hover">lens</i> */}
        {/* <a href="#" onClick={(e) => {  e.stopPropagation(); this.showVenModalEdit(record)}} className="-show-on-hover" title="Edit"><i class="material-icons status-orange">edit</i></a> */}
        <a href={record.dataURL} download={record.company+'-'+record.name+'.png'} title="Download Qr"><i class="material-icons status-orange">select_all</i></a>
        {/* <a href={test} download={record.company+'-'+record.name+'.png'} title="Download Qr"><i class="material-icons status-orange">select_all</i></a> */}

        <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteMatHandler(record)}}>
        {/* <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} className = "-show-on-hover"><i class="material-icons status-orange">delete</i></a> */}
        <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} ><i class="material-icons status-orange">delete</i></a>
        </Popconfirm>  
      </div>
    },
  ];

    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer/>
       <HeaderNav
        payload = {this.props.payload}
      />
      <SideMenu payload = {this.props.payload}>
      </SideMenu>
      
      <MaterialForm
        onChangeHandler = {this.onChangeHandler}
        fileSelectedHandler = {this.fileSelectedHandler}
        handleMatTableChange = {this.handleMatTableChange}
        DownloadAll = {this.DownloadAll}
        
        fileName = {fileName}
        matData = {matData}
        matColumns = {matColumns}
        showError = {showError}
      />

      <div id='c' style={{marginLeft:'100px',marginBottom:'100px',height:'500px',width:'500px',display:'none'}}>
      
      </div>
      </div>

    )
  }
}