import React,{Component} from 'react';
import {Button,Table} from 'antd'

export default class pollsForm extends Component{
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className= "main">
          <div class="top">
            <h1>Polls</h1>
            {/* <div class="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
          </div>
          <div class="content">
            <h1 class="title-fancy">What are the <span class="emp">Questions</span><span class="font-huge">?</span></h1>
            <h2 class="title-fancy-sub">Upload poll details here</h2>
            
            <section class="section">
              
              <div class="space-between">
                <div>
                  
                </div>
                {/* <div>
                  <i class="material-icons font-small status-red">lens</i> Incomplete Details
                  <span class="h-space-small"></span>
                  <i class="material-icons font-small status-green">lens</i> Complete Details
                </div> */}
              </div>
              <h3 class="tbl-title">Questions</h3>
              <Button className = "btn-gray-dash-add" block onClick = {(e)=> this.props.showPollModalAdd(e)}>Add Poll</Button>
              <Table 
                className="tbl tbl-white -has-hover"
                columns={this.props.pollColumns} 
                dataSource={this.props.listPoll} 
                onChange={this.props.handlePollTableChange} 
                pagination={{ pageSize: 10 }} 
                // onRow={(record) => ({
                //   onClick: () => { this.props.showAgendaModalEdit(record); }
                // })}
            />
            </section>
          </div>
      </div>
    )
  }
}