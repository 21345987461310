import React,{Component} from 'react';
import {Input,Form,Button} from 'antd'
const { TextArea } = Input;

export default class exhibitorModal extends Component{
  constructor(props){
    super(props)
  }

  render(){
    return(
      <Form 
        className='Login-Form' 
        onSubmit= {(e)=> this.props.onSubmitExhHandler(e,this.props)} 
        layout = "vertical" 
        style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}
      >

        <div className="fld-item">
          <label>Name:</label>
          <Input onChange={this.props.onChangeHandler}
            className="fld"
            size="default" 
            name='exhName' 
            placeholder="Input Name" 
            defaultValue = {this.props.exhData.exhName}
          />
        </div>
        <div className="fld-item">
        <label>Description:</label>
          <TextArea 
          className="fld"
            onChange={this.props.onChangeHandler} 
            size="default" 
            name='exhDescription' 
            placeholder="Input Description" 
            defaultValue = {this.props.exhData.exhDescription}
          />
        </div>
        <div className="fld-item">
          <label>Category Label:</label>
          <Input
          className="fld"
            onChange={this.props.onChangeHandler} 
            size="default" 
            name='exhCategoryLabel' 
            placeholder="Input Web Url" 
            defaultValue = {this.props.exhData.exhCategoryLabel}
          />
        </div>
        <div className="fld-item">
          <label>Category Order:</label>
          <Input
          type = "number"
          className="fld"
            onChange={this.props.onChangeHandler} 
            size="default" 
            name='exhCategory' 
            placeholder="Input Order for Category" 
            defaultValue = {this.props.exhData.exhCategory}
          />
        </div>
        <div className="fld-item">
          <label>Web Url:</label>
          <Input 
          className="fld"
            onChange={this.props.onChangeHandler} 
            size="default" 
            name='exhWebUrl' 
            placeholder="Input Web Url"
            defaultValue = {this.props.exhData.exhWebUrl}
          />
        </div>
        <div className="fld-item">
          <label>Order:</label>
          <Input 
          type = "number"
          className="fld"
            onChange={this.props.onChangeHandler} 
            size="default" 
            name='exhBilling' 
            placeholder="Input Order"
            defaultValue = {this.props.exhData.exhBilling}
          />
        </div>
        <div className="fld-item">
          <label>Upload Partner Image:</label>
          <input type = "file" onChange={(e)=>this.props.eventExhFileHandler(e)} />
          <div class="text-gray v-space-large">Maximum upload file size: 15mb Dimensions should be 500px : 350px (rectangle)</div>
        <br></br>
        </div>
        <div className="fld-item">
          <Button disabled={this.props.eventEnded} type="primary" htmlType='submit' block>Save</Button>
        </div>

      </Form>
      
    )
  }
}