

import React,{Component} from 'react'
import {Button,Input,Select,Spin,Avatar,DatePicker,TimePicker,Table} from 'antd'
import moment from 'moment'
import About_Camera from '../../styles/images/About_Camera Frames@2x.png'
import Materials_icon from '../../styles/images/Materials_icon.png'
const Option = Select.Option;
const format = 'HH:mm';
const { TextArea } = Input;

export default class contentForm extends Component{
  constructor(props){
    super(props)
  }

  style ={
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px"
  }
  
  render(){
    return(

      <div className= "main">

        <div className="top">
          <h1>Materials</h1>
          {/* <div className="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
        </div>
        <div className="content">
          <h1 className="title-fancy">What the <span class="emp">material is about</span><span className="font-huge">?</span></h1>
          <h2 className="title-fancy-sub">Upload materials here</h2>
          
          <section className="section">
            
            <div class="dash-blocks text-align-center h-margin-small" style ={{marginRight:"200px", marginLeft:"200px"}}>
              <h3 class="text-gray"><i class="material-icons v-align text-gray">add</i> Materials</h3>
              <div class="display-frame"><img src={Materials_icon}/></div>
              <div class="text-gray v-space-large">Drop your files here. Maximum upload file size: 10mb </div>
              <div>
 
                
                <div class="fld-item fld-item-col-2" style={{marginBottom:"10px"}}>
                  <Input 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='company' 
                    placeholder="Input Company"
                    className="fld-large half-width"
                    style = {{width:"50%", display:'inline-block'}}
                    // defaultValue = {this.props.event.eventName}
                  />
                </div>
                <span style ={{display:this.props.showError,color:'#ED0D19', marginBottom:'10px'}}>Company is Required!</span>
                <input id="headBg" onChange={(e)=>this.props.fileSelectedHandler(e)} type="file" class="hide" value ={this.props.fileName} multiple/>
                <label for="headBg" class="btn btn-orange btn-small">Select file</label> 
              </div>
            </div>
            <h3 class="tbl-title">Material details</h3>
            <Button block onClick = {(e)=> this.props.DownloadAll()} className = "btn-gray-dash-add">Download All Qr</Button>
            <Table 
              className="tbl tbl-white -has-hover" cellpadding="0" cellspacing="0" border="0"
              columns={this.props.matColumns} 
              dataSource={this.props.matData} 
              onChange={this.props.handleMatTableChange} 
              pagination={{ pageSize: 10 }} 
            />

          </section>
        </div>
      </div>

 
    )
  }
}

  
   