import React,{Component} from 'react'
// import { Layout,Card} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import LoginForm from './LoginForm/loginForm'
import {authFirebase} from '../config'
import {openNotif,createTokens} from '../utils';
import {Redirect} from 'react-router-dom'

// import login from '../styles/login.css'
import {dbFirebase} from '../config'
import _ from 'lodash';
let mainObj = {}
// const { Content } = Layout;


export default class Login extends Component{
  constructor(props){
    super(props)
    this.state = {
      login:{
        username:null,
        password:null
      },
      isValid:null
    }
  }
  
  onSubmitHandler = (event,formProps) => {
    let company
    var childObj = {};
    let name
    let role
    // console.log("inside")
    event.preventDefault();

    let {login} = this.state;


    formProps.form.validateFields((err, values) => {
      let loggedInObj = {
        email:null,
        name:null,
        uid:null
      }
      if (!err) {
        // console.log('Received values of form: ', values);
        authFirebase.signInWithEmailAndPassword(login.username, login.password)
        .then(authUser =>{
          sessionStorage.setItem("logged-in", true);
          // console.log("auth user",authUser);
 
          loggedInObj=[
            authUser.user.email,
            authUser.user.uid
          ]
          openNotif('success',"LogIn Successful!","Welcome to EESY wizarding tool!")

        }).then(()=>{
             // Get company
             dbFirebase.ref('users/authWeb/'+loggedInObj[1]).once("value").then(snap =>{
              // console.log("snap",snap.val())
              let allSnap = snap.val()
              company = allSnap.company;
              
              name = allSnap.name
              if(allSnap.role =='' || allSnap.role==undefined) role = 'organizer'
              else role = allSnap.role
              // console.log("role",role)
              loggedInObj.push(company)
              loggedInObj.push(name)
              loggedInObj.push(role)

              return snap.val().company;;

            }).then(data =>{
              //data = company
              // console.log("company",data)
              
              dbFirebase.ref('Links/'+data).once("value").then(link=>{
                // console.log("link",link.val())
                //get dbLink if live use GANAPP link if not
                let linkVal = link.val()
                if(linkVal.dbLink != null && linkVal.dbLink != " " && linkVal.dbLink != "" &&linkVal.dbLink != undefined)
                loggedInObj.push(linkVal.dbLink)
                else loggedInObj.push('https://us-central1-eesy-15d1a.cloudfunctions.net')

                //livedb link for storage
                if(linkVal.storageLink != null && linkVal.storageLink != " " && linkVal.storageLink != "" &&linkVal.storageLink != undefined)
                loggedInObj.push(linkVal.storageLink)
                else loggedInObj.push('https://us-central1-eesy-15d1a.cloudfunctions.net')

                let sessiontoken = createTokens(loggedInObj)
                sessionStorage.setItem("user-session", sessiontoken);
  
                this.setState({isValid:"Success"});
              })
            })


        }).catch(function(err) {  

          openNotif('error',"LogIn Failed",err.message);
          console.log("error",err)
        });
      }
    });    
  };

onChangeHandler = (e) => {
  let {login} = this.state
  switch(e.target.name){
    case'uUsername':
    this.setState({login:{...login,username:e.target.value}})
    // console.log("username",this.state.login.username);
    break;
    case'uUpassword':
      this.setState({login:{...login,password:e.target.value}})
      // console.log("password",this.state.login.password);
    break;
  }
}

  render(){
    if(this.state.isValid =="Success"){
      return (<Redirect to ="/"/>)
    }
    return(
      <div>
        {/* <Layout>
        <HeaderNav></HeaderNav>
       
        <Content style={{ padding: '0 10%', marginTop: '150px' ,marginLeft: "auto",  marginRight: "auto", width:"100%"}}>
        
          <Card
            title="Login"
            style={{ width: '100%' ,  marginLeft: "auto",  marginRight: "auto" , maxWidth:"500px"}}
          > */}
            <LoginForm
             onChangeHandler = {this.onChangeHandler}
             onSubmitHandler = {this.onSubmitHandler}
            />
            
          {/* </Card>
        </Content>
        <FooterNav></FooterNav>
      </Layout> */}
      </div>
    )
  }
}