import React,{Component} from 'react'
import SettingsForm from './Settings/settingsForm'
import HeaderNav from './Layout/Header';
import {openNotif} from '../utils'
import SideMenu from './Layout/SideMenu'
import { Form} from 'antd';
import Timer from './Utils/Time'
import _ from 'lodash'
import axios from 'axios'
import {Redirect} from 'react-router-dom';

export default class Settings extends Component{
  constructor(props){
    super(props)
    this.state= {
      settings:{
        aboutEvent:false,
        aboutGanapp:false,
        aboutPointwest:false,
        engagement:false,
        eventFeedback:false,
        exhibitor:false,
        expert:false,
        messages:false,
        partner:false,
        privacyPolicy:false,
        question:false,
        survey:false,
        termsOfUse:false,
        vote:false,

        materials:false,
        myInfo:false,
        mySched:false,

        company:false,
        companyId:false,
        contactNumber:false,
        jobTitle:false,
        eventCode:''
      },
    }
  }

  componentDidMount = () =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');

    axios.get(this.props.payload.apiLink+'/api/getFeatures/'+sesEventCode)
    .then(response =>{
      // console.log("feature",response)
      let settings = {
        aboutEvent:response.data.infoSettings.aboutEvent.status,
        aboutGanapp:response.data.infoSettings.aboutGanapp.status,
        aboutPointwest:response.data.infoSettings.aboutPointwest.status,
        // engagement:response.data.infoSettings.engagement.status,
        eventFeedback:response.data.infoSettings.eventFeedback.status,
        exhibitor:response.data.infoSettings.exhibitor.status,
        expert:response.data.infoSettings.expert.status,
        messages:response.data.infoSettings.messages.status,
        partner:response.data.infoSettings.partner.status,
        privacyPolicy:response.data.infoSettings.privacyPolicy.status,
        question:response.data.infoSettings.question.status,
        survey:response.data.infoSettings.survey.status,
        termsOfUse:response.data.infoSettings.termsOfUse.status,
        vote:response.data.infoSettings.vote.status,

        materials:response.data.profSettings.materials.status,
        myInfo:response.data.profSettings.myInfo.status,
        mySched:response.data.profSettings.mySched.status,

        company:response.data.userAddedInfo.company.status,
        companyId:response.data.userAddedInfo.companyId.status,
        contactNumber:response.data.userAddedInfo.contactNumber.status,
        jobTitle:response.data.userAddedInfo.jobTitle.status,

        eventCode:sesEventCode,
      };
      console.log("settings",settings)
      this.setState({ settings });
    }).catch(function (error) {
      console.log(error);
    });
  }

  onSubmitHandler = () =>{
    let {settings} = this.state

    axios.post(this.props.payload.apiLink+'/api/addFeature',
    settings
       ).then((response) => {
        // console.log(response);
        openNotif('success',"Session Created","Session Succesfully Created!")
 
       })
       .catch(function (error) {
         console.log(error);
       }); 
  }

  handleOnClick = (e) =>{
    let {settings} = this.state

    switch(e.target.name){
      //Info Settings
      case 'chk-aboutEvent':
        this.setState({settings:{...settings,aboutEvent:e.target.checked}})
      break;
      case 'chk-aboutGanapp':
        this.setState({settings:{...settings,aboutGanapp:e.target.checked}})
      break;
       case 'chk-aboutPointwest':
        this.setState({settings:{...settings,aboutPointwest:e.target.checked}})
      break;
      case 'chk-engagement':
        this.setState({settings:{...settings,engagement:e.target.checked}})
      break;
      case 'chk-eventFeedback':
        this.setState({settings:{...settings,eventFeedback:e.target.checked}})
      break;
      case 'chk-exhibitor':
        this.setState({settings:{...settings,exhibitor:e.target.checked}})
      break;
      case 'chk-expert':
        this.setState({settings:{...settings,expert:e.target.checked}})
      break;
      case 'chk-messages':
        this.setState({settings:{...settings,messages:e.target.checked}})
      break;
      case 'chk-partner':
        this.setState({settings:{...settings,partner:e.target.checked}})
      break;
      case 'chk-privacyPolicy':
        this.setState({settings:{...settings,privacyPolicy:e.target.checked}})
      break;
      case 'chk-question':
        this.setState({settings:{...settings,question:e.target.checked}})
      break;
      case 'chk-survey':
        this.setState({settings:{...settings,survey:e.target.checked}})
      break;
      case 'chk-termsOfUse':
        this.setState({settings:{...settings,termsOfUse:e.target.checked}})
      break;
      case 'chk-vote':
        this.setState({settings:{...settings,vote:e.target.checked}})
      break;
      //Profile Settings
      case 'chk-materials':
        this.setState({settings:{...settings,materials:e.target.checked}})
      break;
      case 'chk-myInfo':
      this.setState({settings:{...settings,myInfo:e.target.checked}})
      break;
      case 'chk-mySched':
        this.setState({settings:{...settings,mySched:e.target.checked}})
      break;
      //User Added Info
      case 'chk-company':
        this.setState({settings:{...settings,company:e.target.checked}})
      break;
      case 'chk-companyId':
        this.setState({settings:{...settings,companyId:e.target.checked}})
      break;
      case 'chk-contactNumber':
        this.setState({settings:{...settings,contactNumber:e.target.checked}})
      break;
      case 'chk-jobTitle':
        this.setState({settings:{...settings,vote:e.target.jobTitle}})
      break;
    }
  }

  // appendInput() {
  //   var newInput = `input-${this.state.inputs.length}`;
  //   this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
  // }

  render(){
    let {settings} = this.state
    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer></Timer>
      <HeaderNav
        payload = {this.props.payload}
      />
      <SideMenu payload = {this.props.payload}>
      </SideMenu>
          <SettingsForm
            handleOnClick = {this.handleOnClick}
            onSubmitHandler = {this.onSubmitHandler}
            settings = {settings}
          />
          {/* <div>
               <form>
                   <div id="dynamicInput">
                       {this.state.inputs.map(input => <input type='text' value ={input}/>)}
                   </div>
               </form>
               <button onClick={ () => this.appendInput() }>
                   CLICK ME TO ADD AN INPUT
               </button>
            </div> */}
      </div>
    )
  }
}