import React,{Component} from 'react';
import {Button,Table} from 'antd'

export default class venueForm extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className= "main">

        <div className="top">
          <h1>Venues</h1>
          {/* <div className="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
        </div>
        <div className="content">
          <h1 className="title-fancy">Where's the <span className="emp">venue</span><span className="font-huge">?</span></h1>
          <h2 className="title-fancy-sub">Upload venue details here</h2>
          
          <section className="section">
            
            <div className="space-between">
              <div>
                
              </div>
              {/* <div>
                <i className="material-icons font-small status-red">lens</i> Incomplete Details
                <span className="h-space-small"></span>
                <i className="material-icons font-small status-green">lens</i> Complete Details
              </div> */}
            </div>
            
            <h3 className="tbl-title">Venue Details</h3>
            <Button disabled={this.props.eventEnded} className = "btn-gray-dash-add" block onClick = {(e)=> this.props.showVenModalAdd(e)}>Add Venue</Button>
            <Table 
                columns={this.props.venColumns} 
                dataSource={this.props.venList} 
                onChange={this.props.handleVenTableChange} 
                pagination={{ pageSize: 10 }} 
                className="tbl tbl-white -has-hover" cellpadding="0" cellspacing="0" border="0"
                // onRow={(record) => ({
                //   onClick: () => { this.props.showAgendaModalEdit(record); }
                // })}
              />
          </section>
        </div>
      </div>
    )
  }
}