import React,{Component} from 'react'
import {Input,Form,Button} from 'antd'
const { TextArea } = Input;
export default class partnerModal extends Component{
  constructor(props){
    super(props)
  }

  render(){


    return(
        <Form 
        className='Login-Form' 
        onSubmit= {(e)=> this.props.handleOrgSubmitHandler(e,this.props)} 
        layout = "vertical" 
        style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}
        >

        <div className="fld-item">
          <label>Name:</label>
          <Input onChange={this.props.onChangeHandler} 
          className="fld"
            size="default" 
            name='oName' 
            placeholder="Input Name" 
            defaultValue = {this.props.orgData.oName}
          />
        </div>
        <div className="fld-item">
          <label>Description:</label>
          <TextArea 
            onChange={this.props.onChangeHandler} 
            className="fld"
            size="default" 
            name='oDescription' 
            placeholder="Input Description" 
            defaultValue = {this.props.orgData.oDescription}
          />
        </div>
        <div className="fld-item">
          <label>Category Label:</label>
          <Input
            onChange={this.props.onChangeHandler} 
            className="fld"
            size="default" 
            name='oCategoryLabel' 
            placeholder="Input Web Url" 
            defaultValue = {this.props.orgData.oCategoryLabel}
          />
        </div>
        <div className="fld-item">
          <label>Category Order:</label>
          <Input
          type = "number"
            onChange={this.props.onChangeHandler} 
            className="fld"
            size="default" 
            name='oCategory' 
            placeholder="Input Order for Category" 
            defaultValue = {this.props.orgData.oCategory}
          />
        </div>
        <div className="fld-item">
          <label>Web Url:</label>
          <Input 
            onChange={this.props.onChangeHandler} 
            className="fld"
            size="default" 
            name='oWebUrl' 
            placeholder="Input Web Url"
            defaultValue = {this.props.orgData.oWebUrl}
          />
        </div>
        <div className="fld-item">
          <label>Order:</label>
          <Input 
          type = "number"
            onChange={this.props.onChangeHandler} 
            className="fld"
            size="default" 
            name='oBilling' 
            placeholder="Input Order"
            defaultValue = {this.props.orgData.oBilling}
          />

        </div>
        <div className="fld-item">
          <label>Upload Partner Image:</label>
          <input type = "file" onChange={(e)=>this.props.eventOrgFileHandler(e)} />
          <div class="text-gray v-space-large">Maximum upload file size: 15mb Dimensions should be 500px : 350px (rectangle)</div>
        </div>

        <div className="fld-item">
          <Button disabled={this.props.eventEnded} type="primary" htmlType='submit' block>Save</Button>
        </div>

      </Form>
      
    )
  }
}