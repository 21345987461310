import React,{Component} from 'react'
import PollsForm from './Polls/pollsForm'
import HeaderNav from './Layout/Header';
import {openNotif} from '../utils'
import SideMenu from './Layout/SideMenu'
import { Modal,message,Popconfirm} from 'antd';
import PollModal from './Polls/pollsModal'

import {Redirect} from 'react-router-dom';
import _ from 'lodash'
import axios from 'axios'

export default class Settings extends Component{
  constructor(props){
    super(props)
    this.state = {
      listPoll:[],
      pollData:{
        id:'',
        options:[],
        question:'',
        eventCode:'',
        submitStatus:'',
        questionLabel:''
      },
      pollModalVisible:false,
      sortedPoll:null,
      filteredPoll:null,
    }
  }

  onSelectedFileHandler = (e) =>{
    let {pollData} = this.state;
    let index = pollData.options.findIndex(x => x.id==e.target.name);
    pollData.options[index].selectedFile = e.target.files
    this.setState({
      pollData
    })

  }

  onChangeHandler = (e) =>{
    let {pollData,options} = this.state;
    console.log("etarget.name",e.target.name)
    switch(e.target.name){

      case 'pQuestion':
        this.setState({pollData:{...pollData,question:e.target.value}})
        // console.log("onChange event",this.state.orgData.oName)
      break;
      default:
        let index = pollData.options.findIndex(x => x.id==e.target.name);
        pollData.options[index].value = e.target.value
        this.setState({pollData})
        // console.log("onChange event",this.state.pollData)
        
      break;
    }
  }

  componentDidMount = () =>{
    // let sesEventCode = sessionStorage.getItem('active-event-code');
    // let {listPoll} = this.state;

    // axios.get(this.props.payload.apiLink+'/api/getAllPolls/'+sesEventCode)
    // .then(response =>{
    //   // console.log("polls",response)
    //   listPoll = _.values(response.data).map(poll => ({
    //     id:poll.id,
    //     question:poll.question,
    //     options:_.values(poll.options),
    //     eventCode:sesEventCode,
    //     responseCount:poll.responseCount,
    //     responses:poll.responses
    //   }));
    //   // console.log("settings",listPoll)
    //   this.setState({ listPoll });
    // }).catch(function (error) {
    //   console.log(error);
    // });
    this.getPollFunc();

  }

  // appendInput = (e,option,pollId) => {
  //   let {pollData,options} = this.state;
  //   let sesEventCode = sessionStorage.getItem('active-event-code');
  //   e.preventDefault();
  //   let pollOptions = pollData.options;
  //   console.log("dasdas",pollData)
  //   console.log("optioniD",option)
  //   console.log("pollOptions",pollOptions)
    
  //   var item = {
  //     id:option,
  //     value:'',
  //     selectedFile:''
  //   }
  //   const newItems = [...pollData.options];
  //   newItems.push(item);
  //   pollData = {
  //     options:newItems,
  //     id: pollId,
  //     question:pollData.question,
  //     eventCode:sesEventCode
  //   }
  //   console.log('newitems',pollData)

  //   this.setState({pollData})
  //   // this.setState(prevState => ({ pollData:{,prevState.pollData.concat([newInput]) }}));
  // }

  appendInput = (e,pollId) => {
    let {pollData,options} = this.state;
    let sesEventCode = sessionStorage.getItem('active-event-code');
    e.preventDefault();
    let pollOptions = pollData.options;
    var item = {
      id:'option'+Math.floor((Math.random() * 1000) + 1),
      value:'',
      selectedFile:''
    }
    const newItems = [...pollData.options];
    newItems.push(item);
    pollData = {
      options:newItems,
      id: pollId == '' ?'poll'+Math.floor((Math.random() * 1000) + 1):pollId,
      question:pollData.question,
      eventCode:sesEventCode,
      submitStatus:pollData.submitStatus
    }
    console.log('newitems',pollData)

    this.setState({pollData})
    // this.setState(prevState => ({ pollData:{,prevState.pollData.concat([newInput]) }}));
    

  }
  
  showPollModalAdd = () =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    this.setState({
      pollData:{
        id:'',
        options:[],
        question:'',
        eventCode:sesEventCode,
        submitStatus:'add'
      },
      pollModalVisible:true
    });
  }
  
  showPollModalEdit = (record) =>{
    let {pollData} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    console.log("edit record",record)

    pollData = {
      id:record.id,
      options:record.options,
      question:record.question,
      eventCode:sesEventCode,
      submitStatus:'edit',
      responseCount:record.responseCount,
      responses:record.responses
    }
    this.setState({
      pollData,
      pollModalVisible:true
    });
  }


  getPollFunc = () =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {listPoll} = this.state;
    let pollCount = 0;
    axios.get(this.props.payload.apiLink+'/api/getAllPolls/'+sesEventCode)
    .then(response =>{
      // console.log("polls",response)
      listPoll = _.values(response.data).map(poll => (
        pollCount = pollCount+1,
        
        {
        id:poll.id,
        question:poll.question,
        options:_.values(poll.options),
        eventCode:sesEventCode,
        responseCount:poll.responseCount,
        responses:poll.responses,
        questionLabel:'Question'+pollCount,
      }));
      console.log("settings",listPoll)
      this.setState({ listPoll });
    }).catch(function (error) {
      console.log(error);
    });
  }

  onSubmitHandler = (e) =>{
    e.preventDefault();
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {pollData,listPoll} = this.state

    
    const hide = message.loading('Action in progress..', 0);
    if(pollData.submitStatus =='add'){
      
      console.log("listPoll",listPoll)
      axios.post(this.props.payload.apiLink+'/api/addPoll',
      pollData)
      .then((response) =>{
        console.log(response);
        let trackCount = 0;
        let stopCnt = 0;
        let selFile = new FormData();
        pollData.questionLabel='Question'+listPoll.length+1;
        pollData.id = response.data.newPollId;
        // pollData.options=[];
        console.log("poldata.id",pollData.id)
        console.log("pollData",pollData)
        listPoll.push(pollData)
        console.log("listPoll",listPoll)
        // let index = listPoll.findIndex(x => x.id==pollData.id);

        for(let count=0;count < pollData.options.length;count++){
          // for(let count=0;count ==0;count++){
          console.log("count",count)
          if(!pollData.options[count].selectedFile == ""){

            selFile.append('filename',pollData.options[trackCount].selectedFile[0]);
            selFile.append('company',this.props.payload.company);
            selFile.append('eventCode',sesEventCode);
            selFile.append('type',"pollList/"+response.data.newPollId+'/options/option'+(trackCount+=1));
            selFile.append('oldFileLink','');

            axios.post(this.props.payload.storageLink+'/api/addPollOptionImage/',
            selFile,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) =>{
              trackCount += 1;
              console.log("trackCount",trackCount)
              // console.log("pollData.options[count].id",pollData.options[count].id)
              // let index2 = (listPoll[index].options).findIndex(x => x.id==pollData.options[count].id);
              // console.log("index2",index2)
              // listPoll[index].options.push({
              //   id:pollData.options[count].id,
              //   value:pollData.options[count].value,
              //   imageUrl:response.data.dataURL
              // })
              // console.log("parseInt(trackCount-1)==parseInt(count)",parseInt(trackCount-1)==parseInt(count))
              // console.log("stopCnt",stopCnt)
              // console.log("parseInt(trackCount-1)",parseInt(trackCount-1))
              //To show the success message once
              //stopCnt in case the loop is not yet done after uploading
              if(parseInt(trackCount-3)==parseInt(count) && stopCnt ==0) {
                this.getPollFunc();
                stopCnt+=1;
                message.destroy()
                openNotif('success',"Poll Added!","Poll Succesfully Added!")
                // console.log("pollList",listPoll);
                this.setState({pollModalVisible:false,listPoll})
              }
              // console.log("response",response)
            })
            selFile = new FormData();
          }
          else if(pollData.options[count].selectedFile == "" || pollData.options[count].selectedFile == undefined){
            console.log('inside lese')
            trackCount += 1;
            // listPoll[index].options.push({
            //   id:pollData.options[count].id,
            //   value:pollData.options[count].value,
            //   imageUrl:pollData.options[count].imageUrl == undefined ? '':pollData.options[count].imageUrl
            // })
            if(parseInt(trackCount-1)==parseInt(count)) {
              this.getPollFunc();
              stopCnt+=1;
              message.destroy()
              openNotif('success',"Poll Added!","Poll Succesfully Added!")
              // console.log("pollList",listPoll);
              this.setState({pollModalVisible:false,listPoll})
            }
          }
        }
      })
      .catch((error) =>{
        console.log(error);
      });
    }
    else if(pollData.submitStatus =='edit'){

      console.log("pollData",pollData)

      axios.post(this.props.payload.apiLink+'/api/editPoll',
      pollData)
      .then((response) =>{
        console.log(response);
        let trackCount = 0;
        let stopCnt = 0;
        let selFile = new FormData();
        listPoll.push(pollData)
        console.log("listPoll",listPoll)
        for(let count=0;count < pollData.options.length;count++){
          console.log("count",count)
          if(!pollData.options[count].selectedFile == ""){

            selFile.append('filename',pollData.options[count].selectedFile[0]);
            selFile.append('company',this.props.payload.company);
            selFile.append('eventCode',sesEventCode);
            selFile.append('type',"pollList/"+pollData.id+'/options/option'+(count+=1));
            selFile.append('oldFileLink','');

            axios.post(this.props.payload.storageLink+'/api/addPollOptionImage/',
            selFile,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) =>{
              trackCount += 1;

              //To show the success message once
              //stopCnt in case the loop is not yet done after uploading
              if(parseInt(trackCount)==parseInt(count) && stopCnt ==0) {
                this.getPollFunc();
                stopCnt+=1;
                message.destroy()
                openNotif('success',"Poll Added!","Poll Succesfully Added!")
                // console.log("pollList",listPoll);
                this.setState({pollModalVisible:false,listPoll})
              }
              // console.log("response",response)
            })
            selFile = new FormData();
          }
          else if(pollData.options[count].selectedFile == "" || pollData.options[count].selectedFile == undefined){
            console.log('inside lese')
            trackCount += 1;
            console.log("track count",trackCount);
            console.log("track count cond",trackCount==count );
           
            if(parseInt(trackCount-1)==parseInt(count) && stopCnt ==0) {
              this.getPollFunc();
              stopCnt+=1;
              console.log("stopCnt",stopCnt);
              message.destroy()
              openNotif('success',"Poll Added!","Poll Succesfully Added!")
              // console.log("pollList",listPoll);
              this.setState({pollModalVisible:false,listPoll})
            }
          }
        }
      })
      .catch((error) =>{
        console.log(error);
      });     
          
    }
  }

  onDeletePollHandler = (record) =>{
    let {listPoll} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    // console.log("record",record);

    let index = listPoll.findIndex(x => x.id==record.id);

    let newList = [...listPoll];
    newList.splice(index,1);
    // console.log("newList",newList)

    let pollObj = {
      eventCode:sesEventCode,
      id:record.id,
      imageUrl:record.imageUrl == undefined ? '':record.imageUrl
    }

    axios.post(this.props.payload.apiLink+'/api/deletePoll/',pollObj
    )
    .then( (res) => {
      // console.log("resDel",res)

      this.setState({
        listPoll:newList
      });
      openNotif('success',"Poll Deleted!","Poll Succesfully Deleted!")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  handlePollModalCancel = (e) => {
    // console.log(e);
    this.setState({
      pollModalVisible: false,
    });
  }

  handlePollTableChange = (pagination,filters, sorter) => {
    // console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedPoll: sorter,
      filteredPoll:filters
    });
  }

  hanldeRemoveOption = (record,id) =>{
    // console.log("recpmrd",record)

    let {pollData} = this.state
    let index = pollData.options.findIndex(x => x.id==record.id);

    let newList = [...pollData.options];
    newList.splice(index,1);
    this.setState({
      pollData:{...pollData,options:newList}
    });
  }

  render(){
    let {pollModalVisible,pollData,listPoll,filteredPoll,sortedPoll} = this.state;
    sortedPoll = sortedPoll || {};
    filteredPoll = filteredPoll || {};
    const pollColumns = [
      {
        title: 'Question Id',
        dataIndex: 'questionLabel',
        key: 'questionLabel',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        sortOrder: sortedPoll.columnKey === 'questionLabel' && sortedPoll.order,
      },
     
      {
        title: 'Questions',
        dataIndex: 'question',
        key: 'question',
        sorter: (a, b) => { return a.question.localeCompare(b.question)},
        sortOrder: sortedPoll.columnKey === 'question' && sortedPoll.order,
      },
      {
        title: 'Action',
        key: 'operation',
        width: 100,
        render: (text,record) =>
        <div className="text-align-center">
        {/* <i class="material-icons font-small status status-red -hide-on-hover">lens</i> */}
        {/* <a href="#" onClick={(e) => {  e.stopPropagation(); this.showOrgModalEdit(record)}} className="-show-on-hover" title="Edit"><i class="material-icons status-orange">edit</i></a> */}
        <a href="#" onClick={(e) => {  e.stopPropagation(); this.showPollModalEdit(record)}} title="Edit"><i class="material-icons status-orange">edit</i></a>

            <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeletePollHandler(record)}}>
              {/* <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} className = "-show-on-hover"><i class="material-icons status-orange">delete</i></a> */}
              <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} ><i class="material-icons status-orange">delete</i></a>
            </Popconfirm>  
        </div>
      },
    ];

    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
       
      <HeaderNav
        payload = {this.props.payload}
      />
      <SideMenu payload = {this.props.payload}>
      </SideMenu>
        <PollsForm
          handleOnClick = {this.handleOnClick}
          showPollModalAdd = {this.showPollModalAdd}
          handlePollTableChange = {this.handlePollTableChange}
          showPollModalEdit = {this.showPollModalEdit}

          pollColumns = {pollColumns}
          listPoll = {listPoll}
        />
        <Modal title="Poll Details"
            visible={pollModalVisible}

            destroyOnClose = {true}
            footer = {[null]}
            onCancel={this.handlePollModalCancel}
        >
            
          <PollModal
            pollData = {pollData}
            listPoll = {listPoll}

            onSubmitHandler = {this.onSubmitHandler}
            onChangeHandler = {this.onChangeHandler}
            handleOrgSubmitHandler = {this.handleOrgSubmitHandler}
            appendInput = {this.appendInput}
            onSelectedFileHandler = {this.onSelectedFileHandler}
            hanldeRemoveOption = {this.hanldeRemoveOption}
          />

        </Modal>
      
      </div>
    )
  }
}