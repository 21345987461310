import React,{Component} from 'react'
import UrlForm from './Content/urlForm'
import HeaderNav from './Layout/Header';
import {openNotif} from '../utils'
import SideMenu from './Layout/SideMenu'
import Timer from './Utils/Time'
import _ from 'lodash'
import axios from 'axios'
import {Redirect} from 'react-router-dom';

export default class FormUrl extends Component{
  constructor(props){
    super(props)
    this.state = {
      role:'',
      formUrls:{
        feedbackUrl:"",
        formMaterialUrl:"",
        formMyScheduleUrl:"",
        formSessionUrl:"",
        formSpeakerUrl:"",
        notifSheetKey:"",
      },
      arrayUrl:[]
    }
  }

  componentDidMount = () =>{
    let {formUrls,arrayUrl,role} = this.state;
    let sesEventCode = sessionStorage.getItem('active-event-code');

    role = this.props.payload.role;
    // console.log("this.props.payload",role)

    axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
    .then(response =>{
      console.log("properties",response)
  
      // console.log("eventEnded",response.data.eventEnded )
      formUrls = {
        feedbackUrl:response.data.feedbackUrl,
        formMaterialUrl:response.data.formMaterialUrl,
        formMyScheduleUrl:response.data.formMyScheduleUrl,
        formSessionUrl:response.data.formSessionUrl,
        formSpeakerUrl:response.data.formSpeakerUrl,
        notifSheetKey:response.data.notifSheetKey,
      }

      if(response.data.feedbackUrl != '')arrayUrl.push("Feedback Form Url:"+'\xa0'.repeat(7.5)+response.data.feedbackUrl)
      if(response.data.formMaterialUrl != '')arrayUrl.push("Material Form Url: "+'\xa0'.repeat(9.5)+response.data.formMaterialUrl)
      if(response.data.formMyScheduleUrl != '')arrayUrl.push("My Schedule Form Url: "+'\xa0'.repeat(1)+response.data.formMyScheduleUrl)
      if(response.data.formSessionUrl != '')arrayUrl.push("Session Form Url: "+'\xa0'.repeat(10)+response.data.formSessionUrl)
      if(response.data.formSpeakerUrl != '')arrayUrl.push("Speaker Form Url: "+'\xa0'.repeat(10)+response.data.formSpeakerUrl)
      if(response.data.notifSheetKey != '')arrayUrl.push("Notif Form Sheet Key: "+'\xa0'.repeat(3)+response.data.notifSheetKey)
      
      // console.log('formUrls',formUrls)
      this.setState({formUrls,arrayUrl,role})
    }).catch(function (error) {
      console.log(error);
    });
  }

  onSubmitHandler = () =>{
    let {formUrls} = this.state;
    let sesEventCode = sessionStorage.getItem('active-event-code');

    axios.put(this.props.payload.apiLink+'/api/addFormUrls/'+sesEventCode,
    formUrls)
    .then( (res) => {

      openNotif('success',"Form Url Edited!","Form Url Succesfully Edited!")
      

    })
    .catch(function (error) {
      console.log(error);
    });
  }

  onChangeHandler = (e) =>{
    let {formUrls} = this.state;

    switch(e.target.name){
      case 'feedbackUrl':
        this.setState({formUrls:{...formUrls,feedbackUrl:e.target.value}})
        // console.log("onChange event",this.state.formUrls.feedbackUrl)
      break;
      case 'formMaterialUrl':
        this.setState({formUrls:{...formUrls,formMaterialUrl:e.target.value}})
        // console.log("onChange event",this.state.formUrls.formMaterialUrl)
      break;
      case 'formMyScheduleUrl':
        this.setState({formUrls:{...formUrls,formMyScheduleUrl:e.target.value}})
        // console.log("onChange event",this.state.formUrls.formMyScheduleUrl)
      break;
      case 'formSessionUrl':
        this.setState({formUrls:{...formUrls,formSessionUrl:e.target.value}})
        // console.log("onChange event",this.state.formUrls.formSessionUrl)
      break;
      case 'formSpeakerUrl':
        this.setState({formUrls:{...formUrls,formSpeakerUrl:e.target.value}})
        // console.log("onChange event",this.state.formUrls.formSpeakerUrl)
      break;
      case 'notifSheetKey':
        this.setState({formUrls:{...formUrls,notifSheetKey:e.target.value}})
        // console.log("onChange event",this.state.formUrls.notifSheetKey)
      break;
    }
  }

  render(){
    let {formUrls,arrayUrl,role} = this.state
    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer/>
      <HeaderNav
        payload = {this.props.payload}
      />
      <SideMenu
      payload = {this.props.payload}>
        
      </SideMenu>
          <UrlForm
            onChangeHandler={this.onChangeHandler}
            onSubmitHandler = {this.onSubmitHandler}
            arrayUrl = {arrayUrl}
            formUrls = {formUrls}
            role = {role}
          />
      </div>
    )
 }
}