import React,{Component} from 'react'
import AboutForm from './Content/aboutForm'
import { Layout,Card,Button,Popconfirm,Modal,Avatar} from 'antd';
import {openNotif} from '../utils'
import SideMenu from './Layout/SideMenu'
import HeaderNav from './Layout/Header';
import {dbFirebase} from '../config'
import Timer from './Utils/Time'
import {Redirect} from 'react-router-dom';
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

let filterArr = [
  {
  id:'camF0',
  port:'',
  thumb:'',
  tempPortFile:null,
  tempThumbFile:null
},
{
  id:'camF1',
  port:'',
  thumb:'',
  tempPortFile:null,
  tempThumbFile:null
},
{
  id:'camF2',
  port:'',
  thumb:'',
  tempPortFile:null,
  tempThumbFile:null
},
{
  id:'camF3',
  port:'',
  thumb:'',
  tempPortFile:null,
  tempThumbFile:null
},
{
  id:'camF4',
  port:'',
  thumb:'',
  tempPortFile:null,
  tempThumbFile:null
},]

export default class Contents extends Component{
  constructor(props){
    super(props);
    this.state = {
      color:{
        headerLabel:'',
        headerTitle:'',
        onGoing:'',
        primary:'',
        secondary:''
      },

      event:{
        eventName:'',
        eventDescription:'',
        eventAddress:'',
        eventCoordinates:'',
        eventVenue:'',
        eventHashTag:'',
        eventStartDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        eventEndDate:moment(moment().toDate()).format('YYYY-MM-DD'),
        eventTimeStart:"12:00",
        eventTimeEnd:"12:00",
      },

      properties:{
        bannerUrl:'',
        eventImageUrl:'',
        eventLogoUrl:'',
        eventEnded:''
      },

      eventBannerFile:null,
      eventBackgroundFile:null,
      eventLogoFile:null,

      filterData:{
        fId:'',
        fPort:'',
        fThumb:''
      },

      filterList:filterArr,

      headerColorVisible:false,

    }
  }

  handleChangeHeaderLabel = (colors) => {
    // console.log("color",colors)
    let {color} = this.state
    this.setState({color:{...color,headerLabel:colors.hex }})
    // console.log("onChange event",this.state.color.headerLabel)
  };

  handleChangeHeaderTitleLabel = (colors) => {
    // console.log("color",colors)
    let {color} = this.state
    this.setState({color:{...color,headerTitle:colors.hex }})
    // console.log("onChange event",this.state.color.headerTitle)
  };

  handleChangeOnGoingLabel = (colors) => {
    // console.log("color",colors)
    let {color} = this.state
    this.setState({color:{...color,onGoing:colors.hex }})
    // console.log("onChange event",this.state.color.onGoing)
  };

  handleChangePrimaryLabel = (colors) => {
    // console.log("color",colors)
    let {color} = this.state
    this.setState({color:{...color,primary:colors.hex }})
    // console.log("onChange event",this.state.color.primary)
  };

  handleChangeSecondaryLabel = (colors) => {
    // console.log("color",colors.hex)
    let {color} = this.state
    this.setState({color:{...color,secondary:colors.hex }})
    // console.log("onChange event",this.state.color.secondary)
  };
  
  handleHeaderVisibleChange = (headerColorVisible) =>{
    this.setState({ headerColorVisible });
  }

  onChangeEventStartDate = (date, dateString) => {
    let {event} = this.state;
    console.log(event);
    // console.log(dateString);
    if(dateString=='') dateString=moment(moment().toDate()).format('YYYY-MM-DD')
    this.setState({event:{...event,eventStartDate:dateString}})
  }

  onChangeEventEndDate = (date, dateString) => {
    let {event} = this.state;
    // console.log('enddate',event);
    // console.log('enddate',dateString);
    if(dateString=='') dateString=moment(moment().toDate()).format('YYYY-MM-DD')
    this.setState({event:{...event,eventEndDate:dateString}})
  }

  onChangeEventEndTime = (time, timeString) => {
    let {event} = this.state;
    // console.log(event);
    // console.log(dateString);
    
    if(timeString=='') timeString="12:00"
    this.setState({event:{...event,eventTimeEnd:timeString}})
  }

  onChangeEventStartTime = (time, timeString) => {
    let {event} = this.state;
    // console.log(time);
    // console.log(timeString);
    if(timeString=='') timeString="12:00"
    this.setState({event:{...event,eventTimeStart:timeString}})
  }


  componentDidMount = () =>{
    let {color,event,filterList,properties} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    //Get Color
    axios.get(this.props.payload.apiLink+'/api/getColors/'+sesEventCode)
    .then(response =>{
      // console.log("colors",response)
      if(response.data != ""){
        color = {
          headerLabel:response.data.headerLabelColor,
          headerTitle:response.data.headerTitleColor,
          onGoing:response.data.onGoingColor,
          primary:response.data.primaryColor,
          secondary:response.data.secondaryColor
        }
        this.setState({ color });
      }

      }).catch(function (error) {
        console.log(error);
      });

      //Get Properties
      axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
      .then(response =>{
        console.log("properties",response)
        event= {
          eventName:response.data.name,
          eventDescription:response.data.description,
          eventAddress:response.data.location.address,
          eventCoordinates:response.data.location.coordinates,
          eventVenue:response.data.location.venue,
          eventHashTag:response.data.hashtag,
          eventStartDate:response.data.startDate == ''? moment(moment().toDate()).format('YYYY-MM-DD'):moment(response.data.startDate).format('YYYY-MM-DD'),
          eventEndDate: response.data.endDate == ''? moment(moment().toDate()).format('YYYY-MM-DD'):moment(response.data.endDate).format('YYYY-MM-DD'),
          eventTimeStart:response.data.startDate == '' ? moment(moment().toDate()).format('h:mm A'):moment(response.data.startDate).format('h:mm A'),
          eventTimeEnd:response.data.endDate == '' ? moment(moment().toDate()).format('h:mm A'):moment(response.data.endDate).format('h:mm A'),

          bannerUrl:response.bannerUrl,
          eventImageUrl:response.eventImageUrl,
          eventLogoUrl:response.eventLogoUrl,
          eventEnded:response.eventEnded
        }
        properties = {
          bannerUrl:response.data.bannerUrl,
          eventImageUrl:response.data.eventImageUrl,
          eventLogoUrl:response.data.eventLogoUrl,
          eventEnded:response.data.eventEnded
        }
        // console.log("event",event)

        // console.log("nope,",moment(response.data.startDate).format('YYYY-MM-DD'))
        this.setState({ event,properties });
      }).catch(function (error) {
        console.log(error);
      });

      axios.get(this.props.payload.apiLink+'/api/getCamFilter/'+sesEventCode
      ).then( (response) => {
        // console.log(response);
        // console.log("camFilter",response)
        // filterList = _.values(response.data).map(filter => ({
        //   port:filter.port,
        //   thumb:filter.thumb,
        //   id:filter.id
        // }));

        let tempList = _.values(response.data).map(filter => ({
          port:filter.port,
          thumb:filter.thumb,
          id:filter.id
        }));

        // console.log("templist",tempList)
        const newItems = [...this.state.filterList];
        //Replace only items that has value to avoid error when camFilter does not have all 5 id
        for(let cnt = 0; cnt < tempList.length; cnt ++){
          let sesIndex = tempList.findIndex(x => x.id==tempList[cnt].id);
          // console.log('tempList[cnt].id',tempList[cnt].id)
          newItems[sesIndex].port = tempList[cnt].port == undefined ? '':tempList[cnt].port;
          newItems[sesIndex].thumb = tempList[cnt].thumb == undefined ? '':tempList[cnt].thumb;

          newItems[sesIndex].port = tempList[cnt].port == '' ? '':tempList[cnt].port;
          newItems[sesIndex].thumb = tempList[cnt].thumb == '' ? '':tempList[cnt].thumb;

        }
        // console.log("filterList",newItems)
        this.setState({ filterList:newItems });
        
      })
      .catch(function (error) {
        console.log(error);
      });
  
      
    }


  onChangeHandler = (e) => {
    let {color,agenda,event,tabs} = this.state;
    
    switch(e.target.name){
      //Color

      // case 'headerLabelColor':
      //   this.setState({color:{...color,headerLabel:e.target.value}})
      //   console.log("onChange event",this.state.color.headerLabel)
      //   break;
      // case 'headerTitleColor':
      //   this.setState({color:{...color,headerTitle:e.target.value}})
      //   console.log("onChange event",this.state.color.headerTitle)
      // break;
      // case 'onGoingColor':
      //   this.setState({color:{...color,onGoing:e.target.value}})
      //   console.log("onChange event",this.state.color.headerTitle)
      // break;
      // case 'primaryColor':
      //   this.setState({color:{...color,primary:e.target.value}})
      //   console.log("onChange event",this.state.color.headerTitle)
      // break;
      // case 'secondaryColor':
      //   this.setState({color:{...color,secondary:e.target.value}})
      //   console.log("onChange event",this.state.color.headerTitle)
      // break;
      
      //Event Details

      case 'eventName':
        this.setState({event:{...event,eventName:e.target.value}})
        // console.log("onChange event",this.state.event.eventName)
      break;
      case 'eventDescription':
        this.setState({event:{...event,eventDescription:e.target.value}})
        // console.log("onChange event",this.state.event.eventDescription)
      break;
      case 'eventAddress':
        this.setState({event:{...event,eventAddress:e.target.value}})
        // console.log("onChange event",this.state.event.eventAddress)
      break;
      case 'eventVenue':
        this.setState({event:{...event,eventVenue:e.target.value}})
        // console.log("onChange event",this.state.event.eventVenue)
      break;
      case 'eventCoordinates':
        this.setState({event:{...event,eventCoordinates:e.target.value}})
        // console.log("onChange event",this.state.event.eventCoordinates)
      break;
      case 'eventHashTag':
        this.setState({event:{...event,eventHashTag:e.target.value}})
        // console.log("onChange event",this.state.event.eventHashTag)
      break;

    }
  }

  onSubmitColorHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    axios.put(this.props.payload.apiLink+'/api/addColors/'+sesEventCode,
        this.state.color
      ).then(function (response) {
        // console.log(response);
        openNotif('success',"Color Updated!","Color Succesfully Updated!")
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  onSubmitEventDetailsHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let date = moment(this.state.event.eventStartDate +" "+this.state.event.eventTimeStart).format();

    // console.log("date",this.state.event);
    // console.log("date JSON",moment(this.state.event.eventStartDate +" "+this.state.event.eventTimeStart).format());
    // console.log("date iso",new Date(this.state.event.eventStartDate).toISOString().slice(0,-14));
    let dataObj = {
      eventAddress: this.state.event.eventAddress,
      eventCoordinates: this.state.event.eventCoordinates,
      eventDescription: this.state.event.eventDescription,
      eventEndDate: moment(this.state.event.eventEndDate +" "+this.state.event.eventTimeEnd).format(),
      eventHashTag: this.state.event.eventHashTag,
      eventName: this.state.event.eventName,
      eventStartDate: moment(this.state.event.eventStartDate +" "+this.state.event.eventTimeStart).format(),
      eventTimeEnd: this.state.event.eventTimeEnd,
      eventTimeStart: this.state.event.eventTimeStart,
      eventVenue: this.state.event.eventVenue,
    }

    // console.log("dataobj",dataObj)
    axios.put(this.props.payload.apiLink+'/api/addEventDetails/'+sesEventCode,
        dataObj
      ).then(function (response) {
        // console.log(response);
        openNotif('success',"Event Details Updated!","Event Details Succesfully Updated!")
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  eventLogoUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {properties} = this.state
    let selFile = new FormData();

    selFile.append('filename',e.target.files[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"Assets");
    selFile.append('oldFileLink',properties.eventLogoUrl)

      axios.post(this.props.payload.storageLink+'/api/addEventLogo',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) =>{
        // console.log(response);

        let updateUrlObj = {
          eventCode:sesEventCode,
          propertyKey:"eventLogoUrl",
          url:response.data.dataURL
        }

        axios.post(this.props.payload.apiLink+'/api/updateEventPropertiesUrl',
        updateUrlObj)
        .then((response) =>{
          // console.log("updatelInk",response);
          openNotif('success',"Event Logo Updated!","Event Logo Succesfully Updated!")
        })
        .catch((error) =>{
          console.log(error);
        });

      })
      .catch((error) =>{
        console.log(error);
      });

  }

  eventBannerUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {properties} = this.state
    let selFile = new FormData();

    selFile.append('filename',e.target.files[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"Assets");
    selFile.append('oldFileLink',properties.bannerUrl)

      axios.post(this.props.payload.storageLink+'/api/addEventBanner',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then( (response) =>{
        // console.log(response);

        let updateUrlObj = {
          eventCode:sesEventCode,
          propertyKey:"bannerUrl",
          url:response.data.dataURL
        }

        axios.post(this.props.payload.apiLink+'/api/updateEventPropertiesUrl',
        updateUrlObj)
        .then((response) =>{
          // console.log("updatelInk",response);
          openNotif('success',"Event Banner Updated!","Event Banner Succesfully Updated!")
        })
        .catch((error) =>{
          console.log(error);
        });

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  eventBackgroundUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {properties} = this.state
    let selFile = new FormData();

    selFile.append('filename',e.target.files[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"Assets");
    selFile.append('oldFileLink',properties.eventImageUrl)

      axios.post(this.props.payload.storageLink+'/api/addEventBackground',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then( (response)=> {
        // console.log(response);
        openNotif('success',"Event Background Updated!","Event Background Succesfully Updated!")

        let updateUrlObj = {
          eventCode:sesEventCode,
          propertyKey:"headerUrl",
          url:response.data.dataURL
        }

        axios.post(this.props.payload.apiLink+'/api/updateEventPropertiesUrl',
        updateUrlObj)
        .then((response) =>{
          // console.log("updatelInk",response);
          
        })
        .catch((error) =>{
          console.log(error);
        });

      })
      .catch( (error) =>{
        console.log(error);
      });
  }
  
  eventUploadPortFilter = (e,id,type) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {filterList} = this.state
    let portFile = new FormData();
    let thumbFile = new FormData();
    let portUrl
    let thumbUrl

    let index = filterList.findIndex(x => x.id==id);

    portFile.append('filename',e.target.files[0]);
    portFile.append('company',this.props.payload.company);
    portFile.append('eventCode',sesEventCode);
    portFile.append('type',"Assets");
    try {
      if(type == 'port')portFile.append('oldFileLink',filterList[index].port)
      else if (type =='thumb')portFile.append('oldFileLink',filterList[index].thumb)
      
    }
    catch(err) {
      portFile.append('oldFileLink','')
    }
    // if(filterList[index].port != undefined)portFile.append('oldFileLink',filterList[index].port)
    // else portFile.append('oldFileLink','')

    axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
    portFile,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) =>{
      // console.log(response)
      portUrl = response.data.dataURL;

      let updateUrlObj = {
        id:id,
        eventCode:sesEventCode,
        typeUrl:portUrl,
        type:type
      }

      axios.post(this.props.payload.apiLink+'/api/editEventCameraFilter',
      updateUrlObj)
      .then((response) =>{
        // console.log("editEventCameraFilter",response);

        const newItems = [...filterList];
        try {
          if(type == 'port')newItems[index].port = portUrl
          else if (type =='thumb')newItems[index].thumb = portUrl
        }
        catch(err) {
        }
        this.setState({ filterList:newItems,
          });
        openNotif('success',"Uploaded Succesfully!","")

      })
      .catch((error) =>{
        console.log(error);
      });
    })
    .catch((error) =>{
      console.log(error);
    });
    
  }

  eventUploadThumbFilter = (e,id,type) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {filterList} = this.state
    let portFile = new FormData();
    let thumbFile = new FormData();
    let portUrl
    let thumbUrl

    let index = filterList.findIndex(x => x.id==id);

    thumbFile.append('filename',e.target.files[0]);
    thumbFile.append('company',this.props.payload.company);
    thumbFile.append('eventCode',sesEventCode);
    thumbFile.append('type',"Assets");
    try {
      thumbFile.append('oldFileLink',filterList[index].thumb)
    }
    catch(err) {
      portFile.append('oldFileLink','')
    }
    // if(filterList[index].port != undefined)portFile.append('oldFileLink',filterList[index].port)
    // else portFile.append('oldFileLink','')

    axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
    portFile,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) =>{
      // console.log(response)
      portUrl = response.data.dataURL;

      let updateUrlObj = {
        id:id,
        eventCode:sesEventCode,
        typeUrl:portUrl,
        type:type
      }

      axios.post(this.props.payload.apiLink+'/api/editEventCameraFilter',
      updateUrlObj)
      .then((response) =>{
        // console.log("editEventCameraFilter",response);

        const newItems = [...filterList];
        try {
          newItems[index].port = portUrl;
        }
        catch(err) {
        }
        this.setState({ filterList:newItems,
          });
        openNotif('success',"Uploaded Succesfully!","")

      })
      .catch((error) =>{
        console.log(error);
      });
    })
    .catch((error) =>{
      console.log(error);
    });
    
  }
  render(){
    // console.log("venueList",this.state.venueList)
    const { color,event,headerColorVisible,properties,filterList} = this.state;
    
    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer/>
      <HeaderNav
        payload = {this.props.payload}
      />
      <SideMenu
      payload = {this.props.payload}
      >
      </SideMenu>
      <AboutForm
        onChangeHandler = {this.onChangeHandler}
        onSubmitColorHandler = {this.onSubmitColorHandler}

        onSubmitEventDetailsHandler = {this.onSubmitEventDetailsHandler}
        onChangeEventStartDate = {this.onChangeEventStartDate}
        onChangeEventEndDate = {this.onChangeEventEndDate}
        onChangeEventEndTime = {this.onChangeEventEndTime}
        onChangeEventStartTime = {this.onChangeEventStartTime}
        handleHeaderVisibleChange = {this.handleHeaderVisibleChange}

        handleChangeHeaderLabel = {this.handleChangeHeaderLabel}
        handleChangeSecondaryLabel = {this.handleChangeSecondaryLabel}
        handleChangeHeaderTitleLabel = {this.handleChangeHeaderTitleLabel}
        handleChangePrimaryLabel = {this.handleChangePrimaryLabel}
        handleChangeOnGoingLabel = {this.handleChangeOnGoingLabel}

        eventLogoUploadHandler = {this.eventLogoUploadHandler}
        eventBannerUploadHandler = {this.eventBannerUploadHandler}
        eventBackgroundUploadHandler = {this.eventBackgroundUploadHandler}

        eventUploadPortFilter = {this.eventUploadPortFilter}

        color = {color}
        event = {event}
        headerColorVisible = {headerColorVisible}
        properties = {properties}
        filterList = {filterList}

      />
      </div>



    )
  }

  
}
