import React,{Component} from 'react'
import PartnerForm from './Partner/partnerForm'
import { Layout,Card,Button,Popconfirm,Modal,Avatar} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import {openNotif} from '../utils'
import PartnerModal from './Partner/partnerModal'
import SideMenu from './Layout/SideMenu'
import Timer from './Utils/Time'
import {Redirect} from 'react-router-dom';

import _ from 'lodash'
import axios from 'axios'

const {Content} = Layout;
export default class Partner extends Component{
  constructor(props){
    super(props);
    this.state = {
      orgData:{
        oBilling:'',
        oCategory:'',
        oCategoryLabel:'',
        oId:'',
        oLogoUrl:'',
        oName:'',
        oWebUrl:'',
        oDescription:'',
        eventOrgFile:null,
        submitStatus:'',
        eventCode:'',
        location:''
      },

      orgList:[{
        billing:'',
        category:'',
        categoryLabel:'',
        id:'',
        logoUrl:'',
        name:'',
        webUrl:'',
        description:''
      }],

      sortedOrg:null,
      filteredOrg:null,
      orgModalVisible:false,

      eventEnded:''
    }

  }

  onChangeHandler = (e) =>{
    let {orgData} = this.state;
    
    switch(e.target.name){

      case 'oName':
        this.setState({orgData:{...orgData,oName:e.target.value}})
        // console.log("onChange event",this.state.orgData.oName)
      break;
      case 'oDescription':
        this.setState({orgData:{...orgData,oDescription:e.target.value}})
        // console.log("onChange event",this.state.orgData.oDescription)
      break;
      case 'oBilling':
        this.setState({orgData:{...orgData,oBilling:e.target.value}})
        // console.log("onChange event",this.state.orgData.oBilling)
      break;
      case 'oWebUrl':
        this.setState({orgData:{...orgData,oWebUrl:e.target.value}})
        // console.log("onChange event",this.state.orgData.oWebUrl)
      break;
      case 'oCategory':
        this.setState({orgData:{...orgData,oCategory:e.target.value}})
        // console.log("onChange event",this.state.orgData.oCategory)
      break;
      case 'oCategoryLabel':
        this.setState({orgData:{...orgData,oCategoryLabel:e.target.value}})
        // console.log("onChange event",this.state.orgData.oCategoryLabel)
      break;

    }
  }

  eventOrgFileHandler = event =>{
    // console.log("lg",event.target.files)
    let {orgData} = this.state;
    
    this.setState({
      orgData:{...orgData,eventOrgFile:event.target.files}
    })

  }

  componentDidMount = () =>{
    let {orgList} = this.state

    let sesEventCode = sessionStorage.getItem('active-event-code');
  
    //Get properties to get eventEnded on properties

    axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
    .then(response =>{
      // console.log("properties",response)
  
      // console.log("eventEnded",response.data.eventEnded )
      this.setState({ eventEnded:response.data.eventEnded });
    }).catch(function (error) {
      console.log(error);
    });
    axios.get(this.props.payload.apiLink+'/api/getAllPartners/'+sesEventCode,
      ).then((response) => {
        // console.log("body",response)
        
        orgList = _.values(response.data).map(partner => ({
          billing:partner.billing,
          category:partner.category,
          categoryLabel:partner.categoryLabel,
          id:partner.id,
          logoUrl:partner.logoUrl,
          name:partner.name,
          webUrl:partner.webUrl,
          description:partner.description
        }));
        
        // console.log("orgList",orgList)

        this.setState({orgList})
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  handleOrgTableChange = (pagination,filters, sorter) => {
    // console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedOrg: sorter,
      filteredOrg:filters
    });
  }

  showOrgModalAdd = () =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    this.setState({
      orgData:{
        oBilling:'',
        oCategory:'',
        oCategoryLabel:'',
        oId:'',
        oLogoUrl:'',
        oName:'',
        oWebUrl:'',
        oDescription:'',
        eventOrgFile:null,
        submitStatus:'add',
        eventCode:sesEventCode,
        location:'info/partner'
      },
      orgModalVisible:true
    });
  }

  showOrgModalEdit = (record) =>{
    let {orgData} = this.state
    // record assignment _.values(record.assigments) match eId eProgram eRole
    let sesEventCode = sessionStorage.getItem('active-event-code');
    
    orgData = {
      oBilling:record.billing,
      oCategory:record.category,
      oCategoryLabel:record.categoryLabel,
      oId:record.id,
      oLogoUrl:record.logoUrl,
      oName:record.name,
      oWebUrl:record.webUrl,
      oDescription:record.description,
      eventCode:sesEventCode,
      location:'info/partner',
      submitStatus:'edit'
    }

    // console.log("record",record);

    this.setState({
      orgData,
      orgModalVisible:true
    });
  }

  handleOrgSubmitHandler = (e) =>{
    e.preventDefault();
    let {orgData,orgList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let selFile = new FormData();
    // console.log("orgData",orgData)
    if(orgData.submitStatus == 'edit'){
      if(orgData.eventOrgFile != undefined){

        selFile.append('filename',orgData.eventOrgFile[0]);
        selFile.append('company',this.props.payload.company);
        selFile.append('eventCode',sesEventCode);
        selFile.append('type',"PartnersLogo/"+orgData.oId);
        selFile.append('oldFileLink',orgData.oLogoUrl);
  
        let index = orgList.findIndex(x => x.id==orgData.oId);
  
        const newItems = [...orgList];
  
        newItems[index].billing = orgData.oBilling;
        newItems[index].category = orgData.oCategory;
        newItems[index].categoryLabel = orgData.oCategoryLabel;
        newItems[index].id = orgData.oId
        newItems[index].name = orgData.oName
        newItems[index].webUrl = orgData.oWebUrl
        newItems[index].description = orgData.oDescription
  
        // console.log("inside undefined")
  
        axios.post(this.props.payload.storageLink+'/api/addPartnerLogo2/',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) =>{
          
          // console.log("response",response)
  
          let updatePartnerObj = {
            oBilling:orgData.oBilling,
            oCategory:orgData.oCategory,
            oCategoryLabel:orgData.oCategoryLabel,
            oId:orgData.oId,
            oLogoUrl:response.data.dataURL,
            oName:orgData.oName,
            oWebUrl:orgData.oWebUrl,
            oDescription:orgData.oDescription,
            eventCode:sesEventCode,
          }
  
          newItems[index].logoUrl = response.data.dataURL;
  
          axios.post(this.props.payload.apiLink+'/api/editPartner/',
          updatePartnerObj)
          .then( (res) => {
  
            // console.log(res);
            this.setState({ orgList:newItems,
              orgModalVisible: false });
            openNotif('success',"Partner Edited!","Partner Succesfully Edited!")
            
  
          })
          .catch(function (error) {
            console.log(error);
          });
        })      
        .catch(function (error) {
          console.log(error);
        });
  
      }else{
  
        axios.post(this.props.payload.apiLink+'/api/editPartner',
        orgData)
        .then( (res) => {
  
          // console.log(res);

          let index = orgList.findIndex(x => x.id==orgData.oId);
  
          const newItems = [...orgList];
    
          newItems[index].billing = orgData.oBilling;
          newItems[index].category = orgData.oCategory;
          newItems[index].categoryLabel = orgData.oCategoryLabel;
          newItems[index].id = orgData.oId
          newItems[index].name = orgData.oName
          newItems[index].webUrl = orgData.oWebUrl
          newItems[index].description = orgData.oDescription
  
          this.setState({ orgList:newItems,
            orgModalVisible: false });
          openNotif('success',"Partner Edited!","Partner Succesfully Edited!")
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
    else if(orgData.submitStatus == 'add'){
      if(orgData.eventOrgFile != undefined){
        selFile.append('filename',orgData.eventOrgFile[0]);
        selFile.append('company',this.props.payload.company);
        selFile.append('eventCode',sesEventCode);
        
        selFile.append('oldFileLink',orgData.oLogoUrl);

        // console.log("orgData",orgData)
        let newOrgId = ''
        let newImageLink = ''
        let updateLinkObj = {}
        axios.post(this.props.payload.apiLink+'/api/addPartner2/',
        orgData)
        .then( (res) => {

          // console.log(res);
          
          newOrgId = res.data.orgId
          selFile.append('type',"PartnersLogo/"+newOrgId);
          
          axios.post(this.props.payload.storageLink+'/api/addPartnerLogo2/',
          selFile,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) =>{
            // console.log("imageres",response)

            newImageLink = response.data.dataURL

            updateLinkObj = {
              oId:newOrgId,
              logoUrl:newImageLink,
              eventCode:sesEventCode
            }

            axios.post(this.props.payload.apiLink+'/api/updateOrgLogoUrl/',
            updateLinkObj)
            .then((response) =>{
              // console.log("updateUrl",response)

              orgList.push({
                billing:orgData.oBilling,
                category:orgData.oCategory,
                categoryLabel:orgData.oCategoryLabel,
                description:orgData.oDescription,
                id:newOrgId,
                logoUrl:newImageLink,
                name:orgData.oName,
                webUrl:orgData.oWebUrl,
              })

              this.setState({ orgList,
                orgModalVisible: false });
              openNotif('success',"Partner Added!","Partner Succesfully Added!")

            })
            .catch(function (error) {
              console.log(error);
            });

          })
          .catch(function (error) {
            console.log(error);
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      }
      else{
        axios.post(this.props.payload.apiLink+'/api/addPartner2',
        orgData)
        .then( (res) => {
  
          // console.log(res);
  
          orgList.push({
            billing:orgData.oBilling,
            category:orgData.oCategory,
            categoryLabel:orgData.oCategoryLabel,
            description:orgData.oDescription,
            id:res.data.orgId,
            logoUrl:'',
            name:orgData.oName,
            webUrl:orgData.oWebUrl,
          })

          this.setState({ orgList,
            orgModalVisible: false });
          openNotif('success',"Partner Added!","Partner Succesfully Added!")
  
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }
 
  }
  
  onDeleteOrgHandler = (record) =>{
    let {orgList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    // console.log("record",record);

    let index = orgList.findIndex(x => x.id==record.id);

    let newList = [...orgList];
    newList.splice(index,1);
    // console.log("newList",newList)

    let partnerObj = {
      eventCode:sesEventCode,
      oId:record.id,
      company:this.props.payload.company,
      imageLink:record.logoUrl == undefined ? '':record.logoUrl
    }

    axios.post(this.props.payload.apiLink+'/api/deletePartner/',partnerObj
    )
    .then( (res) => {
      // console.log("resDel",res)

      this.setState({
        orgList:newList
      });
      openNotif('success',"Partner Deleted!","Partner Succesfully Deleted!")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  handleOrgModalCancel = (e) => {
    // console.log(e);
    this.setState({
      orgModalVisible: false,      
      orgData:{
        oBilling:'',
        oCategory:'',
        oCategoryLabel:'',
        oId:'',
        oLogoUrl:'',
        oName:'',
        oWebUrl:'',
        oDescription:'',
        eventOrgFile:null
      },
    });
  }

  render(){

    let { sortedOrg, orgList,filteredOrg,orgModalVisible,orgData,eventEnded } = this.state;
    // console.log("test",orgData)
    // console.log(orgList);

    sortedOrg = sortedOrg || {};
    filteredOrg = filteredOrg || {};
    const orgColumns = [
      {
      title: 'Photo',
      width: 100,
      render: (text,record) =>   <Avatar shape="square" src={record.logoUrl} size="large"/>

    }, 
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      sortOrder: sortedOrg.columnKey === 'name' && sortedOrg.order,
    }, 
    {
      title: 'Company Profile',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder: sortedOrg.columnKey === 'description' && sortedOrg.order,
    },
    {
      title: 'Website Link',
      dataIndex: 'webUrl',
      key: 'webUrl',
      sorter: (a, b) => a.webUrl.length - b.webUrl.length,
      sortOrder: sortedOrg.columnKey === 'webUrl' && sortedOrg.order,
    },
    {
      title: 'Category Label',
      dataIndex: 'categoryLabel',
      key: 'categoryLabel',
      sorter: (a, b) => { return a.categoryLabel.localeCompare(b.categoryLabel)},
      sortOrder: sortedOrg.columnKey === 'categoryLabel' && sortedOrg.order,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (text,record) =>
      
      // <div>
      //       <Button onClick={(e) => {  e.stopPropagation(); this.showOrgModalEdit(record)}}> 
      //         Edit
      //       </Button>

      //         <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteOrgHandler(record)}}>
      //           <Button onClick={(e) => {  e.stopPropagation();}}> 
      //             Delete
      //           </Button  >
                
      //         </Popconfirm>  
      // </div>

      <div className="text-align-center">
      {/* <i class="material-icons font-small status status-red -hide-on-hover">lens</i> */}
      {/* <a href="#" onClick={(e) => {  e.stopPropagation(); this.showOrgModalEdit(record)}} className="-show-on-hover" title="Edit"><i class="material-icons status-orange">edit</i></a> */}
      <a href="#" onClick={(e) => {  e.stopPropagation(); this.showOrgModalEdit(record)}} title="Edit"><i class="material-icons status-orange">edit</i></a>

          <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteOrgHandler(record)}}>
            {/* <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} className = "-show-on-hover"><i class="material-icons status-orange">delete</i></a> */}
            <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} ><i class="material-icons status-orange">delete</i></a>
          </Popconfirm>  
      </div>
    },
  ];

    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer/>
        <Modal title="Partner Details"
            visible={orgModalVisible}
            destroyOnClose = {true}
            footer = {[null]}
            onCancel={this.handleOrgModalCancel}
        >
            
          <PartnerModal
            eventOrgFileHandler = {this.eventOrgFileHandler}
            orgData = {orgData}
            onChangeHandler = {this.onChangeHandler}
            handleOrgSubmitHandler = {this.handleOrgSubmitHandler}
            eventEnded = {eventEnded}
          />

        </Modal>

      <HeaderNav
        payload = {this.props.payload}
      />
      <SideMenu payload = {this.props.payload}>
      </SideMenu>
          <PartnerForm
            orgList = {orgList}
            handleOrgTableChange = {this.handleOrgTableChange}
            orgColumns = {orgColumns}
            showOrgModalAdd = {this.showOrgModalAdd}
            eventEnded = {eventEnded}
          />
</div>
    )
  }
}