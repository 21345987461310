import React,{Component} from 'react';
import VenueForm from './Venue/venueForm'
import { Layout,Card,Button,Popconfirm,Modal,Avatar} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import {openNotif} from '../utils'
import VenueModal from './Venue/venueModal'
import SideMenu from './Layout/SideMenu'
import _ from 'lodash'
import axios from 'axios'
import Timer from './Utils/Time'
import {Redirect} from 'react-router-dom';

const {Content} = Layout;

export default class Venue extends Component{
  constructor(props){
    super(props);

    this.state = {

      venData: {
        venName:'',
        eventVenFile:null,
        venUrl:'',
        venId:'',
        venAgendaList:[],
        eventCode:'',
        location:''
      },

      venList: [{
        name:'',
        url:'',
        id:'',
        agendaList:[]
      }],

      sortedVen:null,
      filteredVen:null,
      venModalVisible:false,

      eventEnded:''
    }

  }

  componentDidMount = () =>{
    let {venList} = this.state

    let sesEventCode = sessionStorage.getItem('active-event-code');

    //Get properties to get eventEnded on properties

    axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
    .then(response =>{
      // console.log("properties",response)
 
      // console.log("eventEnded",response.data.eventEnded )
      this.setState({ eventEnded:response.data.eventEnded });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(this.props.payload.apiLink+'/api/getVenue/'+sesEventCode,
      ).then((response) => {
        // console.log("body",response)
        
        venList = _.values(response.data).map(venue => ({
          id:venue.id,
          url:venue.url,
          name:venue.name,
          agendaList:venue.agenda
        }));
        
        // console.log("venList",venList)

        this.setState({venList})
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleVenModalCancel = (e) => {
    console.log(e);
    this.setState({
      venModalVisible: false,      

    });
  }

  eventVenFileHandler = event =>{
    // console.log("lg",event.target.files)
    let {venData} = this.state;
    
    this.setState({
      venData:{...venData,eventVenFile:event.target.files}
    })

  }

  onChangeHandler = (e) =>{
    let {venData} = this.state;
    
    switch(e.target.name){

      case 'venName':
        this.setState({venData:{...venData,venName:e.target.value}})
        // console.log("onChange event",this.state.venData.venName)
      break;

    }
  }

  showVenModalAdd = () =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    this.setState({
      venData: {
        venName:'',
        eventExhFile:null,
        venUrl:'',
        venId:'',
        submitStatus:'add',
        eventCode:sesEventCode,
        location:'maps'
      },

      venModalVisible:true
    });
  }

  showVenModalEdit = (record) =>{
    let {venData} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    
    venData = {
      venName:record.name,
      venUrl:record.url,
      venId:record.id,
      eventCode:sesEventCode,
      location:'maps',
      submitStatus:'edit',
      venAgendaList:_.values(record.agendaList)
    }

    // console.log("record",record);

    this.setState({
      venData,
      venModalVisible:true
    });
  }

  onDeleteVenHandler = (record) =>{
    let {venList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    console.log("record",record);

    let index = venList.findIndex(x => x.id==record.id);

    let newList = [...venList];
    newList.splice(index,1);
    // console.log("newList",venList)

    let venueObj = {
      eventCode:sesEventCode,
      venAgendaList:_.values(record.agendaList),
      venId:record.id,
      company:this.props.payload.company,
      imageLink:record.url
    }

    axios.post(this.props.payload.apiLink+'/api/deleteVenue',venueObj
    )
    .then( (res) => {
      // console.log("resDel",res)

      this.setState({
        venList:newList
      });
      openNotif('success',"Venue Deleted!","Venue Succesfully Deleted!")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  onSubmitVenHandler = (e) =>{
    e.preventDefault();

    let {venData,venList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let selFile = new FormData();

    // console.log("current venue data",venData);
    let venueAddObj = {}
    let venueEditObj = {}
    if(venData.submitStatus == 'add'){
      if(venData.eventVenFile != undefined){

        // console.log("inside != undefined")
        selFile.append('filename',venData.eventVenFile[0]);
        selFile.append('company',this.props.payload.company);
        selFile.append('eventCode',sesEventCode);
        selFile.append('type',"Maps");
        selFile.append('oldFileLink',venData.venUrl);
  
        axios.post(this.props.payload.storageLink+'/api/addVenueImage/',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) =>{
          // console.log("responsee image",response)
          venueAddObj = {
            venName:venData.venName,
            venUrl:response.data.dataURL,
            venId:'',
            eventCode:sesEventCode,
            location:venData.location
          }
          

          // console.log("venueAddObj",venueAddObj)

          axios.post(this.props.payload.apiLink+'/api/addVenue2/',
          venueAddObj)
          .then( (res) => {


            // console.log("venueAddObj",venueAddObj.venUrl)

            venList.push({
              id:res.data.venueId,
              url:venueAddObj.venUrl,
              name:venData.venName
            })
  
            // console.log(res);
            this.setState({ venList,
              venModalVisible: false });
            openNotif('success',"Venue Added!","Venue Succesfully Added!")
  
          })
          .catch(function (error) {
            console.log(error);
          });

        })      
        .catch(function (error) {
          console.log(error);
        });
      }
      else{
        axios.post(this.props.payload.apiLink+'/api/addVenue2/',
        venData)
        .then( (res) => {

          venList.push({
            id:res.data.venueId,
            url:'',
            name:venData.venName
          })

          // console.log(res);
          this.setState({ venList,
            venModalVisible: false });
          openNotif('success',"Venue Added!","Venue Succesfully Added!")
          

        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }  
    else if(venData.submitStatus == 'edit'){

      let index = venList.findIndex(x => x.id==venData.venId);
  
      const newItems = [...venList];

      newItems[index].name = venData.venName;

      if(venData.eventVenFile != undefined){
        // console.log("inside != undefined")
        selFile.append('filename',venData.eventVenFile[0]);
        selFile.append('company',this.props.payload.company);
        selFile.append('eventCode',sesEventCode);
        selFile.append('type',"Maps");
        selFile.append('oldFileLink',venData.venUrl);


        axios.post(this.props.payload.storageLink+'/api/addVenueImage/',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) =>{
          // console.log("responsee image",response)

          venueEditObj = {
            venName:venData.venName,
            venUrl:response.data.dataURL,
            venId:venData.venId,
            eventCode:sesEventCode,
            location:venData.location,
            venAgendaList:_.values(venData.venAgendaList)
          }

          newItems[index].url = response.data.dataURL;

          axios.post(this.props.payload.apiLink+'/api/editVenue/',
          venueEditObj)
          .then( (res) => {
  
            // console.log(res);
            this.setState({ venList:newItems,
              venModalVisible: false });
            openNotif('success',"Venue Edited!","Venue Succesfully Edited!")
  
          })
          .catch(function (error) {
            console.log(error);
          });

        })      
        .catch(function (error) {
          console.log(error);
        });
      }
      else{
        axios.post(this.props.payload.apiLink+'/api/editVenue/',
        venData)
        .then( (res) => {

          // console.log(res);
          this.setState({ venList:newItems,
            venModalVisible: false });
          openNotif('success',"Venue Edited!","Venue Succesfully Edited!")
          

        })
        .catch(function (error) {
          console.log(error);
        });
      }
    }  
  }


  handleVenTableChange = (pagination,filters, sorter) => {
    // console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedVen: sorter,
      filteredVen:filters
    });
  }

  render(){

    let { sortedVen, venList,filteredVen,venModalVisible,venData,eventEnded } = this.state;

    // console.log("venList",venList)

    sortedVen = sortedVen || {};
    filteredVen = filteredVen || {};
    const venColumns = [
      {
      title: 'Photo',
      width: 100,
      render: (text,record) =>   <Avatar shape="square" src={record.url} size="large"/>

    }, 
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      sortOrder: sortedVen.columnKey === 'name' && sortedVen.order,
    }, 
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (text,record) =>
      
      // <div className="text-align-center">
      // <i class="material-icons font-small status status-red -hide-on-hover">lens</i>
      //       <Button onClick={(e) => {  e.stopPropagation(); this.showVenModalEdit(record)}}> 
      //         Edit
      //       </Button>

      //         <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteVenHandler(record)}}>
      //           <Button onClick={(e) => {  e.stopPropagation();}}> 
      //             Delete
      //           </Button  >
                
      //         </Popconfirm>  
      // </div>
      <div className="text-align-center">
        {/* <i class="material-icons font-small status status-red -hide-on-hover">lens</i> */}
        {/* <a href="#" onClick={(e) => {  e.stopPropagation(); this.showVenModalEdit(record)}} className="-show-on-hover" title="Edit"><i class="material-icons status-orange">edit</i></a> */}
        <a href="#" onClick={(e) => {  e.stopPropagation(); this.showVenModalEdit(record)}} title="Edit"><i class="material-icons status-orange">edit</i></a>
        

        <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteVenHandler(record)}}>
        {/* <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} className = "-show-on-hover"><i class="material-icons status-orange">delete</i></a> */}
        <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} ><i class="material-icons status-orange">delete</i></a>
        </Popconfirm>  
      </div>
    },
  ];

    return(
      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer/>
        <Modal title="Venue Details"
          visible={venModalVisible}
          destroyOnClose = {true}
          footer = {[null]}
          onCancel={this.handleVenModalCancel}
          >
              
            <VenueModal
              onChangeHandler = {this.onChangeHandler}
              eventVenFileHandler = {this.eventVenFileHandler}
              venData = {venData}
              onSubmitVenHandler = {this.onSubmitVenHandler}
              eventEnded = {eventEnded}
            />

          </Modal>

        <HeaderNav
          payload = {this.props.payload}
        />
        <SideMenu payload = {this.props.payload}>
        </SideMenu>

        <VenueForm
          venColumns = {venColumns}
          venList = {venList}
          showExhModalAdd = {this.showVenModalAdd}
          handleVenTableChange = {this.handleVenTableChange}
          showVenModalAdd = {this.showVenModalAdd}
          eventEnded = {eventEnded}
        />
      </div>
  //     <Layout>

  //     <Modal title="Venue Details"
  //         visible={venModalVisible}
  //         destroyOnClose = {true}
  //         footer = {[null]}
  //         onCancel={this.handleVenModalCancel}
  //     >
          
  //       <VenueModal
  //         onChangeHandler = {this.onChangeHandler}
  //         eventVenFileHandler = {this.eventVenFileHandler}
  //         venData = {venData}
  //         onSubmitVenHandler = {this.onSubmitVenHandler}
  //       />

  //     </Modal>

  //   <HeaderNav></HeaderNav>
  //   <Content style={{ padding: '0 10%', marginTop: '150px' ,marginLeft: "auto",  marginRight: "auto"}}>
  //     <Card
  //       title="Venues"
  //       style={{ width: '100%' ,  marginLeft: "auto",  marginRight: "auto"}}
  //     >
  //       <VenueForm
  //         venColumns = {venColumns}
  //         venList = {venList}
  //         showExhModalAdd = {this.showVenModalAdd}
  //         handleVenTableChange = {this.handleVenTableChange}
  //         showVenModalAdd = {this.showVenModalAdd}
  //       />
  //     </Card>
  //   </Content>
  //   <FooterNav></FooterNav>
  // </Layout>
    )
  }
}