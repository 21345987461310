import React,{Component} from 'react'
import {Link} from 'react-router-dom'

export default class SideMenu extends Component{
  constructor(props){
    super(props)
  }

  render(){

    // console.log("this.props",this.props)
    return(
      this.props.payload.role == 'auditor' ? 
      
      <nav className="side-menu">
        <Link to="/LiveChart" className="item">
          <i className="material-icons">bar_chart</i>
          <span className="item-label">Live Chart</span>
        </Link>
      </nav>
      
      
      :
      <nav className="side-menu">
       <Link to="/" className="item">
          <i className="material-icons">dashboard</i>
          <span className="item-label">Dashboard</span>
        </Link>
        <Link to="/Sessions" className="item">
          <i className="material-icons">calendar_today</i>
          <span className="item-label">Sessions</span>
        </Link>
        <Link to="/Venue" className="item">
          <i className="material-icons">pin_drop</i>
          <span className="item-label">Venues</span>
        </Link>
        <Link to="/Experts" className="item">
          <i className="material-icons">sentiment_satisfied_alt</i>
          <span className="item-label">Speakers</span>
        </Link>
        <Link to="/Partners" className="item">
          <i className="material-icons">people</i>
          <span className="item-label">Partners</span>
        </Link>
        <Link to="/Exhibitors" className="item">
          <i className="material-icons">account_box</i>
          <span className="item-label">Exhibitors</span>
        </Link>
        <Link to="/Polls" className="item">
          <i className="material-icons">announcement</i>
          <span className="item-label">Polls</span>
        </Link>
        <a href="#page-rating" disabled className="item">
          <i className="material-icons">star</i>
          <span className="item-label">Rating</span>
        </a>
        <Link to="/About" className="item">
          <i className="material-icons">info</i>
          <span className="item-label">About</span>
        </Link>
        <Link to="/Materials" className="item">
          <i className="material-icons">select_all</i>
          <span className="item-label">Materials</span>
        </Link>
        <Link to="/FormUrl" className="item">
          <i className="material-icons">chrome_reader_mode</i>
          <span className="item-label">Form Url</span>
        </Link>
        <Link to="/LiveChart" className="item">
          <i className="material-icons">bar_chart</i>
          <span className="item-label">Live Chart</span>
        </Link>
      </nav>
    )
  }
}