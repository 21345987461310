import {notification} from 'antd'
//import jwt from 'jsonwebtoken'
var jwt = require('jsonwebtoken');

const openNotif = (type,msg,desc) => {
  notification[type]({
    message: msg,
    description: desc,
  });
};

let createTokens = function(user){
  const sessiontoken = jwt.sign(
    {
      email:user[0],
      uid:user[1],
      company:user[2],
      name:user[3],
      role:user[4],
      apiLink:user[5],
      storageLink:user[6]
      
    },
    "secretTest",
    {
      expiresIn:'20m'
    }
  );
  return sessiontoken;
}


export{openNotif,createTokens};