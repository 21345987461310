import React, {Component} from 'react'
import {Button,Input,Select,Table} from 'antd'

const Option = Select.Option;





export default class infoForm extends Component{
  constructor(props){
    super(props)

  }

  style ={
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px"
  }

  render(){
    

    return(
      <div>
        <table style = {{fontFamily:'arial', borderCollapse:'collapse',width:'100%'}}>
          <tbody>
            <tr>
              <th style = {this.style}>Update Info</th>
              <th style = {this.style}></th>
            </tr>

              <tr>
                <td style = {this.style}>
                  <h3>Partner Details:</h3>
                  <br></br>
                  <h4>Name:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='pName' placeholder="Input Name" />
                  <h4>Description:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='pDescription' placeholder="Input Description" />
                  <h4>Category:</h4>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a Category"
                    optionFilterProp="children"
                    onChange={this.props.onChangeCategoryPartner}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="1">In Partnership With</Option>
                    <Option value="2">In Cooperation With</Option>
                    <Option value="3">Knowledge Partner</Option>
                    <Option value="4">Pr Partner</Option>
                    <Option value="5">Event Partner</Option>
                    <Option value="6">Mobile App Provider</Option>
                    <Option value="7">Registration Partner</Option>
                                    
                  </Select>
                  <h4>Web Url:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='pWebUrl' placeholder="Input Web Url" />
                  <h4>Order:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='pOrder' placeholder="Input Order" />

                  <h4>Upload Partner Image:</h4>
                  <input type = "file" onChange={(e)=>this.props.eventPartnerHandler(e)} />
                  <br></br>
                </td> 

                <td style = {this.style}>
                <Button onClick = {(e)=> this.props.onPartnerSubmitHandler()}>
                    Save
                </Button>
                </td>
              </tr>

              <tr>
                <td style = {this.style}>
                  <h3>Exhibitor Details:</h3>
                  <br></br>
                  <h4>Name:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='eName' placeholder="Input Name" />
                  <h4>Description:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='eDescription' placeholder="Input Description" />
                  <h4>Category:</h4>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a Category"
                    optionFilterProp="children"
                    onChange={this.props.onChangeExhibitorPartner}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="1">Co-Presenter</Option>
                    <Option value="2">Platinum</Option>
                    <Option value="3">Gold</Option>
                    <Option value="4">Silver</Option>
                    <Option value="5">Registration Lounge</Option>
                    <Option value="6">Bronze</Option>
                    <Option value="7">Exhibitor</Option>
                    <Option value="8">Media Partner</Option>
                                    
                  </Select>
                  <h4>Web Url:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='eWebUrl' placeholder="Input Web Url" />
                  <h4>Order:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='eOrder' placeholder="Input Order" />

                  <h4>Upload Exhibitor Image:</h4>
                  <input type = "file" onChange={(e)=>this.props.eventExhibitorHandler(e)} />
                  <br></br>
                </td> 

                <td style = {this.style}>
                <Button onClick = {(e)=> this.props.onExhibitorSubmitHandler()}>
                    Save
                </Button>
                </td>
              </tr>
              
            </tbody>
        </table>   
     
        
       
      </div>
    )
  }
}