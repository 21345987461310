import * as firebase from 'firebase';

//  Initialize Firebase

//GANAPP config

var Config = {
  apiKey: "AIzaSyAJuLLcSZoJzpXdkUlmgfNNCrtx-pTk98g",
  authDomain: "eesy-15d1a.firebaseapp.com",
  databaseURL: "https://eesy-15d1a.firebaseio.com",
  projectId: "eesy-15d1a",
  storageBucket: "eesy-15d1a.appspot.com",
  messagingSenderId: "866486502831"
};

  firebase.initializeApp(Config);

  const dbFirebase = firebase.database();
  const authFirebase = firebase.auth();
  const storageFirebase = firebase.storage();

export {dbFirebase,authFirebase,storageFirebase}