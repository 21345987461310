import React,{Component} from 'react'
import {Input,Form,Button,Table} from 'antd'
const { TextArea } = Input;

export default class expertModal extends Component{
  constructor(props){
    super(props)
  }


  render(){
    return(
      <Form className='Login-Form' onSubmit= {(e)=> this.props.onSubmitExpertHandler(e,this.props)} layout = "vertical" style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}>
        
        <div className="fld-item">
          <label>Name:</label>
          <Input 
          className="fld"
            onChange={this.props.onChangeHandler} 
            size="default" 
            name='name' 
            placeholder="Input Name" 
            defaultValue = {this.props.expertData.eName}
          />
        </div>

        <div className="fld-item">
          <label>Affiliation:</label>
            <Input 
            className="fld"
              onChange={this.props.onChangeHandler} 
              size="default" 
              name='affiliation' 
              placeholder="Input Affiliation"
              defaultValue = {this.props.expertData.eAffiliation}
            />
        </div>

        <div className="fld-item">
          <label>Description:</label>
            <TextArea 
            className="fld"
              onChange={this.props.onChangeHandler} 
              size="default" 
              name='description' 
              placeholder="Input Description"
              defaultValue = {this.props.expertData.eDescription}
            />        
        </div>

        <div className="fld-item">
        <label>Order:</label>
          <Input 
          type = "number"
          className="fld"
            onChange={this.props.onChangeHandler} 
            size="default" 
            name='billing' 
            placeholder="Input Order"
            defaultValue = {this.props.expertData.eBilling}
          />

        </div>

        <div className="fld-item">
        <label>Upload Expert Image:</label>
          <input type = "file" onChange={(e)=>this.props.expertFileHandler(e)} />
          <div class="text-gray v-space-large">Maximum upload file size: 5mb Dimensions should be 320px : 320px (rectangle)</div>

        </div>

        <div className="fld-item">
        {this.props.expertData.submitStatus == 'edit' ?
          
          <Table 
            columns={this.props.assignmentColumns} 
            dataSource={this.props.expertData.eAssignments} 
            onChange={this.props.handleExpertTableChange} 
            pagination={{ pageSize: 5 }} 

          />
          :
          null
        
        }
        
        </div>

        <div className="fld-item">
         
         <Button disabled={this.props.eventEnded} type="primary" htmlType='submit' block>Save</Button>
        </div>

      </Form>


    )
  }

}