import React, { Component } from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import decode from 'jwt-decode'
import './styles/style.css'
import Login from './Component/Login'
import Dashboard from './Component/Dashboard'
import Assets from './Component/Assets'
import Content from './Component/Content'
import Sessions from './Component/Session'
import Info from './Component/Info'
import Expert from './Component/Expert'
import Partner from './Component/Partner'
import Exhibitor from './Component/Exhibitor'
import Venue from './Component/Venue'
import About from './Component/About'
import Materials from './Component/Materials'
import FormUrl from './Component/FormUrl'
import Settings from './Component/Settings'
import Chart from './Component/Chart/ChartBoard'
import Polls from './Component/Polls'

let payload;

const isAuth = () =>{
  let loginStatus = sessionStorage.getItem('logged-in');
  let session = sessionStorage.getItem('user-session');


  if(loginStatus){
    payload = decode(session)
    // console.log("payload",payload)
    return true
  }else{
    return false
  }
}

const PrivateRoute = ({ component: Component ,...rest  } ) => (
  <Route {...rest} render={props => (
    isAuth() ? (
      <Component {...props} payload = {payload}/> 
    ) : (
      <Redirect to={{ pathname: '/login' }}/>
    )
  )}/>
)

class App extends Component {
  constructor(props){
    super(props);
  }
  
  render() {
    return (
      <BrowserRouter>
    <div className="App">
    
      <Switch>
        <Route exact path = "/Login" component={Login}/>
        <PrivateRoute exact path = "/" component={Dashboard} />
        <PrivateRoute exact path = "/Dashboard" component={Dashboard} />
        <PrivateRoute exact path = "/ModifyAssets" component={Assets} />  
        <PrivateRoute exact path = "/Sessions" component={Sessions} />  
        <PrivateRoute exact path = "/ModifyInfo" component={Info} />  
        <PrivateRoute exact path = "/Experts" component={Expert} />  
        <PrivateRoute exact path = "/Partners" component={Partner} /> 
        <PrivateRoute exact path = "/Exhibitors" component={Exhibitor} />
        <PrivateRoute exact path = "/Venue" component={Venue} /> 
        <PrivateRoute exact path = "/Content" component={Content} /> 
        <PrivateRoute exact path = "/About" component={About} /> 
        <PrivateRoute exact path = "/Materials" component={Materials} />
        <PrivateRoute exact path = "/FormUrl" component={FormUrl} /> 
        <PrivateRoute exact path = "/LiveChart" component={Chart} /> 
        <PrivateRoute exact path = "/Settings" component={Settings} /> 
        <PrivateRoute exact path = "/Polls" component={Polls} /> 

      </Switch>
      </div>
    </BrowserRouter>
    );
  }
}

export default App;
