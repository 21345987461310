import React,{Component} from 'react'
import {Input,Form,Button} from 'antd'
const { TextArea } = Input;
export default class pollsModal extends Component{
  constructor(props){
    super(props)
  }

  render(){

    return(
        <Form 
        className='Login-Form' 
        onSubmit= {(e)=> this.props.onSubmitHandler(e,this.props)} 
        layout = "vertical" 
        style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}
        >
        <div className="fld-item">
          <label>Description:</label>
          <TextArea 
            onChange={this.props.onChangeHandler} 
            className="fld"
            size="default" 
            name='pQuestion' 
            placeholder="Input Question" 
            defaultValue = {this.props.pollData.question}
          />

          
        </div>

        {this.props.pollData.options.map((option,id) => 
          <div id={id}>
            <div className="fld-item">
              <span style={{display: "inline"}}>
                
                <label style={{display: "inline"}} >Option:</label><a href="#" title="Delete" onClick={(e) => { this.props.hanldeRemoveOption(option,this.props.pollData.id) }} > <i class="material-icons" style = {{textAlign:'right',display: "inline", float: "right"}}>close</i></a>
              </span>
              <Input type='text' name={option.id} value={option.value} onChange={(e)=>this.props.onChangeHandler(e)}/> 

             
              
            </div>

            <div className="fld-item">
              <label>Option Image:</label>
              <input type ="file" name={option.id} onChange={(e)=>this.props.onSelectedFileHandler(e)} />
            </div>
          </div>
        )} 
       
       <div className="fld-item">
          <button class="btn btn-orange" onClick={ (e) => this.props.appendInput(e,this.props.pollData.submitStatus == 'add'?'':this.props.pollData.id) }>
              Add an option
          </button>
          {/* <button class="btn btn-orange" onClick={ (e) => this.props.appendInput(e,'option'+this.props.pollData.options.length == undefined ? 'option1':'option'+(parseInt(this.props.pollData.options.length)+1).toString(),
              'option'+this.props.listPoll.length == undefined ? 'poll1':'poll'+(this.props.listPoll.length+1) ) }>
              Add an option
          </button> */}
        </div>
        <div className="fld-item">
          <Button type="primary" htmlType='submit' block>Save</Button>
        </div>

      </Form>
      
    )
  }
}