import React, {Component} from 'react'
import {Input,Button} from 'antd'

export default class dashboardForm extends Component{
  constructor(props){
    super(props)
  }
  style ={
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px"
  }
  render(){
    return(
      <div className= "main">

<div class="top">
		<h1>Dashboard</h1>
		{/* <div class="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
	</div>
	<div class="content">
		<div class="col-group">
			<div class="col col-2">
				{/* <input type="button" class="btn btn-orange full-width uppercase" value="5 New Messages" /> */}
				<section class="section type1">
					<div class="section-title">
						<h2>Program Schedule</h2>
						<input type="button" class="btn btn-orange uppercase btn-small" value="On Time" />
					</div>
					<div class="section-content">
						<table class="tbl tbl-dark" cellpadding="0" cellspacing="0" border="0">
							{this.props.paginationAgenda.map((item,index)=>(
								<tr class = {item.onGoing}>
									<td class="tbl-date">{item.dateStart}</td>
									<td class="tbl-time">{item.time}</td>
									<td class="tbl-sched"><div class="space-between"><span class="sched-txt">{item.program}</span>{item.onGoing == 'active'?<span class="btn btn-green uppercase btn-small">Ongoing</span>:''}</div></td>
								</tr>

							))}
							
						</table>
						<div class="text-align-right">
							<ul class="ant-pagination tbl-pagination">
								<li title="Previous Page" disabled class={this.props.prevPage} onClick={(e)=>{this.props.paginationAgendaList(this.props.currentPage-1)}} aria-disabled="true">
									<a class="ant-pagination-item-link" disabled><i disabled class="material-icons">chevron_left</i></a>
								</li>

								{this.props.pagesSes.map((item,index)=>(
									<li title={item.title}class={item.active} onClick={(e)=>{this.props.paginationAgendaList(item.tab)}} tabindex={item.tab}>
									<a>{item.title}</a></li>
								))}

								<li title="Next Page" class={this.props.nextPage} onClick={(e)=>{this.props.paginationAgendaList(this.props.currentPage+1)}} aria-disabled="true">
									<a class="ant-pagination-item-link"><i class="material-icons">chevron_right</i></a>
								</li>
							</ul>
						</div>

					</div>
				</section>
				<section class="section type3">
					<div class="satisfaction-graph"><i class="material-icons" style={{fontSize:"150px", color:"#cbcbcb", marginTop:"-15px"}}>radio_button_unchecked</i></div>
					<div class="section-content">
						<div class="section-title">
							<h2>Audience' Satisfaction Meter</h2>
						</div>
						<div>
							<div class="satisfaction-icon"><i class="material-icons status-red">sentiment_very_dissatisfied</i> <span class="satisfaction-score">5<span class="font-small">%</span></span></div>
							<div class="satisfaction-icon"><i class="material-icons status-orange">sentiment_dissatisfied</i> <span class="satisfaction-score">15<span class="font-small">%</span></span></div>
							<div class="satisfaction-icon"><i class="material-icons status-blue">sentiment_satisfied</i> <span class="satisfaction-score">15<span class="font-small">%</span></span></div>
							<div class="satisfaction-icon"><i class="material-icons status-yellow">sentiment_satisfied_alt</i> <span class="satisfaction-score">35<span class="font-small">%</span></span></div>
							<div class="satisfaction-icon"><i class="material-icons status-medaqua">sentiment_very_satisfied</i> <span class="satisfaction-score">30<span class="font-small">%</span></span></div>
						</div>
					</div>
				</section>
			</div>
			<div class="col col-2">
				<section class="section type2">
					<div class="section-title">
						<h2>Day 1 Session</h2>
						<a href="#" class="zoom"><i class="material-icons text-light-gray">zoom_out_map</i></a>
					</div>
				</section>
				<section class="section type2">
					<div class="section-title">
						<h2>Speakers <i class="material-icons v-align">keyboard_arrow_down</i></h2>
						<a href="#" class="zoom"><i class="material-icons text-light-gray">zoom_out_map</i></a>
					</div>
					<div class="section-content">
						<div class="speaker-rating-row">
							<div class="speaker-user-img"><i class="material-icons">person</i></div>
							<div class="speaker-name">Jayson Ong</div>
							<div class="speaker-stars">
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star_border</i>
							</div>
							<div class="speaker-details">20/40 Attendees are rating</div>
						</div>
						<div class="speaker-rating-row">
							<div class="speaker-user-img"><i class="material-icons">person</i></div>
							<div class="speaker-name">Stephanie Delos Santos</div>
							<div class="speaker-stars">
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star_border</i>
								<i class="material-icons">star_border</i>
							</div>
							<div class="speaker-details">Final rating as of 10:45am</div>
						</div>
						<div class="speaker-rating-row">
							<div class="speaker-user-img"><i class="material-icons">person</i></div>
							<div class="speaker-name">Jerry Paraguanan</div>
							<div class="speaker-stars">
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star</i>
								<i class="material-icons">star_border</i>
							</div>
							<div class="speaker-details">15/10 Attendees are rating</div>
						</div>
						<div class="speaker-rating-row no-rating">
							<div class="speaker-user-img"><i class="material-icons">person</i></div>
							<div class="speaker-name">Thomas Templar</div>
							<div class="speaker-stars">
								No rating yet
							</div>
							<div class="speaker-details">Upcoming speaker</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>
        

        // <table style = {{fontFamily:'arial', borderCollapse:'collapse',width:'100%'}}>
        //   <tbody>
        //     <tr>
        //       <th style = {this.style}>Event</th>
        //       <th style = {this.style}></th>
        //     </tr>
            
        //     {this.props.eventList.map((item,index)=>(
        //       <tr key={index}>
        //         <td style = {this.style}>Code: {item.eventCode}</td> 

        //         <td style = {this.style}>
        //         <Button onClick = {()=> this.props.setActiveEvent(item)}>
        //             Set Active
        //         </Button>
        //         </td>
        //       </tr>
        //       ))}
        //   </tbody>
        // </table>
        // <br/>
        // <h3>{"Add new Event Code"}</h3>

        // <Input addonBefore = "Event Code:" onChange={this.props.onChangeHandler} size="large" name='uEventCode' placeholder="Input Event Code" />
        // <Button onClick = {this.props.addEventCode}>
        //             Add Event Code
        // </Button> 
     
    )
  }
}