import React,{Component} from 'react'
import {Button,Input,Select,Spin,Avatar,DatePicker,TimePicker,Table} from 'antd'
import moment from 'moment'

const Option = Select.Option;
const format = 'HH:mm';
const { TextArea } = Input;

//Not Using anymore
export default class contentForm extends Component{
  constructor(props){
    super(props)
  }

  style ={
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px"
  }
  
  render(){
    // console.log("this.prps",this.props)
    return(
      <div>
         <table style = {{fontFamily:'arial', borderCollapse:'collapse',width:'100%'}}>
          <tbody>
            <tr>
              <th style = {this.style}>Update Content</th>
              <th style = {this.style}></th>
            </tr>
        
              <tr>
                <td style = {this.style}>
                  <h3>Color:</h3>
                  <Input 
                    addonBefore = "Header Label" 
                    onChange={this.props.onChangeHandler}
                    size="default" 
                    name='headerLabelColor' 
                    placeholder="Input Header Label"
                    defaultValue = {this.props.color.headerLabel}
                  />
                  <Input 
                    addonBefore = "Header Title" 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='headerTitleColor' 
                    placeholder="Input Header Title"
                    defaultValue = {this.props.color.headerTitle}
                  />
                  <Input 
                    addonBefore = "On Going" 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='onGoingColor' 
                    placeholder="Input On Going"
                    defaultValue = {this.props.color.onGoing}
                  />
                  <Input 
                    addonBefore = "Primary" 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='primaryColor' 
                    placeholder="Input Primary Color"
                    defaultValue = {this.props.color.primary}
                  />
                  <Input 
                    addonBefore = "Secondary" 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='secondaryColor' 
                    placeholder="Input Secondary Color"
                    defaultValue = {this.props.color.secondary}
                  />
                </td> 

                <td style = {this.style}>
                <Button onClick = {(e)=> this.props.onSubmitColorHandler()}>
                    Save
                </Button>
                </td>
              </tr>

              <tr>
                <td style = {this.style}>
                  <h3>About the Event:</h3>
                  <br></br>
                  <h4>Event Name:</h4>
                  <Input 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='eventName' 
                    placeholder="Input Name" 
                    defaultValue = {this.props.event.eventName}
                  />
                  <h4>Event Description:</h4>
                  <TextArea 
                    rows={4} 
                    onChange={this.props.onChangeHandler} 
                    name='eventDescription' size="default" 
                    placeholder="Input Description"
                    value = {this.props.event.eventDescription}
                  />
                  <h4>Date Start:</h4>
                  <DatePicker 
                    onChange={this.props.onChangeEventStartDate}
                    value = {moment(this.props.event.eventStartDate,'YYYY/MM/DD')}
                  />

                  <h4>Date End:</h4>
                  <DatePicker 
                    onChange={this.props.onChangeEventEndDate}
                    value = {moment(this.props.event.eventEndDate,'YYYY/MM/DD')}
                  />

                  <h4>Time Start:</h4>
                  <TimePicker 
                    value={moment(this.props.event.eventTimeStart, 'h:mm a')} 
                    name='eventTimeStart' onChange={this.props.onChangeEventStartTime} 
                    use12Hours 
                    format="h:mm a" 
                  />

                  <h4>Time End:</h4>
                  <TimePicker 
                    value={moment(this.props.event.eventTimeEnd, 'h:mm a')} 
                    name='eventTimeEnd' 
                    onChange={this.props.onChangeEventEndTime} 
                    use12Hours 
                    format="h:mm a" 
                  />

                  <h4>Event HashTag:</h4>
                  <Input 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='eventHashTag' 
                    placeholder="Input HashTag"
                    defaultValue = {this.props.event.eventHashTag} 
                  />
                  <h4>Event Address:</h4>
                  <Input 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='eventAddress' 
                    placeholder="Input Address"
                    defaultValue = {this.props.event.eventAddress}
                  />
                  <h4>Event Venue:</h4>
                  <Input 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='eventVenue' 
                    placeholder="Input Venue"
                    defaultValue = {this.props.event.eventVenue}
                  />
                  <h4>Event Coordinates:</h4>
                  <Input 
                    onChange={this.props.onChangeHandler} 
                    size="default" 
                    name='eventCoordinates' 
                    placeholder="Input Coordinates"
                    defaultValue = {this.props.event.eventCoordinates}
                  />

                  <br></br>
                </td> 

                <td style = {this.style}>
                <Button onClick = {(e)=> this.props.onSubmitEventDetailsHandler()}>
                    Save
                </Button>
                </td>
              </tr>

              {/* <tr>
                <td style = {this.style}>
                  <h3>Venue Details:</h3>
                  <br></br>
                  <h4>Venue Name:</h4>
                  <Input onChange={this.props.onChangeHandler} size="default" name='vVenueName' placeholder="Input Venue Name" />
                  
                  <h4>Upload Venue Image:</h4>
                  <input type = "file" onChange={(e)=>this.props.venueFileHandler(e)} />
                  <br></br>
                </td> 

                <td style = {this.style}>
                <Button onClick = {(e)=> this.props.onSubmitVenueHandler()}>
                    Save
                </Button>
                </td>
              </tr> */}

          </tbody>
        </table>


        <br></br>
        <h4>Tabs:</h4>
        <table style = {{fontFamily:'arial', borderCollapse:'collapse',width:'100%'}}>
          <tbody>
            {this.props.tabsList.map((item,index)=>(
              <tr key={index}>
                Tab:  <span onClick = {(e)=> this.props.showTabModalEdit(item.id)}>{item.title}   </span>
                
                <Button onClick = {(e)=> this.props.handleTabDelete(item.id)}>
                    Delete
                </Button>
               

              </tr>
              ))}
          </tbody>
        </table>

        <Button type="primary" block onClick = {(e)=> this.props.showTabModalAdd()}>Add Tab</Button>
        <br></br>   
        <br></br>  

        <h4>Sessions:</h4>
        <Button type="primary" block onClick = {(e)=> this.props.showAgendaModalAdd()}>Add Agenda</Button>
        <br></br>
        <br></br>
        <Table 
          columns={this.props.AgendaColumns} 
          dataSource={this.props.listAgenda} 
          onChange={this.props.handleAgendaTableChange} 
          pagination={{ pageSize: 5 }} 
          onRow={(record) => ({
            onClick: () => { this.props.showAgendaModalEdit(record); }
        })}
        />
      </div>
    )
  }
}