import React,{Component} from 'react'
import GanApp_logo from '../../styles/images/GanApp_logo@2x.png'

export default class settingsForm extends Component{
  constructor(props){
    super(props)
  }

  render(){
    // console.log("set",this.props.settings)
    return(
      <div className= "main">
        <div class="top">
          <h1>Settings</h1>
          {/* <div class="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
        </div>
        <div class="content">
          <div class="col-group">
            <div class="col col-2">
              <section class="section">
                <div class="section-title">
                  <h2>Account Settings</h2>
                </div>
                <div class="section-content">
                  
                </div>
              </section>
            </div>
            <div class="col col-2">
              <section class="section">
                <div class="section-title">
                  <h2>Feature Settings</h2>
                </div>
                <div class="section-content">
                <div class="col-group">
                  <div class="col col-3">
                    <div class="v-space-small">Info Settings</div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-aboutEvent" onClick = {this.props.handleOnClick} checked={this.props.settings.aboutEvent} name="chk-aboutEvent" class="hide" />
                      <label for="chk-aboutEvent" ><span class="checkbox-icon" /> About Event</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-aboutGanapp" onClick = {this.props.handleOnClick} checked={this.props.settings.aboutGanapp} name="chk-aboutGanapp" class="hide" />
                      <label for="chk-aboutGanapp" ><span class="checkbox-icon" /> About Ganapp</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-aboutPointwest" onClick = {this.props.handleOnClick} checked={this.props.settings.aboutPointwest} name="chk-aboutPointwest" class="hide" />
                      <label for="chk-aboutPointwest"><span class="checkbox-icon" /> About Pointwest</label>
                    </div>
                    {/* <div class="v-space-small">
                      <input type="checkbox" id="chk-engagement" onClick = {this.props.handleOnClick} checked={this.props.settings.engagement} name="chk-engagement" class="hide" />
                      <label for="chk-engagement"><span class="checkbox-icon" /> Engagement</label>
                    </div> */}
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-eventFeedback" onClick = {this.props.handleOnClick} checked={this.props.settings.eventFeedback} name="chk-eventFeedback" class="hide" />
                      <label for="chk-eventFeedback"><span class="checkbox-icon" /> Event Feedback</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-exhibitor" onClick = {this.props.handleOnClick} checked={this.props.settings.exhibitor} name="chk-exhibitor" class="hide" />
                      <label for="chk-exhibitor"><span class="checkbox-icon" /> Exhibitor</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-expert" onClick = {this.props.handleOnClick} checked={this.props.settings.expert} name="chk-expert" class="hide" />
                      <label for="chk-expert"><span class="checkbox-icon" /> Expert</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-messages" onClick = {this.props.handleOnClick} checked={this.props.settings.messages} name="chk-messages" class="hide" />
                      <label for="chk-messages"><span class="checkbox-icon" /> Messages</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-partner" onClick = {this.props.handleOnClick} checked={this.props.settings.partner} name="chk-partner" class="hide" />
                      <label for="chk-partner"><span class="checkbox-icon" /> Partner</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-privacyPolicy" onClick = {this.props.handleOnClick} checked={this.props.settings.privacyPolicy} name="chk-privacyPolicy" class="hide" />
                      <label for="chk-privacyPolicy"><span class="checkbox-icon" /> Privacy Policy</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-question" onClick = {this.props.handleOnClick} checked={this.props.settings.question} name="chk-question" class="hide" />
                      <label for="chk-question"><span class="checkbox-icon" /> Question</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-survey" onClick = {this.props.handleOnClick} checked={this.props.settings.survey} name="chk-survey" class="hide" />
                      <label for="chk-survey"><span class="checkbox-icon" /> Survey</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-termsOfUse" onClick = {this.props.handleOnClick} checked={this.props.settings.termsOfUse} name="chk-termsOfUse" class="hide" />
                      <label for="chk-termsOfUse"><span class="checkbox-icon" /> Terms Of Use</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" id="chk-vote" onClick = {this.props.handleOnClick} checked={this.props.settings.vote} name="chk-vote" class="hide" />
                      <label for="chk-vote"><span class="checkbox-icon" /> Vote</label>
                    </div>

                  </div>

                  <div class="col col-3">
                    <div class="v-space-small">Profile Settings</div>
                    <div class="v-space-small">
                      <input type="checkbox" onClick = {this.props.handleOnClick} id="chk-materials" checked={this.props.settings.materials} name="chk-materials" class="hide" />
                      <label for="chk-materials"><span class="checkbox-icon" /> Materials</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" onClick = {this.props.handleOnClick} id="chk-myInfo" checked={this.props.settings.myInfo} name="chk-myInfo" class="hide" />
                      <label for="chk-myInfo"><span class="checkbox-icon" /> My Info</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" onClick = {this.props.handleOnClick} id="chk-mySched" checked={this.props.settings.mySched} name="chk-mySched" class="hide" />
                      <label for="chk-mySched"><span class="checkbox-icon" /> My Sched</label>
                    </div>
                  </div>

                  <div class="col col-3">
                    <div class="v-space-small">User Added Info</div>
                    <div class="v-space-small">
                      <input type="checkbox" onClick = {this.props.handleOnClick} id="chk-company" checked={this.props.settings.company} name="chk-company" class="hide" />
                      <label for="chk-company"><span class="checkbox-icon" /> Company</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" onClick = {this.props.handleOnClick} id="chk-companyId" checked={this.props.settings.companyId} name="chk-companyId" class="hide" />
                      <label for="chk-companyId"><span class="checkbox-icon" /> Company Id</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" onClick = {this.props.handleOnClick} id="chk-contactNumber" checked={this.props.settings.contactNumber} name="chk-contactNumber" class="hide" />
                      <label for="chk-contactNumber"><span class="checkbox-icon" /> Contact Number</label>
                    </div>
                    <div class="v-space-small">
                      <input type="checkbox" onClick = {this.props.handleOnClick} id="chk-jobTitle" checked={this.props.settings.jobTitle} name="chk-jobTitle" class="hide" />
                      <label for="chk-jobTitle"><span class="checkbox-icon" /> Job Title</label>
                    </div>
                  </div>
                </div>
     
                  
                <div  className="text-align-left" style = {{marginTop:"5px"}}><input onClick = {(e)=> this.props.onSubmitHandler()}  type="button" value="Done" class="btn btn-orange" /></div>
                </div>
              </section>
            </div>
            <div class="col col-2">
              <section class="section type3">
                <div><img src={GanApp_logo} width="100" /></div>
                <div class="section-content">
                  <p class="line-height-med">GanApp Raise your event to higher levels of digital engagement. Monitor the utilization of both your event’s tracks, attractions, and sponsors. Measure the feedback of your audience and participants real-time.</p>
                </div>
              </section>
            </div>
            <div class="col col-2">
              <section class="section type3 align-items-center">
                <div class="title-fancy" ><i class="material-icons font-huge">domain</i></div>
                <div class="section-content">
                  <a href="mailto:ganapp@pointest.com.ph" class="btn btn-orange btn-small">ganapp@pointest.com.ph</a>
                  <p class="line-height-med">9F Tower 3 Rockwell Business Center<br /> Ortigas Avenue, Pasig City<br /> Philippines</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    )
  }
}