import React,{Component} from 'react'
import {Table,Button} from 'antd'

export default class expertForm extends Component{
  constructor(props){
    super(props)
  }
 
  render(){
    return (
      <div className= "main">
          <div class="top">
            <h1>Speakers</h1>
            {/* <div class="top-info">Last edit: 09/28/2018 at 02:04PM</div> */}
          </div>
          <div class="content">
            <h1 class="title-fancy">Who's <span class="emp">talking</span><span class="font-huge">?</span></h1>
            <h2 class="title-fancy-sub">Upload the speakers' details here</h2>
            
            <section class="section">
              
              <div class="space-between">
                <div>
                  
                </div>
                {/* <div>
                  <i class="material-icons font-small status-red">lens</i> Incomplete Details
                  <span class="h-space-small"></span>
                  <i class="material-icons font-small status-green">lens</i> Complete Details
                </div> */}
              </div>
              <h3 class="tbl-title">Speaker's Profile</h3>
              <Button block disabled={this.props.eventEnded} onClick = {(e)=> this.props.showExpertModalAdd(e)} className = "btn-gray-dash-add">Add Expert</Button>
              <br></br>
              <Table 
                    className="tbl tbl-white -has-hover" cellpadding="0" cellspacing="0" border="0"
                    columns={this.props.expertColumns} 
                    dataSource={this.props.expertsList} 
                    onChange={this.props.handleExpertTableChange} 
                    pagination={{ pageSize: 10
                    }} 
                    // onRow={(record) => ({
                    //   onClick: () => { this.props.showAgendaModalEdit(record); }
                    // })}
                />
            </section>
          </div>
                {/* <Button type="primary" block onClick = {(e)=> this.props.showExpertModalAdd(e)}>Add Expert</Button>
              <br></br>
              <br></br>
                <Table 
                    columns={this.props.expertColumns} 
                    dataSource={this.props.expertsList} 
                    onChange={this.props.handleExpertTableChange} 
                    pagination={{ pageSize: 5 }} 
                    // onRow={(record) => ({
                    //   onClick: () => { this.props.showAgendaModalEdit(record); }
                    // })}
                /> */}
      </div>

    )
  }
}