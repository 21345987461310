import React,{Component} from 'react'
import { Layout,Card,Select} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import ExpertForm from './Expert/expertForm';
import ExpertModal from './Expert/expertModal'
import {openNotif} from '../utils'
import {Redirect} from 'react-router-dom';

import {Button,Avatar,Popconfirm,Modal} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import Timer from './Utils/Time'
import SideMenu from './Layout/SideMenu'

const Option = Select.Option;
const {Content} = Layout;

export default class Expert extends Component{
  constructor(props){
    super(props)
    this.state = {

      expertData:{
        eAffiliation:'',
        eAssignments:[{
          eId:'',
          eProgram:'',
          eRole:''
        }],
        eBilling:'',
        eDescription:'',
        eImageLink:'',
        eName:'',
        eId:'',
        eSessionString:'',
        eExpertFile:null,
        eRole:'',
        eventCode:'',
        submitStatus:''
      },

      expertsList:[{
        affiliation:'',
        assignments:[],
        billing:'',
        description:'',
        imageLink:'',
        name:'',
        id:'',
        sessionString:''
      }],

      sortedExpert:null,
      filteredExpert:null,
      expertModalVisible:false,

      eventEnded:''

    }
  }

  expertFileHandler = event =>{
    // console.log("lg",event.target.files)
    let {expertData} = this.state;
    
    this.setState({
      expertData:{...expertData,expertFile:event.target.files}
    })

  }


  onChangeHandler = (e) =>{
    let {expertData} = this.state;
    
    switch(e.target.name){
      //Expert
      case 'name':
        this.setState({expertData:{...expertData,eName:e.target.value}})
        // console.log("onChange event",this.state.expertData.eName)
      break;
      case 'affiliation':
        this.setState({expertData:{...expertData,eAffiliation:e.target.value}})
        // console.log("onChange event",this.state.expertData.eAffiliation)
      break;
      case 'description':
        this.setState({expertData:{...expertData,eDescription:e.target.value}})
        // console.log("onChange event",this.state.expertData.eDescription)
      break;
      case 'billing':
        this.setState({expertData:{...expertData,eBilling:e.target.value}})
        // console.log("onChange event",this.state.expertData.eBilling)
      break;

    }
  }

  componentDidMount(){
    let {expertsList} = this.state

    let sesEventCode = sessionStorage.getItem('active-event-code');

    //Get properties to get eventEnded on properties

    axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode)
    .then(response =>{
      // console.log("properties",response)
  
      // console.log("eventEnded",response.data.eventEnded )
      this.setState({ eventEnded:response.data.eventEnded });
    }).catch(function (error) {
      console.log(error);
    });

    axios.get(this.props.payload.apiLink+'/api/getAllExperts/'+sesEventCode,
        ).then((response) => {
          console.log("body",response)
          
          expertsList = _.values(response.data).map(expert => ({
            affiliation:expert.affiliation,
            assignments:_.values(expert.assignments),
            billing:expert.billing,
            description:expert.description,
            imageLink:expert.imageLink == undefined ? '':expert.imageLink,
            name:expert.name,
            id:(expert.name).toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, '')
          }));

          let programString = ''
          //List of expert sessions in 1 string to show in table
          for(let exp = 0; exp < expertsList.length;exp++){
            for(let ses = 0;ses<expertsList[exp].assignments.length;ses++){
              programString = programString + expertsList[exp].assignments[ses].program + ','
            }
            expertsList[exp].sessionString = programString.slice(0,-1)
            programString = ''
          }
          
          // console.log("expertList",expertsList)

          this.setState({expertsList})
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  handleExpertTableChange = (pagination,filters, sorter) => {
    // console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedExpert: sorter,
      filteredExpert:filters
    });
  }

  handleExpertModalCancel = (e) => {
    // console.log(e);
    this.setState({
      expertModalVisible: false,      
      expertData:{
        eAffiliation:'',
        eAssignments:[{
          eId:'',
          eProgram:'',
          eRole:''
        }],
        eBilling:'',
        eDescription:'',
        eImageLink:'',
        eName:'',
        eId:'',
        eSessionString:'',
        eExpertFile:null,
        eRole:''
      },
    });
  }

  showExpertModalAdd = () =>{
    let {expertData,expertsList} = this.state
    expertData.submitStatus = 'add'
    expertData.eBilling = expertsList.length+1;
    this.setState({
      expertData,
      expertModalVisible:true
    });
  }

  showExpertModalEdit = (record) =>{
    let {expertData,expertsList} = this.state
    // record assignment _.values(record.assigments) match eId eProgram eRole
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let oldAssignments = _.values(record.assignments)
    let newAssignments = []

    for(let assign = 0; assign < oldAssignments.length; assign ++){
      newAssignments.push({
        eId:oldAssignments[assign].id,
        eProgram:oldAssignments[assign].program,
        eRole:oldAssignments[assign].role == undefined ? '':oldAssignments[assign].role 
      })
    }

    expertData = {
      eAffiliation:record.affiliation,
      eAssignments:newAssignments,
      eBilling:record.billing,
      eDescription:record.description,
      eImageLink:record.imageLink,
      eName:record.name,
      eId:record.id,
      eSessionString:record.sessionString,
      eventCode:sesEventCode,
      submitStatus:'edit'
    }

    // console.log("record",record);

    this.setState({
      expertData,
      expertModalVisible:true
    });
  }

  onDeleteExpertHandler = (record) =>{
    let {expertsList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');

    // console.log("record",record);

    let expertObj = {
      id:record.id,
      assignments:record.assignments,
      eventCode:sesEventCode,
      imageLink:record.imageLink,
      company:this.props.payload.company
    }

    let index = expertsList.findIndex(x => x.id==record.id);

    let newList = [...expertsList];
    newList.splice(index,1);
    // console.log("newList",newList)

    // console.log("expertObj",expertObj)
    axios.post(this.props.payload.apiLink+'/api/deleteExpert',
    expertObj)
    .then( (res) => {
      // console.log("resDel",res)
      openNotif('success',"Expert Deleted!","Expert Succesfully Deleted!")
      this.setState({
        expertsList:newList
      });

    })
    .catch(function (error) {
      console.log(error);
    });
  }

  onSubmitExpertHandler = (event,formProps) =>{
    event.preventDefault();
    let {expertData,expertsList} = this.state

    let selFile = new FormData();
    let sesEventCode = sessionStorage.getItem('active-event-code');
    expertData.eventCode = sesEventCode
    // console.log("expertDataOnSubmit",expertData)
    if(expertData.submitStatus == 'edit'){

      let index = expertsList.findIndex(x => x.id==expertData.eId);

      const newItems = [...expertsList];
      newItems[index].affiliation = expertData.eAffiliation;
      newItems[index].description = expertData.eDescription;
      newItems[index].name = expertData.eName;
      newItems[index].id = expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, '');
      newItems[index].billing = expertData.eBilling

      for(let exp = 0; exp < expertData.eAssignments.length;exp++){

        newItems[index].assignments[exp] = {
          role:expertData.eAssignments[exp].eRole,
          id:expertData.eAssignments[exp].eId,
          program:expertData.eAssignments[exp].eProgram
        }
    
      }

      // console.log("newItems",newItems)

      if(expertData.expertFile != undefined){

        selFile.append('filename',expertData.expertFile[0]);
        selFile.append('company',this.props.payload.company);
        selFile.append('eventCode',sesEventCode);
        selFile.append('type',"ExpertImages/"+expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''));
        selFile.append('location',"info/expert/"+expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''));
        selFile.append('asset','imageLink')
        selFile.append('oldFileLink',expertData.eImageLink)

    //Different link if still using ganapp database
    //uploading files will go to different database so different link
    //updating of image link when uploaded should still be in ganapp
    //if client not yet live. so 3 api calls
    //ganapp(updating expert details) => liveDb(storage of files) => ganapp(updating of image link)
  
    //Did not include update for experts in updating image to reuse the function when image is not present
        axios.post(this.props.payload.apiLink+'/api/editExpert',
        expertData)
        .then( (res) => {

          // console.log(res);
          //change url to liveDb (even if not yet live)
          axios.post(this.props.payload.storageLink+'/api/addSpeakerImage',
          selFile,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) =>{
            // console.log(response);
            //update table image url
            newItems[index].imageLink = response.data.dataURL;

            let updateImageObj = {
              eventCode:sesEventCode,
              id:expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''),
              imageLink:response.data.dataURL,
              agendaList:expertData.eAssignments,
            }
          
            
            this.setState({ expertsList:newItems,
              expertModalVisible: false });
            openNotif('success',"Expert Edited!","Expert Succesfully Edited!")

            console.log("updateImageobj",updateImageObj)
            //update imagelink to db
            axios.post(this.props.payload.apiLink+'/api/updateExpertImageLink',
            updateImageObj)
            .then( (resp) => {
              // console.log(resp);
  
            })
            .catch(function (error) {
              console.log(error);
            });
  
          })
          .catch(function (error) {
            console.log(error);
          });
          
        })
        .catch(function (error) {
          console.log(error);
        });
      
  
        }else{
          axios.post(this.props.payload.apiLink+'/api/editExpert',
          expertData)
          .then( (res) => {
            // console.log(res);

            this.setState({ expertsList:newItems,
              expertModalVisible: false });
            openNotif('success',"Expert Edited!","Expert Succesfully Edited!")
          })
          .catch(function (error) {
            console.log(error);
          });
            
        }
 
      }
      if(expertData.submitStatus == 'add'){

        expertData.eId = expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, '')

        if(expertData.expertFile != undefined){

          selFile.append('filename',expertData.expertFile[0]);
          selFile.append('company',this.props.payload.company);
          selFile.append('eventCode',sesEventCode);
          selFile.append('type',"ExpertImages/"+expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''));
          selFile.append('location',"info/expert/"+expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''));
          selFile.append('oldFileLink','')

          axios.post(this.props.payload.apiLink+'/api/addExpert',
          expertData)
          .then( (res) => {
            // console.log(res);
            axios.post(this.props.payload.storageLink+'/api/addSpeakerImage',
            selFile,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) =>{

              let updateImageObj = {
                eventCode:sesEventCode,
                id:expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''),
                imageLink:response.data.dataURL,
                agendaList:[],
              }

              expertsList.push({
                affiliation:expertData.eAffiliation,
                description:expertData.eDescription,
                name:expertData.eName,
                id:expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''),
                billing:expertData.eBilling,
                imageLink:response.data.dataURL
              })

              this.setState({ expertsList,
                expertModalVisible: false });

                this.setState({
                  expertModalVisible: false,      
                  expertData:{
                    eAffiliation:'',
                    eAssignments:[{
                      eId:'',
                      eProgram:'',
                      eRole:''
                    }],
                    eBilling:'',
                    eDescription:'',
                    eImageLink:'',
                    eName:'',
                    eId:'',
                    eSessionString:'',
                    eExpertFile:null,
                    eRole:''
                  },
                });

              axios.post(this.props.payload.apiLink+'/api/updateExpertImageLink',
              updateImageObj)
              .then( (resp) => {
                // console.log(resp);

                this.setState({ expertsList,
                  expertModalVisible: false });




                openNotif('success',"Expert Added!","Expert Succesfully Added!")
    
              })
              .catch(function (error) {
                console.log(error);
              });

            })
            .catch(function (error) {
              console.log(error);
            });

          })
          .catch(function (error) {
            console.log(error);
          });
            
        

        }else{
          axios.post(this.props.payload.apiLink+'/api/addExpert',
          expertData)
          .then( (res) => {
            // console.log(res);

            expertsList.push({
              affiliation:expertData.eAffiliation,
              description:expertData.eDescription,
              name:expertData.eName,
              id:expertData.eName.toLowerCase().replace(/\s/g,'').replace(/[^a-zA-Z ]/g, "").replace(/[^\w\s]/gi, ''),
              billing:expertData.eBilling,
            })

            this.setState({
              expertModalVisible: false,      
              expertData:{
                eAffiliation:'',
                eAssignments:[{
                  eId:'',
                  eProgram:'',
                  eRole:''
                }],
                eBilling:'',
                eDescription:'',
                eImageLink:'',
                eName:'',
                eId:'',
                eSessionString:'',
                eExpertFile:null,
                eRole:''
              },
            });

            this.setState({ expertsList,
              expertModalVisible: false });
            openNotif('success',"Expert Added!","Expert Succesfully Added!")

          })
          .catch(function (error) {
            console.log(error);
          });
        }

      
      }

  }

  handleChangeAssignment = (value,record) =>{
    let {expertData} = this.state

    // console.log("currentExpertData",expertData)
    // console.log("rec",record)

    let assignmentIndex = expertData.eAssignments.findIndex(x => x.eId==record.eId);
    let newItem = [...this.state.expertData.eAssignments]
    newItem[assignmentIndex].eRole = value;
    expertData.eAssignments = newItem;

    this.setState({
      expertData
    })
  
  }

  render(){
    
    let { sortedExpert, expertsList,filteredExpert,expertModalVisible,expertData,eventEnded } = this.state;
    // console.log("test",expertData)
    // console.log(expertsList);

    sortedExpert = sortedExpert || {};
    filteredExpert = filteredExpert || {};
    const expertColumns = [
      {
      title: 'Photo',
      width: 100,
      render: (text,record) =>   <Avatar src={record.imageLink} size="large"/>

    }, 
    {
      title: 'Expert',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { return a.name.localeCompare(b.name)},
      sortOrder: sortedExpert.columnKey === 'name' && sortedExpert.order,
    }, 
    {
      title: 'Sessions',
      dataIndex: 'sessionString',
      key: 'sessionString',
      sorter: (a, b) => a.sessionString.length - b.sessionString.length ,
      sortOrder: sortedExpert.columnKey === 'sessionString' && sortedExpert.order,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (text,record) =>
      
      // <div>
      //       <Button onClick={(e) => {  e.stopPropagation(); this.showExpertModalEdit(record)}}> 
      //         Edit
      //       </Button>

      //         <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteExpertHandler(record)}}>
      //           <Button onClick={(e) => {  e.stopPropagation();}}> 
      //             Delete
      //           </Button  >
                
      //         </Popconfirm>  
      // </div>

      <div className="text-align-center">
      {/* <i class="material-icons font-small status status-red -hide-on-hover">lens</i> */}
      {/* <a href="#" onClick={(e) => {  e.stopPropagation(); this.showExpertModalEdit(record)}} className="-show-on-hover" title="Edit"><i class="material-icons status-orange">edit</i></a> */}
      <a href="#" onClick={(e) => {  e.stopPropagation(); this.showExpertModalEdit(record)}} title="Edit"><i class="material-icons status-orange">edit</i></a>
    
          <Popconfirm title="Sure to delete?" onCancel = {(e)=>{e.stopPropagation();}} onConfirm={(e) => { e.stopPropagation(); this.onDeleteExpertHandler(record)}}>
            {/* <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} className = "-show-on-hover"><i class="material-icons status-orange">delete</i></a> */}
            <a href="#" title="Delete" onClick={(e) => {  e.stopPropagation();}} ><i class="material-icons status-orange">delete</i></a>
          </Popconfirm>  
      </div>
    },
  ];

  const assignmentColumns = [
    {
      title: 'Sessions',
      dataIndex: 'eProgram',
      key: 'eProgram',
      sorter: (a, b) => { return a.eProgram.localeCompare(b.eProgram)},
      sortOrder: sortedExpert.columnKey === 'eProgram' && sortedExpert.order,
    },
    {
      title: 'Role',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (text,record) =>
      
      <div>
        <Select defaultValue={record.eRole} style={{ width: 120 }} onChange={(e) => this.handleChangeAssignment(e,record)}>
          <Option value="speaker">Speaker</Option>
          <Option value="host">Host</Option>
          <Option value="moderator">Moderator</Option>
          <Option value="panelist">Panelist</Option>
        </Select>
      </div>
    }
  ]

    return(

      <div id="area">
      {this.props.payload.role == 'auditor' ? <Redirect to={{ pathname: '/' }}/> :''}
      <Timer/>
        <Modal title="Title"
            visible={expertModalVisible}
            destroyOnClose = {true}
            footer = {[null]}
            onCancel={this.handleExpertModalCancel}
        >
            
          <ExpertModal
            expertData = {expertData}
            expertFileHandler = {this.expertFileHandler}
            onChangeHandler = {this.onChangeHandler}
            onSubmitExpertHandler = {this.onSubmitExpertHandler}
            handleExpertTableChange ={this.handleExpertTableChange}
            assignmentColumns ={assignmentColumns}
            eventEnded = {eventEnded}
          />

        </Modal>


        <HeaderNav
          payload = {this.props.payload}
        />
      <SideMenu payload = {this.props.payload}>
      </SideMenu>
          <ExpertForm
            expertColumns = {expertColumns}
            expertsList = {expertsList}
            handleExpertTableChange = {this.handleExpertTableChange}
            showExpertModalAdd = {this.showExpertModalAdd}
            eventEnded = {eventEnded}

          />
      </div>
    )
  }


}
