import React,{Component} from 'react'
import {Input,DatePicker,Form,Button } from 'antd'
import moment from 'moment'


export default class tabsModal extends Component{
  constructor(props){
    super(props)
  }
  
render(){
  return(
  <Form 
    className='Login-Form' 
    onSubmit= {(e)=> this.props.onSubmitTabsHandler(e,this.props)} 
    layout = "vertical" 
    style ={{width:"100%",marginLeft: "auto",  marginRight: "auto"}}
    >
   <div className="fld-item">
   <label>Tab Title:</label>
      <Input 
        className="fld"
        onChange={this.props.onChangeHandler} 
        size="default" 
        name='tTitle' 
        placeholder="Input Tab Title" 
        defaultValue = {this.props.tabs.tTitle}
      />
   </div>
     
   <div className="fld-item">
    <label>Order:</label>
        <Input 
          type = "number"
          className="fld"
          onChange={this.props.onChangeHandler} 
          size="default" 
          name='tBilling' 
          placeholder="Input Order" 
          defaultValue = {this.props.tabs.tBilling}
        />
   </div>

   <div className="fld-item">
    <label>Date:</label>
        <DatePicker 
          className="fld"
          onChange={this.props.onChangeTabDate}
          defaultValue = {moment(this.props.tabs.tDate,'YYYY/MM/DD')}
        />
   </div>



   <div className="fld-item">
   <Button  type="primary" htmlType='submit' block>Submit</Button>
   </div>
      


      </Form>
  
  )
}

}