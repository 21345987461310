import React,{Component} from 'react'
import { Layout,Card} from 'antd';
import HeaderNav from './Layout/Header';
import FooterNav from './Layout/Footer';
import axios from 'axios'
import {openNotif} from '../utils'
import AssetsForm from './Assets/assetsForm'
import _ from 'lodash'
const {Content} = Layout;

//========================Not Using anymore ========================//
export default class Assets extends Component{
  constructor(props){
    super(props)
    this.state={
      eventBannerFile:null,
      eventBackgroundFile:null,
      // eventVenueFile:null,
      // eventPartnerFile:null,
      // eventExhibitorFile:null,
      // eventSpeakerFile:null,
      eventLogoFile:null,
      eventFilterPortFile:null,
      eventFilterThumbFile:null,
      
      properties:{
        bannerUrl:'',
        eventImageUrl:'',
        eventLogoUrl:''
      },

      filterData:{
        fId:'',
        fPort:'',
        fThumb:''
      },

      filterList:[{
        id:'',
        port:'',
        thumb:'',
        tempPortFile:null,
        tempThumbFile:null
      }]

    }
  }

  componentDidMount = () =>{
    let {filterList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    console.log("seseventcode",sesEventCode)
    axios.get(this.props.payload.apiLink+'/api/getProperties/'+sesEventCode
    ).then( (response) => {
      console.log(response);

      this.setState({
        bannerUrl:response.bannerUrl,
        eventImageUrl:response.eventImageUrl,
        eventLogoUrl:response.eventLogoUrl
      })

    })
    .catch(function (error) {
      console.log(error);
    });

    axios.get(this.props.payload.apiLink+'/api/getCamFilter/'+sesEventCode
    ).then( (response) => {
      console.log(response);

      filterList = _.values(response.data).map(filter => ({
        port:filter.port,
        thumb:filter.thumb,
        id:filter.id
      }));
      this.setState({ filterList });
      
    })
    .catch(function (error) {
      console.log(error);
    });

  }

  eventLogoHandler = event =>{
    console.log("lg",event.target.files)

    this.setState({
      eventLogoFile:event.target.files
    })
  }

  eventBannerHandler = event =>{
    console.log("lg",event.target.files)

    this.setState({
      eventBannerFile:event.target.files
    })
  }

  eventBackgroundHandler = event =>{
    console.log("bg",event.target.files)

    this.setState({
      eventBackgroundFile:event.target.files
    })
  }

  EventPortUpload = (id)=>{

  }

  eventUploadEditFilter = (id) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {filterList} = this.state
    let portFile = new FormData();
    let thumbFile = new FormData();
    let portUrl
    let thumbUrl

    let index = filterList.findIndex(x => x.id===id);
    if(filterList[index].tempPortFile !=null && filterList[index].tempThumbFile ==null){

      portFile.append('filename',filterList[index].tempPortFile[0]);
      portFile.append('company',this.props.payload.company);
      portFile.append('eventCode',sesEventCode);
      portFile.append('type',"Assets");
      portFile.append('oldFileLink',filterList[index].port)

      axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
      portFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })
      .then((response) =>{
        console.log(response)
        portUrl = response.data.dataURL;

        let updateUrlObj = {
          id:filterList[index].id,
          eventCode:sesEventCode,
          typeUrl:portUrl,
          type:'port'
        }

        axios.post(this.props.payload.apiLink+'/api/editEventCameraFilter',
        updateUrlObj)
        .then((response) =>{
          console.log("editEventCameraFilter",response);

          const newItems = [...filterList];

          newItems[index].port = portUrl;

          this.setState({ filterList:newItems,
            });
          openNotif('success',"Portrait Edited!","Portrait Succesfully Edited!")

        })
        .catch((error) =>{
          console.log(error);
        });
      })
      .catch((error) =>{
        console.log(error);
      });

    }else if(filterList[index].tempPortFile == null && filterList[index].tempThumbFile !=null){

      thumbFile.append('filename',filterList[index].tempThumbFile[0]);
      thumbFile.append('company',this.props.payload.company);
      thumbFile.append('eventCode',sesEventCode);
      thumbFile.append('type',"Assets");
      thumbFile.append('oldFileLink',filterList[index].thumb)

      axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
      thumbFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })
      .then((response) =>{
        console.log(response)
        thumbUrl = response.data.dataURL;

        let updateUrlObj = {
          id:filterList[index].id,
          eventCode:sesEventCode,
          typeUrl:thumbUrl,
          type:'thumb'
        }

        axios.post(this.props.payload.apiLink+'/api/editEventCameraFilter',
        updateUrlObj)
        .then((response) =>{
          console.log("editEventCameraFilter",response);

          const newItems = [...filterList];

          newItems[index].thumb = thumbUrl;

          this.setState({ filterList:newItems,
            });
          openNotif('success',"Thumbnail Edited!","Thumbnail Succesfully Edited!")


        })
        .catch((error) =>{
          console.log(error);
        });
      })
      .catch((error) =>{
        console.log(error);
      });
    }else if(filterList[index].tempPortFile != null && filterList[index].tempThumbFile !=null){
      portFile.append('filename',filterList[index].tempPortFile[0]);
      portFile.append('company',this.props.payload.company);
      portFile.append('eventCode',sesEventCode);
      portFile.append('type',"Assets");
      portFile.append('oldFileLink',filterList[index].port)

      thumbFile.append('filename',filterList[index].tempThumbFile[0]);
      thumbFile.append('company',this.props.payload.company);
      thumbFile.append('eventCode',sesEventCode);
      thumbFile.append('type',"Assets");
      thumbFile.append('oldFileLink',filterList[index].thumb)

      //Port
      axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
      portFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })
      .then((response) =>{
        console.log(response)
        portUrl = response.data.dataURL;
        //Thumb
        axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
        thumbFile,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) =>{
          console.log(response)
          thumbUrl = response.data.dataURL;

          let updateUrlObj = {
            id:filterList[index].id,
            eventCode:sesEventCode,
            thumbUrl:thumbUrl,
            portUrl:portUrl,
          }
  
          axios.post(this.props.payload.apiLink+'/api/editBothEventCameraFilter',
          updateUrlObj)
          .then((response) =>{
            console.log("editEventCameraFilter",response);

            const newItems = [...filterList];

            newItems[index].thumb = thumbUrl;
            newItems[index].port = portUrl;
  
            this.setState({ filterList:newItems,
              });
            openNotif('success',"CamFilter Edited!","CamFilter Succesfully Edited!")
  
  
          })
          .catch((error) =>{
            console.log(error);
          });

        })
        .catch((error) =>{
          console.log(error);
        });
        
      })
      .catch((error) =>{
        console.log(error);
      });
    }

  }

  eventUploadNewFilter = (event) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {filterData,filterList} = this.state
    let portFile = new FormData();
    let thumbFile = new FormData();

    let portUrl
    let thumbUrl

    portFile.append('filename',filterData.fPort[0]);
    portFile.append('company',this.props.payload.company);
    portFile.append('eventCode',sesEventCode);
    portFile.append('type',"Assets");
    portFile.append('oldFileLink','')

    thumbFile.append('filename',filterData.fThumb[0]);
    thumbFile.append('company',this.props.payload.company);
    thumbFile.append('eventCode',sesEventCode);
    thumbFile.append('type',"Assets");
    thumbFile.append('oldFileLink','')

    if(filterData.fPort === '' && filterData.fThumb === '') openNotif('error',"Something went wrong!","Please upload both port and thumb!")
    else{
      //Upload Port
      axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
      portFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })
      .then((response) =>{
        console.log(response);
        portUrl = response.data.dataURL;

        //Upload Thumb
        axios.post(this.props.payload.storageLink+'/api/uploadEventCameraFilter',
        thumbFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) =>{
          console.log(response);
          thumbUrl = response.data.dataURL;

          let updateUrlObj = {
            eventCode:sesEventCode,
            portUrl:portUrl,
            thumbUrl:thumbUrl,
            location:'camfilter'
          }
          console.log("updateUrlObj",updateUrlObj)

          //Update CamFilter table
          axios.post(this.props.payload.apiLink+'/api/addEventCameraFilter',
          updateUrlObj)
          .then((response) =>{
            console.log("addEventCameraFilter",response);

            filterList.push({
              id:response.data.newFilterId,
              port:portUrl,
              thumb:thumbUrl,
              tempPortFile:null,
              tempThumbFile:null
            })
            openNotif('success',"Filter Added!","Filter Successfully Added!")
            this.setState({filterList});

          })
          .catch((error) =>{
            console.log(error);
          });
        
        })
        .catch((error) =>{
          console.log(error);
        });

      })
      .catch((error) =>{
        console.log(error);
      });
    }
   

  }

  //For Data.. for new camera filter
  eventFilterPortDataHandler = (event)=>{
    console.log("eventFilterPortDataHandler",event.target.files)
    let {filterData} = this.state

    this.setState({filterData:{...filterData,fPort:event.target.files}})
  }

  eventFilterThumbDataHandler = (event)=>{
    console.log("eventFilterThumbDataHandler",event.target.files)
    let {filterData} = this.state

    this.setState({filterData:{...filterData,fThumb:event.target.files}})
  }

  //For List
  //Made file store in list to enable user to choose file on all items and still work
  //if stored in data it will replace every other upload
  eventFilterPortListHandler = (event,id)=>{
    console.log("eventFilterPortListHandler",event.target.files)
    let {filterList} = this.state

    let index = filterList.findIndex(x => x.id===id);
    const newItems = [...filterList];

    newItems[index].tempPortFile = event.target.files;

    this.setState({filterList:newItems})
  }

  eventFilterThumbListHandler = (event,id)=>{
    console.log("eventFilterThumbListHandler",event.target.files)
    let {filterList} = this.state

    let index = filterList.findIndex(x => x.id===id);
    const newItems = [...filterList];

    newItems[index].tempThumbFile = event.target.files;

    this.setState({filterList:newItems})
  }

  eventDeleteFilter = (id) =>{
    let {filterList} = this.state
    let sesEventCode = sessionStorage.getItem('active-event-code');
    
    axios.delete(this.props.payload.apiLink+'/api/deleteCamFilter/'+sesEventCode+'/'+id
    ).then( (response) => {
      console.log(response);

      let index = filterList.findIndex(x => x.id===id);
      filterList.splice(index,1);

      this.setState({ filterList});
      openNotif('success',"Camera Filter Deleted!","Camera Filter Succesfully Deleted!")
      
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  //Will Not use no more
  eventFilterPortHandler = event =>{
    console.log("lg",event.target.files)

    this.setState({
      eventFilterPortFile:event.target.files
    })
  }

  eventFilterThumbHandler = event =>{
    console.log("lg",event.target.files)

    this.setState({
      eventFilterThumbFile:event.target.files
    })
  }

//////////////////
  eventLogoUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {eventLogoFile,properties} = this.state
    let selFile = new FormData();

    selFile.append('filename',eventLogoFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"Assets");
    selFile.append('oldFileLink',properties.eventLogoUrl)

      axios.post(this.props.payload.storageLink+'/api/addEventLogo',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) =>{
        console.log(response);


        let updateUrlObj = {
          eventCode:sesEventCode,
          propertyKey:"eventLogoUrl",
          url:response.data.dataURL
        }

        axios.post(this.props.payload.apiLink+'/api/updateEventPropertiesUrl',
        updateUrlObj)
        .then((response) =>{
          console.log("updatelInk",response);

        })
        .catch((error) =>{
          console.log(error);
        });

      })
      .catch((error) =>{
        console.log(error);
      });

  }

  eventBannerUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {eventBannerFile,properties} = this.state
    let selFile = new FormData();

    selFile.append('filename',eventBannerFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"Assets");
    selFile.append('oldFileLink',properties.bannerUrl)

      axios.post(this.props.payload.storageLink+'/api/addEventBanner',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then( (response) =>{
        console.log(response);

        let updateUrlObj = {
          eventCode:sesEventCode,
          propertyKey:"bannerUrl",
          url:response.data.dataURL
        }

        axios.post(this.props.payload.apiLink+'/api/updateEventPropertiesUrl',
        updateUrlObj)
        .then((response) =>{
          console.log("updatelInk",response);

        })
        .catch((error) =>{
          console.log(error);
        });

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  eventBackgroundUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {eventBackgroundFile,properties} = this.state
    let selFile = new FormData();

    selFile.append('filename',eventBackgroundFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"Assets");
    selFile.append('oldFileLink',properties.eventImageUrl)

      axios.post(this.props.payload.storageLink+'/api/addEventBackground',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then( (response)=> {
        console.log(response);


        let updateUrlObj = {
          eventCode:sesEventCode,
          propertyKey:"eventImageUrl",
          url:response.data.dataURL
        }

        axios.post(this.props.payload.apiLink+'/api/updateEventPropertiesUrl',
        updateUrlObj)
        .then((response) =>{
          console.log("updatelInk",response);

        })
        .catch((error) =>{
          console.log(error);
        });

      })
      .catch( (error) =>{
        console.log(error);
      });
  }
  
  
  eventFilterThumbUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {eventFilterThumbFile} = this.state
    let selFile = new FormData();

    selFile.append('filename',eventFilterThumbFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"CameraFrames");
    //Change aarodelapaz depending on user input
    selFile.append('location',"camfilter/camF0");
    selFile.append('asset','thumb')

      axios.post(this.props.payload.apiLink+'/api/addFilterThumb',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  eventFilterPortUploadHandler = (e) =>{
    let sesEventCode = sessionStorage.getItem('active-event-code');
    let {eventFilterPortFile} = this.state
    let selFile = new FormData();

    selFile.append('filename',eventFilterPortFile[0]);
    selFile.append('company',this.props.payload.company);
    selFile.append('eventCode',sesEventCode);
    selFile.append('type',"CameraFrames");
    //Change camF0 depending on user input
    selFile.append('location',"camfilter/camF0");
    selFile.append('asset','port')

      axios.post(this.props.payload.apiLink+'/api/addFilterPort',
        selFile,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render(){
    let {filterList,filterData} = this.state
    console.log("filterLIst",filterList)
    console.log("filterData",filterData)

    return(
      <Layout>
      <HeaderNav></HeaderNav>
      <Content style={{ padding: '0 10%', marginTop: '150px' ,marginLeft: "auto",  marginRight: "auto"}}>
        <Card
          title="Upload Assets"
          style={{ width: '100%' ,  marginLeft: "auto",  marginRight: "auto"}}
        >
          <AssetsForm
            eventBannerUploadHandler = {this.eventBannerUploadHandler}
            eventBannerHandler = {this.eventBannerHandler}
            eventBackgroundHandler = {this.eventBackgroundHandler}
            eventBackgroundUploadHandler = {this.eventBackgroundUploadHandler}
            eventVenueHandler = {this.eventVenueHandler}
            eventVenueUploadHandler = {this.eventVenueUploadHandler}
            eventLogoHandler = {this.eventLogoHandler}
            eventLogoUploadHandler = {this.eventLogoUploadHandler}
            eventFilterPortHandler = {this.eventFilterPortHandler}
            eventFilterPortUploadHandler = {this.eventFilterPortUploadHandler}
            eventFilterThumbHandler = {this.eventFilterThumbHandler}
            eventFilterThumbUploadHandler = {this.eventFilterThumbUploadHandler}
            eventFilterPortListHandler = {this.eventFilterPortListHandler}
            eventFilterThumbListHandler = {this.eventFilterThumbListHandler}
            eventFilterPortDataHandler = {this.eventFilterPortDataHandler}
            eventFilterThumbDataHandler = {this.eventFilterThumbDataHandler}
            eventUploadNewFilter = {this.eventUploadNewFilter}
            eventUploadEditFilter = {this.eventUploadEditFilter}
            eventDeleteFilter = {this.eventDeleteFilter}

            filterList = {filterList}
          />
        </Card>
      </Content>
      <FooterNav></FooterNav>
    </Layout>
    )
  }
}