import React, { Component } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';


class chart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0
    }
  }

  render() {
    //console.log(this.props.name, this.props.prtcpntValue)
    return (

      <div className="main">
        <div class="content">
          <BarChart width={1200} height={580} data={this.props.prtcpntValue}
            margin={{ top: 70, right: 0, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              <Label value={this.props.name} fontSize="17px" fontStyle="800" offset={500} position="top" />
            </XAxis>
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#3390FF" />
          </BarChart>
        </div>
      </div>

    );
  }

}


export default (chart);
